import React from 'react'
import SelectList from '@/components/SelectList'
import User from './components/User'
import CollectionArea from './components/CollectionArea'
import NFTCards from './components/NFTCards'
import HistoryArea from './components/HistoryArea'
import Mybids from './components/Mybids'
import { useTranslation } from 'react-i18next'
import useUrlState from '@ahooksjs/use-url-state'
import styles from './style.module.scss'
// import MyNFTCards from './components/MyNFTCards'
import useGlobalStore from '@/stores/global'
import { useHistory } from 'react-router-dom'
const normalList = [
  // {
  //   label: 'COLLECTION',
  //   value: 'COLLECTION',
  // },
  {
    label: 'COLLECTIBLES',
    value: 'COLLECTIBLES',
  },
  {
    label: 'HISTORY',
    value: 'HISTORY',
  },
  {
    label: 'MY BIDS',
    value: 'MYBIDS',
  },
]

const normalListCN = [
  // {
  //   label: '我的创造',
  //   value: 'COLLECTION',
  // },
  {
    label: '我的收藏品',
    value: 'COLLECTIBLES',
  },
  {
    label: '交易历史',
    value: 'HISTORY',
  },
  {
    label: '我参与的拍卖',
    value: 'MYBIDS',
  },
]
function Index() {
  const history = useHistory()
  const { userAddress } = useGlobalStore()
  const { t, i18n } = useTranslation()
  const [urlState, setUrlState] = useUrlState({
    tab: 'COLLECTION',
    collectionId: '',
  })

  const handleSelect = (value: string) => {
    setUrlState({ tab: value, collectionId: '' })
  }

  const scrollToAnchor = (anchorName: string) => {
    if (anchorName) {
      // 找到锚点
      const anchorElement = document.getElementById(anchorName)
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }
  }

  const handleCardClick = (id: string) => {
    history.push(`/my-nft-cards?id=${id}`)
  }

  const render = () => {
    const { tab } = urlState
    if (tab === 'COLLECTION' || '') {
      return <CollectionArea onCardClick={handleCardClick} />
    }
    if (tab === 'COLLECTIBLES') {
      return <NFTCards />
    }
    if (tab === 'HISTORY') {
      return <HistoryArea />
    }
    // if (tab === 'COLLECTIBLE') {
    //   return <MyNFTCards />
    // }
    if (tab === 'MYBIDS') {
      return <Mybids />
    }
  }

  return (
    <div className={styles.container}>
      <p className={styles.userInfo}>{t('userInfo')}</p>
      <p className={styles.title}>{t('mySpace')}</p>
      {userAddress && <User />}

      <div id="select-box" className={styles.selectBox}>
        {/* Empty区域  */}
        {/* <Empty /> */}

        {/* Content区域  */}
        <div className={styles.selectList}>
          <SelectList
            value={urlState.tab}
            onSelect={handleSelect}
            options={i18n.language === 'cn' ? normalListCN : normalList}
          />
        </div>
        {render()}
      </div>
    </div>
  )
}

export default Index
