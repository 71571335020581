import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Title from '@/components/Title'
import Card from '@/components/Card'
import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'
import { useRequest } from 'ahooks'

function TrendingCollections() {
  const history = useHistory()
  const { data, run } = useRequest<any>(
    API.collection.getTrendingCollectionList.getTrendingCollectionList,
    {
      manual: true,
    }
  )
  const { t } = useTranslation()

  useEffect(() => {
    run()
  }, [])

  const pathToCollections = (record: any) => {
    history.push(`/explore?id=${record?.id}`)
  }

  const renderItem = () => {
    return (data?.data?.trendingCollection || []).map((item: any, index: number) => {
      return (
        <Card
          onClick={() => {
            pathToCollections(item)
          }}
          key={index}
          imgSrc={item.featuredImage}
          text={item.name}
          type="trend"
          newStyles={styles.card}
        />
      )
    })
  }

  return (
    <div className={styles.container}>
      <Title text={t('trending')} />
      <div className={styles.box}>{renderItem()}</div>
    </div>
  )
}

export default TrendingCollections
