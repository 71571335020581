import React from 'react'
import { terms as termsEn } from '@/assets/locales/en-US'
import { terms as termsCn } from '@/assets/locales/zh-CN'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'

const GameRuls = () => {
  const { i18n } = useTranslation()
  const terms = i18n.language === 'en' ? termsEn : termsCn
  return (
    <div className={styles.termsPage}>
      <div className={styles.pageTitle}>{terms.title}</div>
      {terms.descs.map((item, index) => (
        <div className={styles.contentItem} key={index}>
          <span className={styles.itemTitle}>{item.title}</span>
          <div className={styles.itemSpanList}>
            {item.children.map((ite, ind) => (
              <span className={styles.spanContent} key={ind}>
                {ite}
              </span>
            ))}
          </div>
        </div>
      ))}
      <div className={styles.tips}>{terms.tips}</div>
      <div className={styles.team}>{terms.team}</div>
    </div>
  )
}

export default GameRuls
