import React from 'react'
import styles from './style.module.scss'
import cs from 'classnames'

export interface BaseInputProps {
  text?: string
  isRequired?: boolean
  value?: { type: string; name: string }
  placeHolder?: { type: string; name: string }
  error?: { type: string; name: string }
  onChange: (val: any, type: string) => void
  inputStyle?: string
}

function Index({
  text = '',
  isRequired = false,
  value,
  placeHolder = { type: '', name: '' },
  error,
  onChange,
  inputStyle,
}: BaseInputProps) {
  return (
    <div className={styles.baseInput}>
      {text && (
        <div className={styles.textSection}>
          {isRequired && <div className={styles.required}>*</div>}
          <div className={styles.text}>{text}</div>
        </div>
      )}
      <div
        className={
          error ? cs(styles.input, inputStyle, styles.error) : cs(styles.input, inputStyle)
        }
      >
        <div className={styles.inputItem}>
          <input
            value={value?.type}
            placeholder={error?.type ? error.type : placeHolder.type}
            onChange={(e) => onChange(e.target.value, 'type')}
          />
        </div>
        <div className={styles.inputItem}>
          <input
            value={value?.name}
            placeholder={error?.name ? error.name : placeHolder.name}
            onChange={(e) => onChange(e.target.value, 'name')}
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(Index)
