import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './style.module.scss'
import BaseInput from '@/components/BaseInput'
import BaseSelect from '@/components/BaseSelect'
import BaseTextArea from '@/components/BaseTextArea'
import CreateHeader from './components/Header'
import ImgInfo from './components/ImgInfo'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import useGlobalStore from '@/stores/global'
import { getQueryVariable } from '@/utils/common'
import { message, notification } from 'antd'

function Create() {
  const history = useHistory()
  const { userAddress } = useGlobalStore()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [categorySelectValue, setCategorySelectValue] = useState('')
  const [nameNullCheck, setNameNullCheck] = useState('')
  const [descriptionNullCheck, setDescriptionNullCheck] = useState('')
  const [imgNullCheck, setImgNullCheck] = useState('')
  const [imgUrl, setImgUrl] = useState('')
  const [collectionDetail, setCollectionDetail] = useState({})
  const { t, i18n } = useTranslation()
  // 获取url参数
  const urlPair = getQueryVariable(history, 'collectionId')

  const { run: runCategory, data: categoryData } = useRequest(
    API.category.getCategoryList.getCategoryList,
    {
      manual: true,
      formatResult: (res: any) => {
        const list = (res?.data?.categoryList || []).map((item: any) => {
          return {
            label: i18n.language === 'en' ? item.name_en : item.name,
            value: item.id,
          }
        })
        if (res?.data?.categoryList) {
          res.data.categoryList = list.filter((item: any) => item.value !== '1')
        }
        return res
      },
    }
  )

  // 根据collectionID获取系列详情回填
  const { run: getCollection } = useRequest(API.collection.getCollection.getCollection, {
    manual: true,
    formatResult: (res: any) => {
      const list = (res?.data?.categoryList || []).map((item: any) => {
        return {
          label: i18n.language === 'en' ? item.name_en : item.name,
          value: item.id,
        }
      })
      if (res?.data?.categoryList) {
        res.data.categoryList = list
      }
      return res
    },
  })

  const { run: runCollection, data: dataCollection } = useRequest(
    API.collection.createCollection.createCollection,
    {
      manual: true,
    }
  )
  const { run: updateCollection, data: dataUpdateCollection } = useRequest(
    API.collection.updateCollection.updateCollection,
    {
      manual: true,
    }
  )

  useEffect(() => {
    urlPair &&
      getCollection({
        bodyParams: {
          id: urlPair,
        },
      }).then((res) => {
        if (res && res?.code === 0) {
          const obj = res.data.collection
          setCollectionDetail(obj)
          setImgUrl(obj.featuredImage)
          setName(obj.name)
          setDescription(obj.description)
          obj?.categoryList.length && setCategorySelectValue(obj?.categoryList[0].id)
        }
      })
    runCategory({
      bodyParams: {
        count: 0,
        pageSize: 10,
      },
    })
  }, [i18n.language])

  useEffect(() => {
    setImgNullCheck('')
  }, [imgUrl])

  useEffect(() => {
    setNameNullCheck('')
  }, [name])

  useEffect(() => {
    setDescriptionNullCheck('')
  }, [description])

  useEffect(() => {
    if (dataCollection && dataCollection.data.collectionId) {
      history.push('/my-space')
      notification.open({
        message: t('createSellOrBuyCreateSuccessTitle'),
        duration: null,
        description: t('createSellOrBuyCreateSuccess'),
      })
    }
    if (dataUpdateCollection && dataUpdateCollection.code === 0) {
      history.push('/my-space')
      notification.open({
        message: t('createSellOrBuyCreateSuccessTitle'),
        duration: null,
        description: t('createSellOrBuyCreateSuccess'),
      })
    }
  }, [dataCollection, dataUpdateCollection])

  useEffect(() => {
    if (categoryData && categoryData?.code === 0) {
      setCategorySelectValue('7')
    }
  }, [categoryData])

  // 非空验证
  const nonNullCheck = () => {
    let boo = true
    if (!imgUrl) {
      setImgNullCheck('errorImg')
      boo = false
    }
    if (!name) {
      setNameNullCheck('errorName')
      boo = false
    }
    if (!description) {
      setDescriptionNullCheck('errorDescription')
      boo = false
    }
    if (boo) handleData()
  }

  const handleData = async () => {
    urlPair
      ? await updateCollection({
          bodyParams: {
            id: collectionDetail?.id,
            categoryList: collectionDetail?.categoryList,
            description: description,
            featuredImage: imgUrl,
            links: collectionDetail?.links,
            logo: collectionDetail?.logo,
            name: name,
          },
        })
      : await runCollection({
          bodyParams: {
            categoryList: [{ id: categorySelectValue }],
            description: description,
            featuredImage: imgUrl,
            links: '',
            logo: '',
            name: name,
            owner: userAddress,
          },
        })
  }

  return (
    <section className={styles.CreateSetion}>
      <CreateHeader miniTitle={t('createIndexMiniTitle')} title={t('createIndexTitle')} />
      <p className={styles.divider} />
      <div className={styles.title}>{t('createImgInfoTitle')}</div>
      <div className={styles.describe}>{t('createImgInfoDescribe')}</div>

      <section className={styles.createContent}>
        <ImgInfo
          imgUrl={imgUrl}
          errorText={imgNullCheck}
          imgStyle={styles.imgStyle}
          onChange={(res) => {
            if (typeof res === 'object') {
              setImgUrl('')
              setImgNullCheck(i18n.language === 'en' ? res?.msg_en : res?.msg)
            } else {
              setImgNullCheck('')
              setImgUrl(res)
            }
          }}
        />
        <section className={styles.infos}>
          <div className={styles.names}>
            <section className={styles.category}>
              <div className={styles.text}>{t('createIndexCategory')}</div>
              <div className={styles.categorySelect}>
                {categoryData && (
                  <BaseSelect
                    value={categorySelectValue}
                    options={categoryData?.data?.categoryList}
                    onChange={(value) => {
                      setCategorySelectValue(value)
                    }}
                    style={styles.selectStyle}
                  />
                )}
              </div>
            </section>
            <section className={styles.inputSection}>
              <BaseInput
                text={t('createIndexInputName')}
                isRequired
                value={name}
                placeHolder={t('createIndexInputNamePlace')}
                onChange={(value) => setName(value)}
                error={nameNullCheck}
              />
            </section>
          </div>
          <div className={styles.description}>
            <BaseTextArea
              text={t('createIndexInputDescription')}
              inputType="textarea"
              isRequired
              value={description}
              onChange={(value) => {
                setDescription(value)
              }}
              error={descriptionNullCheck}
            />
          </div>
        </section>
      </section>
      <p className={styles.submitDivider} />
      <div className={styles.submit} onClick={nonNullCheck}>
        {t(urlPair ? 'createIndexSubmitButton3' : 'createIndexSubmitButton')}
      </div>
    </section>
  )
}

export default Create
