import React from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { IcColl, IcNoColl } from '../../images'
import styles from './style.module.scss'

interface IProps {
  isVerify: boolean // 是否认证
  link?: string
  txt: string
}
const Tag = ({ isVerify, txt, link }: IProps) => {
  const { t } = useTranslation()
  const pathToLink = () => {
    if (!link) return
    window.open(link)
  }
  return (
    <div className={styles.tag} onClick={pathToLink}>
      <Tooltip title={isVerify ? t('verified') : t('unverified')}>
        <img src={isVerify ? IcColl : IcNoColl} alt="icon" className={styles.icon} />
      </Tooltip>
      <span>{txt}</span>
    </div>
  )
}

export default Tag
