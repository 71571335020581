/**
     * @desc 查询用户报价的NFT列表
用于我的报价页面，返回NFT作品详细信息，包含挂单信息和最高报价信息和我的报价信息
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.MyBidQuery
}

const ajax = new Ajax()

export function getMyBids(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/trading/getMyBids',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
