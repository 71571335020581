import React, { useEffect } from 'react'
import { HashRouter as Router } from 'react-router-dom'

import Layout from './components/Layout'
import { useTranslation } from 'react-i18next'

import './assets/locales/i18n'
import './App.css'
import './index.css'
import '@/services'
import { initConflux } from '@/utils/common'

function App() {
  const { i18n } = useTranslation()

  useEffect(() => {
    initConflux()
  }, [])

  useEffect(() => {
    const language = 'en'
    i18n.changeLanguage(language)
  }, [i18n])

  useEffect(() => {
    document.title =
      i18n.language === 'en' ? 'Tspace——Digital Asset Trading Platform' : 'Tspace——数字资产综合平台'
  }, [i18n.language])

  return (
    <Router>
      <Layout />
    </Router>
  )
}

export default App
