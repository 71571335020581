/**
     * @desc 查询当前用户token
前端登录portal获取钱包地址，将钱包地址作为参数调用该接口获取api访问的鉴权token
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.UserQuery
}

const ajax = new Ajax()

export function getToken(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/system/getToken',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
