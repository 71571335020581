import React, { useEffect, useState } from 'react'
import cs from 'classnames'

import styles from './style.module.scss'

export interface IOption {
  label: string
  value: any
}

export interface IProps {
  options: IOption[]
  value?: any
  onSelect?: (value: any) => void
}

function SelectList({ options, value, onSelect }: IProps) {
  const [current, setCurrent] = useState(value || null)

  useEffect(() => {
    setCurrent(value)
  }, [value])

  const handleClick = (value: any) => {
    setCurrent(value)
    onSelect && onSelect(value)
  }

  const renderItem = () => {
    return (options || []).map((item, index) => {
      return (
        <li
          key={index}
          className={cs({
            [styles.active]: item.value === current,
          })}
          onClick={handleClick.bind(null, item.value)}
        >
          {item.label.toUpperCase()}
        </li>
      )
    })
  }

  return <ul className={styles.container}>{renderItem()}</ul>
}

export default SelectList
