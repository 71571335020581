import { Conflux } from 'js-conflux-sdk'
import { user1, user2, user3, user4, user5, user6 } from '@/assets/images'

export const avatars = [user1, user2, user3, user4, user5, user6]

/**
 * 补全数字前面不够的0
 * @param num 要补全的数字
 * @param len 补全后的长度
 */
export const fullZero = (num: number | string, len: number): string => {
  if (typeof num === 'number') {
    num = num.toString()
  }
  if (num.length >= len) return num
  const arr = new Array(len - num.length).fill(0)
  return `${arr.join('')}${num}`
}

// 获取数组随机一项
export const getArrayItem = (arr: any[]) => {
  return arr[Math.floor(Math.random() * arr.length + 1) - 1]
}

// 字符串除取首尾各四位，其他用符号代替
export const replaceStr = (str: string, replaceStr: string) => {
  return str.replace(str.substring(4, str.length - 4), replaceStr)
}

// 地址显示前6后5位，其余用...代替
export const replaceAddr = (str: string) => {
  return str.replace(str.substring(6, str.length - 5), '...')
}

// 传参编码
export const encodeQuery = (query: { [key: string]: any }): string => {
  let queryStr = ''
  const keys = Object.keys(query)
  // a=1&b=2
  keys.forEach((item, index) => {
    queryStr = index ? `${queryStr}&${item}=${query[item]}` : `${queryStr}${item}=${query[item]}`
  })
  return queryStr
}

// 接收解码
export const decodeQuery = (queryStr: string): any => {
  const query: { [key: string]: any } = {}
  // 中文需解码
  queryStr = decodeURI(queryStr.replace('?', ''))
  const queryArr = queryStr.split('&')
  queryArr.forEach((item) => {
    const keyAndValue = item.split('=')
    query[keyAndValue[0]] = keyAndValue[1]
  })
  return query
}

// 判断地址是否为base32格式
export const isLikeBase32Address = (addr: string) => {
  // this won't return false when there's net1029, net1
  return /^(cfx(test)?|net\d+):(type\.(null|user|contract|builtin):)?[0123456789abcdefghjkmnprstuvwxyz]{42}$/i.test(
    addr
  )
}

// 补充数字位数
export const fillNumber = (num: number | string, len: number) => {
  const initNum = typeof num === 'number' ? num.toString() : num
  let fullNum = initNum
  if (initNum.length < len) {
    const arr = new Array(len - initNum.length).fill(0)
    fullNum = `${arr.join('')}${fullNum}`
  }

  return fullNum
}

// 获取url参数
export const getQueryVariable = (history: any, variable: string) => {
  const query = history.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

// 时间戳转时间格式化
export const forMateDate = (timeStamp: string) => {
  const now = new Date(timeStamp)
  const year = now.getFullYear()
  const month = now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1
  const date = now.getDate() < 10 ? '0' + now.getDate() : now.getDate()
  const hour = now.getHours() < 10 ? '0' + now.getHours() : now.getHours()
  const minute = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes()
  const second = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds()
  return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
}

// 随机获取头像
export const getAvatar = (account: string) => {
  if (!account) return
  const index = account.slice(account.length - 1).charCodeAt(0) % avatars.length
  return avatars[index]
}

// 版本号是否小于最低版本号
//版本号比较
export const versionStringCompare = (preVersion = '', lastVersion = '1.5.11') => {
  const sources = preVersion.replace('^', '').split('.')
  const dests = lastVersion.replace('^', '').split('.')
  const maxL = Math.max(sources.length, dests.length)
  let result = 0
  for (let i = 0; i < maxL; i++) {
    const preValue = sources.length > i ? sources[i] : 0
    const preNum = isNaN(Number(preValue)) ? preValue.charCodeAt() : Number(preValue)
    const lastValue = dests.length > i ? dests[i] : 0
    const lastNum = isNaN(Number(lastValue)) ? lastValue.charCodeAt() : Number(lastValue)
    if (preNum < lastNum) {
      result = 0
      break
    } else {
      result = 1
      break
    }
  }
  return result
}

export const initConflux = async () => {
  // console.log('init')
  const curChainId = await window?.conflux?.request({ method: 'cfx_chainId' })
  // 自行注入window.confluxJS
  window.confluxJS = new Conflux({
    networkId: curChainId / 1 || 1029,
  })
  window.confluxJS.provider = window?.conflux
  // console.log(window.confluxJS, 1111)
}
