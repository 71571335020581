import React, { useEffect } from 'react'
import { useToggle } from 'react-use'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import uncheckIc from './uncheck.png'
import checkedIc from './checked.png'

interface IProps {
  onCheck: (val: boolean) => void
}
const CheckList = ({ onCheck }: IProps) => {
  const { t } = useTranslation()
  const [check1, toogleCheck1] = useToggle(false)

  useEffect(() => {
    onCheck(check1)
  }, [check1])
  return (
    <div className={styles.checkList}>
      <div className={styles.item}>
        <img src={checkedIc} alt="" />
        <p>{t('createCollectiblePriceTips2')}</p>
      </div>

      <div className={styles.item}>
        <img src={check1 ? checkedIc : uncheckIc} alt="" onClick={toogleCheck1} />
        <p>{t('tipsCreate')}</p>
      </div>
    </div>
  )
}

export default CheckList
