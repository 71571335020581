import { Button } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  placeholder,
  placeholder1,
  placeholder2,
  placeholder3,
  placeholder4,
} from '@/assets/images'
import { useTranslation } from 'react-i18next'
import { useInterval } from 'ahooks'
import cs from 'classnames'

import styles from './style.module.scss'

function News() {
  const history = useHistory()

  const { t } = useTranslation()

  const swipers = [
    {
      title: t('break1'),
      desc: t('breakDesc1'),
      pic: placeholder,
    },
    {
      title: t('break2'),
      desc: t('breakDesc2'),
      pic: placeholder1,
    },
    {
      title: t('break3'),
      desc: t('breakDesc3'),
      pic: placeholder2,
    },
    {
      title: t('break4'),
      desc: t('breakDesc4'),
      pic: placeholder3,
    },
    {
      title: t('break5'),
      desc: t('breakDesc5'),
      pic: placeholder4,
    },
  ]
  const [swiper, setSwiper] = useState(0)

  // useInterval(() => {
  //   const newSwiper = swiper + 1
  //   if (newSwiper > swipers.length - 1) {
  //     setSwiper(0)
  //   } else {
  //     setSwiper(newSwiper)
  //   }
  // }, 13000)

  const pathToExplore = () => {
    history.push(`/explore`)
  }

  const renderLine = () => {
    return swipers.map((_item, index) => {
      return (
        <div
          className={cs({
            [styles.lineActive]: swiper === index,
            [styles.line]: swiper !== index,
          })}
          key={index}
          onClick={() => {
            setSwiper(index)
          }}
        />
      )
    })
  }

  const renderInfo = () => {
    return swipers.map((item, index) => {
      return (
        <div
          key={index}
          className={cs({
            [styles.fadeIn]: swiper === index,
            [styles.fadeOut]: swiper !== index,
          })}
        >
          <p className={styles.title}>{item.title}</p>
          <p className={styles.desc}>{item.desc}</p>
        </div>
      )
    })

    // return (
    //   <>
    // <div
    //   className={cs({
    //     [styles.fadeIn]: swiper === 0,
    //     [styles.fadeOut]: swiper !== 0,
    //   })}
    // >
    //   <p className={styles.title}>{t('break1')}</p>
    //   <p className={styles.desc}>{t('breakDesc1')}</p>
    // </div>
    //     <div
    //       className={cs({
    //         [styles.fadeIn]: swiper === 1,
    //         [styles.fadeOut]: swiper !== 1,
    //       })}
    //     >
    //       <p className={styles.title}>{t('break2')}</p>
    //       <p className={styles.desc}>{t('breakDesc2')}</p>
    //     </div>
    //   </>
    // )
  }

  const renderImage = () => {
    return swipers.map((item, index) => {
      return (
        <div
          key={index}
          className={cs({
            [styles.fadeIn]: swiper === index,
            [styles.fadeOut]: swiper !== index,
          })}
        >
          <img className={styles.image} src={item.pic} alt="" />
        </div>
      )
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        {renderInfo()}
        <Button onClick={pathToExplore} className={styles.discover}>
          {t('discover')}
        </Button>
        <div className={styles.scroll}>
          <span
            className={cs({
              [styles.lineTitle]: swiper !== 0,
              [styles.lineTitleActive]: swiper === 0,
            })}
          >
            01
          </span>
          <div className={styles.linesContainer}> {renderLine()}</div>
          <span
            className={cs({
              [styles.lineTitle]: swiper !== 1,
              [styles.lineTitleActive]: swiper === 1,
            })}
          >
            0{swipers.length}
          </span>
        </div>
      </div>
      {renderImage()}
    </div>
  )
}

export default News
