/* eslint-disable react/display-name */
import React from 'react'
import Home from '../pages/Home'
import Privacy from '../pages/Privacy'
import MySpace from '../pages/MySpace'
import Detail from '@/pages/Detail'
// import Create from '../pages/Create'
import Terms from '@/pages/Terms'
// import CreateCollectible from '../pages/Create/CreateCollectible'
import SellOrBuy from '../pages/Create/SellOrBuy'
import Explore from '@/pages/Explore'
import About from '@/pages/About'
import Faq from '@/pages/Faq'
import { RouteProps } from 'react-router-dom'
import Create from '@/pages/Create'
import CreateCollectible from '@/pages/Create/CreateCollectible'
import MyNFTCards from '@/pages/MySpace/components/MyNFTCards'
export interface MyRouteProps extends RouteProps {
  routes?: RouteProps[]
  component: any
}
const routes: MyRouteProps[] = [
  {
    path: '/',
    exact: true,
    component: () => <Home />,
  },
  {
    path: '/my-space',
    exact: true,
    component: () => <MySpace />,
  },
  {
    path: '/my-nft-cards',
    exact: true,
    component: () => <MyNFTCards />,
  },
  {
    path: '/create',
    exact: true,
    component: () => <Create />,
  },
  {
    path: '/create-collectible',
    exact: true,
    component: () => <CreateCollectible />,
  },
  {
    path: '/sell-or-buy',
    exact: true,
    component: () => <SellOrBuy />,
  },
  {
    path: '/explore',
    exact: true,
    component: () => <Explore />,
  },
  {
    path: '/detail',
    exact: true,
    component: () => <Detail />,
  },
  {
    path: '/privacy',
    exact: true,
    component: () => <Privacy />,
  },
  {
    path: '/terms',
    exact: true,
    component: () => <Terms />,
  },
  {
    path: '/about',
    exact: true,
    component: () => <About />,
  },
  {
    path: '/faq',
    exact: true,
    component: () => <Faq />,
  },
]
export default routes
