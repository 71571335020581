/**
     * @desc browse页NFT作品列表
用于查询browse页面数据，参考model说明
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.Browse
}

const ajax = new Ajax()

export function browseItemList(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/item/browseItemList',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
