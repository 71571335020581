import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { email, tspaceFooterLogo, wechat, wechatBox, emailBox } from '@/assets/images'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

const MyFooter = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const [showEmail, setShowEmail] = useState(false)
  const [showWeChat, setShowWeChat] = useState(false)

  return (
    <div className={styles.myFooter}>
      <div className={styles.conBox}>
        <div className={styles.con}>
          <img src={tspaceFooterLogo} className={styles.logo} alt="" />
          <div className={styles.link}>
            <div className={styles.center}>
              <p
                className={styles.item}
                onClick={() => window.open('https://developer.conflux-chain.org/')}
              >
                {t('developerDoc')}
              </p>
              <p className={styles.item} onClick={() => window.open('https://confluxscan.io/swap')}>
                {t('wcfxSwap')}
              </p>
              <p
                className={styles.item}
                onClick={() => window.open('https://fluentwallet.com/')}
              >
                {t('confluxPortal')}
              </p>
              <p className={styles.item} onClick={() => window.open('http://nft.tspace.io/')}>
                {t('cnfiCify')}
              </p>
              <p className={styles.item} onClick={() => window.open('https://guguo.io')}>
                {t('ancientChineseGods')}
              </p>
            </div>
            <div className={styles.right}>
              <p className={styles.item} onClick={() => history.push('/about')}>
                {t('aboutUs')}
              </p>
              <p className={styles.item} onClick={() => history.push('/faq')}>
                {t('faq')}
              </p>
              <img
                onMouseEnter={() => {
                  setShowEmail(true)
                }}
                onMouseLeave={() => {
                  setShowEmail(false)
                }}
                className={styles.itemImg}
                src={email}
                alt=""
              />
              <img
                onMouseEnter={() => {
                  setShowWeChat(true)
                }}
                onMouseLeave={() => {
                  setShowWeChat(false)
                }}
                className={styles.itemImg}
                src={wechat}
                alt=""
              />
              {showEmail && (
                <div className={styles.emailBox}>
                  <p>tspaceservice@gmail.com</p>
                </div>
              )}
              {showWeChat && <img className={styles.wechatBox} src={wechatBox} alt="" />}
            </div>
          </div>
        </div>

        <div className={styles.copyRight}>
          <p className={styles.copy}>Copyright © Tspace Platform. All Rights Reserved.</p>
          <p className={styles.item} onClick={() => history.push('./privacy')}>
            {t('privacy')}
          </p>
          <div className={styles.line}></div>
          <p className={styles.item} onClick={() => history.push('./terms')}>
            {t('terms')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default MyFooter
