import React from 'react'
import { coin, coinEdit } from '@/assets/images'

import styles from './style.module.scss'
import { useHistory } from 'react-router-dom'
import cs from 'classnames'

export interface IProps {
  text: string
  edit?: boolean
  imgSrc?: string
  id?: string
  onClick?: any
  style?: string
}

function Index({ text, edit = true, id, imgSrc, onClick, style }: IProps) {
  const history = useHistory()
  return (
    <div
      onClick={() => {
        onClick && onClick()
      }}
      className={cs(styles.container, style)}
      style={{ backgroundImage: `url(${imgSrc})` }}
    >
      <p className={styles.text}>{text}</p>
      <img className={styles.icon} src={coin} alt="" />
      {edit && (
        <img
          className={styles.coinEdit}
          src={coinEdit}
          alt=""
          onClick={(e) => {
            e.stopPropagation()
            history.push(`/create?collectionId=${id}`)
          }}
        />
      )}
    </div>
  )
}

export default Index
