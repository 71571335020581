const header = {
  marketplace: 'MARKETPLACE',
  create: 'CREATE',
  connect: 'Connect wallet',
  noInstallWorning: `You don't have wallet yet, please download fluent wallet first, then try again plz.`,
}

const home = {
  break: 'Breaking News Confix is launching',
  breakDesc:
    'Artists issue authenticated single edition digital artworks. These arecertified on the Ethereum blockchain to prevent forgery and providehistorical provenance.',
  break1: 'Breaking News: Decisive Moments in History NFT launching on Tspace',
  breakDesc1:
    'Cryptocat “Misaki-S26” created a series of Decisive Moments in History NFT. Einstein, David Bowie, Steve Jobs, Shang Gao, Pikachu, real or virtual, are dazzling in the long history of humanity! When historical figure meets crypto art, what kind of sparks will collide?',
  break2: 'Breaking News: Beijing Opera Facial Masks NFT launching on Tspace',
  breakDesc2:
    'The inheritor of the Intangible Culture Heritage, Mrs. Xu Yana, together with Tspace inherit the cultural heritage of 5,000 years of Chinese civilization and use digital technology to help intangible cultural heritage burst into a new look.',
  break3: 'Kane Tanaka-crylic paint & Pencil drawings Artwork',
  breakDesc3: `Kane Tanaka is a Japanese supercentenarian who at her age of 116 years, 81 days, is the world's oldest verified living person following the death of 117-year-old Chiyo Miyako on 22 July 2018.`,
  break4: 'ZODIAC Series is launching',
  breakDesc4: `This series of works aims to create a modern Ukiyo-e style atmosphere, meanwhile, giving a lot of imagination to those ancient Chinese totems, to pay tribute to the classical aesthetics, so far the elements used are currently tiger/dragon/monkey…`,
  break5: 'Breaking News: The Ghost Warrior Series is launcing on Tspace',
  breakDesc5: `The Ghost Warrior is the first series of artworks of the digital art creation laboratory - New Era. The new attempt at combining digital art and original music. This series conveys the laboratory’s character understanding of the NFT Metaverse. A piece of new crypto music for NFT also represents a new trend of crypto artwork from both the visual and auditory perspectives.`,
  discover: 'DISCOVER',
  trending: 'Trending Collections',
  various: '— VARIOUS DIGITAL ITEMS',
  explore: 'Explore',
  hotBids: 'Hot Bids',
  findMore:
    'Purchase at the asking price or make an offer by placing a bid. Once you own a piece you can resell it in the secondary market to other collectors.',
  findMoreButton: 'FIND OUT MORE',
  trends: '— TRENDS',
  lastNews: 'News',
  trendsDesc: `Although it was not a perfect start, it'll getbetter.Alt hough it it'll getbetter.`,
  lastNewsOne: `Roundtable: DeFi Evolution in the Web 3.0 - New Protocols and New Applications | FAT Awards Ceremony`,
  lastNewsTwo: 'Starting with The Lord of the Rings: The Genesis of the Ancient Chinese Gods',
}

const mySpace = {
  userInfo: '—USER INFO',
  mySpace: 'My Space',
  editProfile: 'Edit Profile',
  back: 'BACK TO COLLECTION',
  myCollections: 'COLLECTIBLES',
  putOnSell: 'PUT ON SELL',
  editNFT: 'Edit',
  highestBid: 'HIGHEST BID',
  yourBid: 'YOUR BID',
  address: 'Address',
  point: 'Points',
  mySpaceRefresh: 'Reload My NFTs',
  collectionInfo: 'COLLECTION INFO',
  mySpaceNftsMessage: 'NFTs changed',
  mySpaceNftsDesc: 'Please click the refresh my NFT button to get the latest information',
}

const create = {
  createIndexMiniTitle: '— Edit your collection info',
  createIndexTitle: 'Create your collection',
  createImgInfoTitle: 'Enter info below',
  createImgInfoDescribe:
    'preview can give you a breif view of how your collectible will be shown after you upload/enter the information.',
  ImgUpLoadTips: 'Format: PNG, GIF, WBEP, MP4, MP3',
  ImgUpLoadTipsMax: 'Max size: 100 MB',
  createIndexInputName: 'NAME',
  createIndexInputNamePlace: 'Collection Name',
  createIndexInputDescription: 'DESCRIPTION',
  createIndexCategory: 'CATEGORY',
  createIndexSubmitButton: 'CREATE',
  createIndexSubmitButton2: 'CREATE',
  createIndexSubmitButton3: 'UPDATE',
  createPass: 'Skip for now',
  payModalCancelButton: 'NEVERMIND',
  payModalComfirmButton: 'PAY',
  bidModalComfirmButton: 'BID',
}

const createCollectible = {
  createCollectibleMiniTitle: '— Edit your collectible info',
  createCollectibleTitle: 'Add collectible',
  CIP115: `A standard interface for contracts that manage multiple token types.
	A single deployed contract may include any combination of fungible
	tokens, non-fungible tokens or other configurations (e.g.
	semi-fungible tokens)`,
  createCollectibleInputName: 'ITEM NAME',
  createCollectibleInputNamePlace: 'The  Great Lines',
  createCollectibleInputDescription: 'DESCRIPTION',
  createCollectibleInputProperties: 'PROPERTIES',
  createCollectibleInputPropertiesPlace: 'Property',
  createCollectibleAddButton: 'ADD MORE',
  createCollectibleTips: `We do not own your private keys and cannot access your funds
	without your confirmation`,
  createCollectibleSubmitButton: 'CREATE',
  createCollectiblePayModalTitle: 'Mint Price',
  createCollectibleButton: 'PAY',
  createCollectiblePayModalSubTitle: 'Confirm your creation',
  createCollectiblePriceTitle: 'Fee',
  createCollectiblePriceBalance: 'Your balance',
  createCollectiblePriceTips1: 'The fee of 5USDT equivalent will be charged for each item to mint.',
  createCollectiblePriceTips2: `The information cannot be changed if the item has been minted,
	please carefully confirm and submit.`,
}

const createSellOrBuy = {
  createSellOrBuyMiniTitle: '— One last setp before ready',
  createSellOrBuyTitle: 'Put on sell',
  createSellOrBuyTypesTitle: 'Choose sell mode',
  typesCard1Title: 'Instant sale price',
  typesCard1Value: 'Sell at a fixed price',
  typesCard2Title: 'Highest  bid',
  typesCard2Value: 'Action to the highest bidder.',
  createSellOrBuySaleInputName: 'instant sale price',
  createSellOrBuySaleInputNamePlace: 'Set price',
  createSellOrBuyBidInput1: 'Minimum bid',
  createSellOrBuyBidInput1Place: 'Set your starting bid price',
  createSellOrBuyBidInput2: 'Reserve price',
  createSellOrBuyBidInput2Tips: `If any bids above this price, it will clinch a deal
	automatically when the end of expiry date`,
  createSellOrBuyBidInput3: 'Expirydate',
  createSellOrBuyBidInput3Tips: `Your auction will automatically end at this time and the
	highest bidder will win.`,
  createSellOrBuyPayModalComfirmButton: 'SELL',
  createSellOrBuyPayModalTitle: 'Confirm to sell',
  createSellOrBuyPayModalSubTitle: 'artwork Name',
  createSellOrBuySaleModalTitle: 'Sell price',
  createSellOrBuySaleModalTips: 'Your item will be sell in a fixed price.',
  createSellOrBuyBidModalTitle: 'Reserve price',
  createSellOrBuyBidModalTips1: 'Your item will be a auctioned',
  createSellOrBuyBidModalTips2: 'The highest bidder will win it on',
  sellOrBuyFee: 'Fee',
  sellOrBuyTotalReceive: 'total receive',
  sellOrBuyCustomize: 'customize',
  sellOrBuyOneWeek: '1W',
  sellOrByOneMonth: '1M',
  sellOrByExpiryTime: 'select date',
  sellOrByApprovalSuccess: 'Authorization is successful, please click the button again to sell',
  createSellOrBuyCreateSuccess:
    'Your collection is under review. After passing the check, you are allowed to continue to create',
  createSellOrBuyCreateSuccessTitle: 'Tips',
}

const createError = {
  errorName: 'Please enter your NFT name',
  errorDescription: 'Please fill in the blanks with the NFT description',
}

const detailModal = {
  title: 'Payment Steps',
  main1: 'Approve',
  main2: 'Signature',
  desc1: 'Send transaction with your wallet',
  desc2: 'You have to bid a price that higher than the highest current price',
  button1: 'Approve',
  button2: 'Sign',
  makeBid: 'Make Your Bid',
  confirmOrder: 'Confirm Your Order',
  yourBid: 'Your Bid',
  total: 'Total',
  atLeast: 'At leaset',
  yourBalance: 'Your balance',
  bidSuc: 'Bid Success',
  getWcfx: 'GET WCFX',
  pay: 'PAY',
}
const detailPage = {
  curPrice: 'Current Price',
  highestPrice: 'Highest Price',
  miniPrice: 'Minimum Price',
  saleEnd: 'Sale ends in',
  overEnd: 'Awaiting for seller accept',
  notOnSale: 'Not on sale yet',
  accept: 'ACCEPT',
  sell: 'SELL',
  cancelSell: 'CANCEL SELL',
  bugNow: 'BUY NOW',
  bid: 'BID',
  paySteps: 'Pay Steps',
  currentBids: 'Current Bids',
  history: 'History',
  noData: 'No Data in this section.',
  sellTips: 'After you put on sale, information will be automatically update',
  info: 'Info',
  chainInfo: 'Chain Info',
  properties: 'Properties',
  contractAddress: 'Contract address',
  tokenId: 'Token Id',
  blockchain: 'Blockchain',
  owner: 'Owner',
  creator: 'Creator',
  hours: 'Hours',
  seconds: 'Seconds',
  minutes: 'Minutes',
  days: 'Days',
  tips1: `I acknowledge that this item that has not been verified or approved by Tspace, and use at
  my own risk.`,
  tips2: 'I agree to tspace risk',
  tips3: 'Warning and Disclaimer.',
  tipsCreate:
    'I guarantee that the uploaded NFTs do not contain any reactionary, pornographic, pirated, or other illegal information and images. In case of violation, I accept the penalty of automatically offloading the NFT from the platform and deciding not to refund the insurance fee.',
  hoverTips: 'Insufficient balance',
  detailTitile: 'ITEM INFORMATION',
  verified: 'Verified collection',
  unverified: 'Unverified collection',
  detailHideInfoLabel: 'Reveal unlockable content',
  detailHideInfoModalLabel: 'Tram content',
  detailHideInfoLogin: 'Please login first',
  detailHideInfoBuy: 'Unlock after purchase',
  detailCRC721: 'CRC721 NFT is currently not supported ',
}

const explore = {
  variousDigitalItems: 'VARIOUS DIGITAL ITEMS',
  explore: 'Explore',
  status: 'Status',
  collections: 'Collections',
  items: 'items',
}

const timeList = {
  recentList: 'Recentlist',
  endingSoon: 'Ending soon',
  priceHighToLow: 'Price high to low',
  priceLowToHigh: 'Price low to high',
  highestBids: 'highest bids',
}

const common = {
  acceptSuc: 'Accept offer successfully',
  copySuc: 'Shared address copped successfully',
  cancelOfferSuc: 'Cancel offer successful',
  cancelSuc: 'Cancel listing successful',
  sellSuc: 'Sell successful',
  unLock: 'Unlock',
  disconnect: 'Wallet disconnected',
  approveWallet: 'Please connect to your wallet again',
  copySuccess: 'copy successful',
  walletVersion: `I'm sorry! Your ConfluxPortal version is too old, please promptly upgrade!`,
  refresh: 'Refresh successful',
  price: 'price',
}

const nftCardModal = {
  confirmTransfer: 'Confirm transfer',
  artworkName: '—artwork Name',
  transferTo: 'Transfer to',
  walletAddress: 'Wallet address',
  walletAddressDetail:
    'To ensure that NFT arrives safely,  plase make sure that this address is a valid wallet address.',
  nevermindButton: 'NEVERMIND',
  nftCardModalConfirm: 'CONFIRM',
}

const card = {
  highestBid: 'HIGHEST BID',
  minsLeft: 'mins left',
  instantPrice: 'INSTANT PRICE',
}

const infoModal = {
  infoLoading: 'Wait a bit',
  infoLoadingDesc: 'Plz wait, In progress...',
  infoSucceed: 'Succeed',
  infoSucceedTrans: 'Transferred successfully',
  infoSucceedDesc: 'View details...',
  infoCancel: 'Cancel bid',
  infoCancelDesc: 'Do you want to cancel your bid?',
  infoCancelButton: 'CANCEL',
}

const footer = {
  footerTitle: 'NEWEST COLLECTIONS',
  slogin: 'GROW YOUR COLLECTION',
  developer: 'Conflux Developers',
  developerDoc: 'Developer Documents',
  wcfxSwap: 'WCFX Swap',
  confluxPortal: 'Conflux Portal',
  cnfiCify: 'ConFi City',
  ancientChineseGods: 'Ancient Chinese Gods',
  wallet: 'Wallet',
  download: 'Download',
  about: 'About',
  aboutUs: 'About us',
  city: 'ConFi CITY',
  faq: 'FAQ',
  contract: 'Contact',
  privacy: 'Privacy',
  terms: 'Terms',
  catalogueText: 'CATALOGUE',
  marketPlace: 'Marketplace',
  read: 'I have read, understood and accept the terms and conditions',
  safeModal: {
    title: 'Risk Warning and Disclaimer',
    tips: 'Please read it carefully to fully understand all the terms contained in this Risk Warning and Disclaimer before using the service provided by this platform. Your acceptance of the service means that you confirm the following terms.',
    descs: [
      '1. When using the service, if the user or the counterparty fail to obey this Agreement or the relevant instructions on the website, or the operation instructions or rules, the Tspace Platform shall not undertake any damage caused thereby.',
      '2. You acknowledge and agree that Tspace discourages, prohibits and restricts any transaction or exchange that occurs through Tspace Platform, all transactions via Tspace Platform or a third party’s DApp are deemed to be the user’s individual behavior, and thus the binding contractual relationship is established only between the user and his counterparty, which is independent with the Tspace Platform and Tspace. No risks, liabilities, losses or expenses arising from the above trading activities shall be borne by the Tspace Platform and Tspace.',
      '3. We suggest that you shall use the Tspace Platform in a safe network environment to avoid potential security risks.',
      '4. Please be careful about frauds from third parties when using services provided by the Tspace Platform. Once such fraud occurred, we encourage you to inform us immediately.',
      '5. No liability shall be borne by the platform for the disclosure of personal profile and client information resulting from the fact that you inform others of your password, or share a registered account with others.',
      '6. Where permitted by law, such as the requirements by the judiciary, regulatory agencies and other relevant agencies, we may provide them with your relevant information.',
      '7. All actions performed under your Honeycomb Planet username are considered to be your actions, and the legal consequences shall be bore by you.',
    ],
    agree: 'I agree',
  },
  view: 'START VIEWING',
  topBoxText: ['THE MARKETPLACE FOR', 'VIRTUAL ASSETS EXCHANGE', 'WITH CONFLUX BLOCKCHAIN'],
}

export const privary = {
  privaryTitle: 'Privacy Policy',

  privaryDescs: [
    {
      title: '',
      children: [
        'Dear users,',
        'Tspace Team (hereinafter referred to as “Tspace”, “we” or “us”) respect and protect the privacy of the users (hereinafter referred to as "you" or "users"). When you use the Tspace Platform, we may collect and use your personal information in accordance with this Privacy policy of the Tspace Platform (hereinafter referred to as the “Policy”).',
        'We suggest you read carefully and understand all the contents of this Policy before using this product (hereinafter referred to as the “Tspace Platform” or “Platform”). The important information, such as the disclaimer and other clauses, will be stressed in bold. The key words in this policy shall have the same meaning as such terms ascribed in the User Terms of Service.',
        'This Policy can be updated at any time by us. Once the updated policy is published, it will replace the original one. Please stop using the Tspace Platform immediately, if you do not accept the revised terms. Your continuous use of the Tspace Platform will be considered as accepting the revised policy. The revised policy, once announced on the Tspace Platform, will come into force automatically and immediately.',
        'You have the knowledge that this policy and other relevant provisions shall be applied to the Tspace Platform and the DApp owned by Tspace.',
      ],
    },
    {
      title: 'Information Collection',
      children: [
        'Please note that we collect the following information for you to meet your needs in the using of the Platform, and great importance will be attached to the protection of your privacy. When we collect your information, the principle of legitimacy, justification and necessity will be strictly abided by. And please note that if you do not provide the relevant information to us for our service, your service experience on the Tspace Platform may be affected.',
        '1.We will collect personal information about your mobile device data, operation records, transaction records and wallet address, etc.',
        '2.To satisfy your specific needs, we will collect your name, mobile phone number, e-mail address and other information.',
        '3.Please note that your wallet password, private key, mnemonic word, and Keystore on the Tspace Platform are not stored or synchronized to the Tspace server. We will not provide services to retrieve your wallet password, private key, mnemonic word and Keystore.',
        '4.Apart from the above, please note that when you use the specific services of Tspace Platform, a special notice will be given before more personal information of yours is collected. Your refusal will be deemed to abandon the specific service of the Tspace Platform. ',
        '5.When you log in a third-party’s DApp, your personal information may be collected by them. The Tspace Platform will not store any personal information collected by them.',
        '6.To the maximum extent permitted by laws and regulations, your personal information may be collected and used in the following circumstances without your consent:',
        '(1) relating to national security and national defense security;',
        '(2) relating to public safety, public health and major public interests;',
        '(3) relating to criminal investigation, prosecution, adjudication and execution of judgments, etc.;',
        '(4) any personal information that has been published by you;',
        '(5) the personal information collected from public available information, such as legitimate news reports, government information disclosure, etc.;',
        '(6) necessary to maintain the safety and compliance of services, such as finding and handling failures in products and services;and',
        '(7) other circumstances provided by the laws and regulations.',
        '7. Ways of collecting information are as follows:',
        '(1) we collect information provided by you, e.g., when you fill in the name or mobile phone number in the "personal center" page; provide an email address when you give feedback on our work to us; or use our specific services and hence provide us with the relevant information accordingly;',
        '(2) we collect information when you use the Tspace Platform, including the mobile device data and operation records on the Tspace Platform.',
      ],
    },
    {
      title: 'Information Usage',
      children: [
        '1. We will confirm the congruent relationship between you and your wallet through the unique serial number of your mobile device.',
        '2. The important notices will be sent to you in time, such as website updates, User Terms of Service and changes in this Policy.',
        '3. Your wallet address published by you and mobile device information will be collected and used to handle your feedback.',
        '4. Your personal information collected will be used for internal audit, data analysis and research to continuously improve our service.',
        "5. According to the User Terms of Service and other related regulations by Tspace, we will use the user information to manage and handle the user's usage behavior.",
        '6. We will comply with requirements of laws and regulations and cooperate with the regulators.',
      ],
    },
    {
      title: 'Information Control',
      children: [
        'We strive to provide users with information security to prevent information loss, improper use, unauthorized access or disclosure.',
        'Various measures will be taken to ensure the security of the collected information to a reasonable extent. For example, encryption anonymization and other means will be used to protect your personal information.',
        'Specialized management systems, processes and organizations will be established to ensure the safety of information. For example, personnel access to information will be strictly limited and the confidentiality obligation will be required to abide by and examined.',
        'In the event of a security incident such as personal information leakage, an emergency plan will be initiated to prevent the disclosure from expanding and inform you by push notifications, announcements, etc.',
      ],
    },
    {
      title: 'Information Sharing and Transfer',
      children: [
        'Without your prior consent, your personal information will not be transferred to or shared with any third party, except that:',
        '(1) we obtain your explicit consent or authorization in advance;',
        '(2) the personal information has been disclosed by you;',
        '(3) the personal information is collected from legal public information, such as legal news reports and government information disclosure channels;',
        '(4) we may share the necessary user information with the related parties of Tspace, which will be bound by the purpose stated in this Policy;',
        '(5) we may provide necessary information in compliance with applicable laws and regulations, legal procedures, and requests by administrative or judicial authorities;and',
        '(6) in the case of mergers and acquisitions, if involving personal information transfer, we will require the recipient of personal information to continue to be bound by this Policy.',
      ],
    },
    {
      title: 'Information Protection',
      children: [
        '1.If Tspace ceases to operate, we will stop the collection of your personal information in a timely manner, announce the suspension of operations on the Platform, and delete or anonymously hold your personal information within a reasonable period of time.',
        '2. In order to protect your personal information, we will adopt technical measures of data safety to improve internal compliance, increase internal employee information security training, and secure access to relevant data to protect your private information.',
        '3.Security information will be sent from the “Contact Email: tspaceservice@gmal.com” on the Platform, and wallet updating and information protection will be published from time to time in the “FAQ Section” for your reference.',
      ],
    },
    {
      title: 'Special Protection for Minors',
      children: [
        'We make the following special agreements for minors (under 18):',
        '1. Minors shall, under the guidance of their parents or guardians, use the services provided by us.',
        '2. We suggest that before guiding the minors to use the Platform, parents or guardians shall read this Policy, the User Terms of Service and other relevant provisions.',
        '3. We will protect the personal information of the minors in compliance with the applicable laws and regulations.',
      ],
    },
    {
      title: 'Disclaimer',
      children: [
        '1.Please note that when you log in a third-party DApp through the Platform, the privacy policy of such a third-party DApp shall apply. The collection and use of your personal information by this third-party DApp is not under the control of Tspace and is not subject to this Policy. Tspace cannot guarantee that third-party DApp will protect personal information according to the requirements of Tspace.',
        '2.To properly protect your personal information, prudence shall be attached when selecting and using a third-party DApp. No liability shall be borne by Tspace for the privacy protection of a third-party DApp.',
        '3.To the maximum extent permitted by the present technology, reasonable security measures will be taken to protect your personal information, to avoid information leakage, tampering or damage. Tspace uses wireless means to transmit data. Due to the attributes of wireless transmission technology, the privacy and security of data, transmitted through wireless networks, are not guaranteed.',
      ],
    },
    {
      title: 'Miscellaneous',
      children: [
        '1.You shall fully understand and comply with all applicable laws, regulations and rules in your respective jurisdiction regarding the use of the services provided by Tspace.',
        '2.You can contact us by submitting feedback through the Platform while you are facing any problems relating to the use of personal information.',
        '3.This policy and other service rules can be viewed on the Platform. And it is greatly encouraged to consult the terms of service and privacy policy of Tspace every time you visit the Platform.',
        '4.Any translation of this Policy is provided for the convenience of users only, not intended to modify the terms of this Policy. If there is a conflict between the Chinese version and the non-Chinese version(s), the V1.11.2020 version shall prevail.',
        '5.This policy shall apply since 01 November, 2020.',
      ],
    },
  ],
  privaryTips:
    'Anything not covered by this policy shall be complied with announcements and rules updated by Tspace from time to time.',
  privaryTeam: 'Tspace Team',
}
export const terms = {
  title: 'User Terms of Service',
  descs: [
    {
      title: '',
      children: [
        'Respected users:',
        'Thank you for choosing services provided by the Tspace Platform. The User Terms of Service (hereinafter referred to as the "Agreement") is made and entered into by and among Tspace Team (hereinafter referred to as "Tspace" or "us") and the user (hereinafter referred to as "you" or "user"). This agreement is a legally binding document between user and Tspace.',
        'Please read carefully the agreement and the related agreements mentioned below, especially the "Disclaimer and liability limitation" Section reflected in a coarse form thereof to ensure that you fully understand the Agreement and consider risks independently.',
      ],
    },
    {
      title: '1. Confirmation and acceptance of the Agreement',
      children: [
        '(1) You understand that this Agreement and related agreements apply to Decentralized Applications ("DApps", but excluding the third-party developed ones) developed and owned by the Tspace Platform and Tspace.',
        '(2) Once opening the Tspace Platform website, creating or importing it into the Wallet, you are deemed to have fully read and accepted all the terms of this Agreement. Accordingly, this Agreement comes into force immediately and has binding effect on both parties.',
        '(3) This Agreement can be updated at any time by Tspace. Once the revised agreement is published on the Tspace Platform, it shall automatically take effect without further notice. After the newsletter announces the terms of the revised agreement, if you do not accept the revised terms, please stop using the Tspace Platform immediately; otherwise your continued use of the Tspace Platform shall be deemed to accept the revised agreement. ',
        '(4) If you are under 18 years old, or with no or limited capacity for civil conduct, your use of the Tspace Platform shall be under the guidance of parents or guardians.',
      ],
    },
    {
      title: '2.Service',
      children: [
        '(1) Wallet Creating or Importing.',
        'For the digital tokens supported by the Tspace Platform, you can use Tspace Platform to generate a new Wallet or import compatible Wallets generated by other wallet tools in the relevant blockchain system',
        '(2) Digital Assets Management.',
        'The digital tokens supported by the Tspace Platform can be added, stored and removed via the Tspace Platform. ',
        '(3) DApp Browsing.',
        'Users can jump to the DApp via the links on the Tspace Platform and use relevant services provided by it (including the one owned by Tspace and other third parties DApp and third-party DApp)',
        '(4) Exchange records.',
        'All or part of the exchange records will be copied through the block-chain system. However, the exchange records are subject to the block-chain system.',
        '(5) Other services that Tspace considers necessary.',
      ],
    },
    {
      title: '3. Rights and Obligations',
      children: [
        '(1) Wallet creating or importing',
        '(A) Wallet creating or importing: ',
        'You have the right to create and / or import your wallet on your mobile device via the Tspace Platform, to set up the wallet password and other information.',
        '(B) Authentication: ',
        'In accordance with the relevant laws, regulations and policies, specific users should complete the relevant identity verification in accordance with the instruction of the Tspace Platform when using the services provided by Tspace Platform. Relevant personal information which shall be provided includes, but not limited to, user’s name, mobile phone number, etc. Otherwise, the specific user will not be able to use the service, and the loss caused by the delay of the specific user is at his/her/its own risk.',
        '(2) Use',
        '(A) Before using the Tspace Platform, registration shall be completed to get the account and password. The registration information includes, but not limited to, all information inputted when registering or update the account, name, avatar, password, etc. Mobile device, wallet password, private key, seed phrase, Keystore and other information shall be kept by users themselves properly, for which Tspace is not responsible. All risks, responsibilities, losses and expenses caused by the missing of mobile devices, active or passive disclosing, forgetting of wallet password, private key, Keystore, or being attacked and deceived, shall be borne by users themselves.',
        '(B) Observance hints. Please note hints given by the Tspace Platform and follow it accordingly. Otherwise, all the risks, responsibilities, losses, expenses, and the like, shall be borne by users themselves.',
        '(C) Please note and understand that the Tspace Platform is not obliged to perform due diligence on services or any other behaviors provided by a third party’s DApp, and users should make reasonabledecisions and undertake the corresponding risks.',
        '(D) Authentication cooperation. ',
        'If the Tspace Platform reasonably consider that the user’s behavior are abnormal, and identity information is questionable, or if the Tspace Platform think it is necessary to check identity documents or other relevant documents, please actively cooperate with the Tspace Platform to complete relevant authentication in a timely manner.',
        '(E) Compliance.',
        'You shall be aware that all the applicable laws and regulations shall be observed throughout the exchange on the Tspace Platform or via DApp provided from it.',
        '(H) Notice.',
        'Please note that information on exchange, hint on operation and the like may be sent through website bulletin, email, SMS, phone, message center information, pop-up hints or client notifications, etc.',
        '(I) Service fees and tax obligations.',
        'The Tspace Platform will not charge you any service fees or handling fees for the time being. If it is necessary for the Tspace Platform to charge some services in the future, new rules will be reached or published.',
      ],
    },
    {
      title: '4. Risk warning',
      children: [
        '(1) When using the service, if the user or the counterparty fail to obey this Agreement or the relevant instructions on the website, or the operation instructions or rules, the Tspace Platform shall not undertake any damage caused thereby.',
        '(2) You acknowledge and agree that Tspace discourages, prohibits and restricts any transaction or exchange that occurs through Tspace Platform, all transactions via Tspace Platform or a third party’s DApp are deemed to be the user’s individual behavior, and thus the binding contractual relationship is established only between the user and his counterparty, which is independent with the Tspace Platform and Tspace. No risks, liabilities, losses or expenses arising from the above trading activities shall be borne by the Tspace Platform and Tspace.',
        '(3) Please note that when creating or importing a wallet on the Tspace Platform, Keystore, private key, and seed phrase are stored only in the current mobile device rather than the Tspace Platform or the server of Tspace. Synchronizing wallet can be used to change mobile devices under the guidance of instructions provided by the Tspace Platform. If wallet password, private key, seed phrase, Keystore and other information are not saved or copied and the relevant mobile devices is lost at the same time, token will be lost as well. If the token is lost, Tspace can’t retrieve it back, and you shall undertake losses incurred on your own. You shall bear the losses caused by information disclosure when exporting, saving, or coping wallet password, private key, seed phrase, Keystore and other information, or the losses of the devices or servers storing the above information caused by hacker.',
        '(4) We suggest that you shall carefully back up the wallet password, private key, seed phrase and Keystore when creating or importing into the wallet. Nevertheless, the following backup measures shall not be taken: screenshots, mail, notepad, SMS, Wechat, QQ and other electronic backup methods. We suggest that you shall write down seed phrase, Keystore and other information in a notebook, and save the electronic data in the password manager at the same time.',
        '(5) We suggest that you shall use the Tspace Platform in a safe network environment to avoid potential security risks.',
        '(6) Please be careful about frauds from third parties when using services provided by the Tspace Platform. Once such fraud occurred, we encourage you to inform us immediately.',
      ],
    },
    {
      title: '5. Service Change, Suspension and Termination',
      children: [
        '(1) You agree that Tspace may temporarily provide some service functions to ensure the autonomy of its business operations, or may suspend some service functions or open new service functions in the future. When any service is reduced, increased or changed, users’ continuous use will be regarded as they still agree with the terms of this agreement or its revised version.',
        '(2) In order to protect your digital token security, and avoid the mis-operation or risk due to the lack of basic knowledge regarding blockchain when using the Tspace Platform, you shall pass the capacity evaluation questionnaire and obtain certain scores required by Tspace before using the Tspace Platform. Otherwise, Tspace has the right to restrict or refuse to provide part or all of the service functions.',
        '(3) You shall be aware that services will be suspended under the following circumstances:',
        '(A) The business is interrupted due to the maintenance, upgrade, failure of equipment, block-chain system, communication interruption and other technology reasons.',
        '(B) Due to force majeure events such as typhoon, earthquake, tsunami, flood, power failure, war or terrorist attack, virus, Trojan horse, hacker attack, system instability or government action etc., service can’t be provided by the information system, or providing continuous services is relatively risky.',
        '(C) Other situations that can’t be controlled or reasonably foreseen by Tspace.',
        '(3) All or part of service of the Tspace Platform may be suspended or terminated unilaterally by Tspace under the following circumstances:',
        '(A) Death of the user;',
        "(B) Embezzlement of others' wallet information or mobile devices;",
        '(C) Providing false information when filling in personal information, or not filling in the questionnaire in person.',
        '(D) Rejecting the mandatory update operations initiate by Tspace to enhance the functions of the Tspace Platform.',
        '(E) Using the Tspace Platform for illegal or criminal purposes;',
        '(F) Impeding the normal use of other users;',
        '(G) Pretending to be a staff or manager of Tspace;',
        '(H) Attacking, invading, altering or through other ways to threaten the operation of Tspace’s computer system;',
        '(I) Posting spam advertisements through the Tspace Platform;',
        '(J) Spreading rumors to hurt the goodwill of Tspace and the Tspace Platform;',
        '(K) Violations of laws and this agreement, and situation that Tspace considers it necessary to suspend services.',
        '(4) Users still have the right to export your wallet and other information within a reasonable time when the service relationship between users and Tspace changes, interrupts, or terminates.',
      ],
    },
    {
      title: '6. Commitment of legal use',
      children: [
        '(1) Laws and regulations of the country or area you live in shall be well observed. The Tspace Platform and relevant services shall not be used for illegal purposes.',
        '(2) The Tspace Platform shall not be used for illegal or criminal conducts, including but not limited to:',
        '(A) Opposing basic principles set by the Constitution, endangering national security, disclosing state secrets, subverting state power, or undermining national unity;',
        '(B) Engaging in any illegal and criminal activities, including but not limited to, money laundering, illegal fund-raising, etc.',
        '(C) Accessing to services provided by Tspace through any automatic program, software, engine, web crawler, web page analysis tool, data mining tool, or any other ways, collecting or processing contents provided by Tspace, intervening or attempting to intervene users; ',
        '(D) Providing gambling information or seducing others to participate in gambling;',
        "(E) Intruding on others' wallet to steal token;",
        '(F) Engaging in any activities of infringing or potentially damaging the Tspace Platform service system and data;',
        '(G) Other violations and inappropriate behavior that Tspace has legitimate reason to believe.',
        '(3) You shall understand and agree that if you violate relevant laws (including but not limited to customs and tax regulations) or relevant provisions of this Agreement, you shall compensate Tspace any damages suffered therefrom, such as compensation, fines caused by any third party or any administrative department and/or related reasonable attorney fees.',
        '(4) Users shall pay services on time (if any) as promised. Otherwise, Tspace has the right to suspend to provide services.',
      ],
    },
    {
      title: '7. Privacy Clauses',
      children: [
        'Tspace attaches great importance to the protection of users’ privacy. For related privacy protection policies, please refer to the Tspace Platform Privacy Policy published and updated by Tspace from time to time.',
      ],
    },
    {
      title: '8. Disclaimer and Limitation of Liability',
      children: [
        '(1) No responsibilities shall be shouldered by Tspace except those specified in this agreement.',
        '(2) Please note that services provided by Tspace are to the maximum extent permitted by current technology in accordance with applicable laws. No responsibilities shall be liable to Tspace, especially for the following causing factors:',
        '(A) Downtime maintenance or upgrade of the Tspace Platform;',
        '(B) Force majeure events such as typhoons, earthquakes, floods, thunder and lightning or terrorist attacks;',
        '(C) Failure of users’ mobile device hardware and software, communication lines and power supply lines.',
        '(D) Disoperation or use of services that is not authorized or recognized by Tspace.',
        '(E) Causes such as virus, Trojan horse, malicious program attack, network congestion, system instability, system or equipment malfunction, communication malfunction, power failure, bank or government action, etc.',
        '(F) Any other reasons irrelevant to Tspace.',
        '(3) No responsibilities shall be liable to Tspace under the following circumstances:',
        '(A) Loss of tokens caused by missing mobile devices, deleting and failing to back up the Tspace Platform, deleting and failing to back up wallet, purse stolen or losing wallet password, private key, seed phrase, and Keystore;',
        "(B) Loss of tokens arising from user's self-disclosure of wallet password, private key, seed phrase, Keystore, lending, transferring, or authorizing others to use their own mobile devices or the wallet, downloading the Tspace Platform through an unofficial channel, or using the Tspace Platform in other unsafe manners;",
        '(C) Loss of tokens due to mis-operation of users (including but not limited to inputting wrong transfer address, choosing transfer node server by oneself);',
        '(D) Loss of tokens due to mis-operation caused by failing to understand the nature of the block-chain technology;',
        '(E) Inconsistencies of the exchange records caused by time lag and the instability of block chain system;',
        '(F) Risks and consequences caused by operation through a third party DApp.',
        '(4) None of the following guarantees are provided:',
        '(A) services of the Tspace will satisfy all your needs;',
        '(B) any technology, product, service and information that you get through Tspace will meet your expectations.',
        '(5) In any case, the total amount of liability for breach under this agreement shall be 10000 RMB.',
        '(6) You shall be aware that the Tspace Platform only serves as a tool to display exchange information. Legal, tax or investment services are not provided. You shall seek advice from the legal, tax, and investment professionals, and no loss or others shall be borne by Tspace in the process of using the services.',
        '(9) You shall be aware that access standards may be changed from time to time, and scope and manner of providing services to a specific group may be limited.',
      ],
    },
    {
      title: '9. Entire Agreement',
      children: [
        '(1) The User Terms of Service, Privacy Policy of the Tspace Platform and any various published by Tspace shall constitute the entire Agreement.',
        '(2) If part of Agreement is deemed illegal or invalid by the court with jurisdiction, the validity of the remaining provisions shall not in any way be affected or impaired.',
        '(3) Any translation version of this Agreement is provided for the convenience of users only, not intending to modify the terms of this policy. If there is a conflict between the Chinese version and the non-Chinese version, the V11.1.2020 version shall prevail.',
      ],
    },
    {
      title: '10. Protection of Intellectual Property Rights (“IPR”)',
      children: [
        'APP developed and owned by the Tspace Platform IPR of any content (including this Agreement, bulletins, articles, videos, audios, pictures, files, information, documents, trademarks or logos) of the Tspace Platform shall be owned by Tspace or a specific third party. APP of the Tspace Platform and its content can only be used for holding and managing tokens. Use, modification, reverse compiling, copying, disseminating, changing, publishing, or issuing or publishing of the above application and content shall not be allowed without prior written consent of Tspace or this specific third party.',
      ],
    },
    {
      title: '11. Applicable Laws and Disputes Resolution',
      children: [
        '(1) Laws of [*] shall apply to the validity, interpretation, change and enforcement of this Agreement and its revised edition, and dispute settlement. Without the above laws, international business practice and / or industry practice shall be applied.',
        '(2) All disputes between users and Tspace thereof, shall be settled amicable by negotiation in the first place. In case no settlement can be reached, each party shall submit the dispute to the court with jurisdiction where Tspace is domiciled.',
      ],
    },
    {
      title: '12. Miscellaneous',
      children: [
        '(1) User shall fully understand and comply with all applicable laws, regulations and rules when using services provided by Tspace.',
        '(2) You may contact us by submitting feedback via the Tspace Platform when you meet any problems relating to the use of personal information.',
        '(3) This policy and other service rules may be viewed on the Tspace Platform. And Tspace encourages you to read the Tspace Platform service agreement and privacy policy each time you visit the Tspace Platform.',
        '(4) This policy shall apply since 01 November, 2020.',
      ],
    },
  ],
  tips: 'Anything not covered by this policy shall be complied with announcements and rules updated by Tspace from time to time.',
  team: 'Tspace Team',
}

export const about = {
  rules: [
    {
      title: 'What’s the Tspace platform',
      descs: [
        "Tspace - Tresure space, is the first decentralized digital asset trading platform based on the Conflux Network, capable of serving the needs of multiple users to manage their digital collectibles NFT (Non-Fungible Token), which has the unique attributes of uniqueness, scarcity and non-replicability and can be applied to a variety of virtual and real application scenarios. With the advantages of Conflux Network's high-speed, secure and low-cost technology, it can better support the casting, display and circulation of NFT, providing users with an open and inclusive integrated management platform and a healthy and prosperous digital commodity market.",
        'As an ecosystem project incubated by the Conflux technology community, the Tspace team provides a quality digital asset management platform for crypto artists and collectors through innovative technology and platform advantages, establishes a non-Fungible and interoperable standards protocal on the Conflux Network, and provides a tool and integrated marketplace for developers, artists, and users with ideas. is committed to exploring and practicing ways to popularize blockchain technology, guiding people into the magical world of crypto art and enjoying the changes of life brings with technology.',
      ],
    },
  ],
  aboutUs: 'About us',
}

export const faq = {
  rules: [
    {
      title: 'FAQs',
      descs: [
        'Q1. How do I buy a ConFi blind box?',
        'A. You can buy a ConFi in the online official Taobao store, or in the ConFi Module of the Conflux Forum (https://forum.conflux.fun) or buy in WeChat groups out the counter.',
        'Q2. How much is a ConFi?',
        'A. Each ConFi blind box costs ￥69. The price of all editions is the same.',
        'Q3. What is crypto ConFi?',
        'A. Crypto ConFi is NFT (Non-Fungible Token) issued on top of Conflux chain based on the off-line distribution number of ConFi, pegged 1:1.  Each on-chain ConFi NFT corresponds to the edition of an off-line ConFi in a blind box, and the NFT appearance of each edition is unique.',
        'Q4. How do I receive crypto ConFi through transfer?',
        'A. Copy your address on the top left of your account, and the seller can transfer crypto ConFi to your address.',
        'Q5. How do I sell crypto ConFi?',
        'A. Select the crypto ConFi that you want to sell, click the Transfer button, input the receiving address and confirm, then the ConFi is transferred.',
        'Q6. How many crypto Confi do I need to do the synthesis game?',
        'A. You need at least one of each edition, and when you collect 7+1 ConFi (7 limited and 1 year-of-rat), you can do synthesis.',
        'Q7. Can I re-synthetize ConFi?',
        'A. No. One crypto ConFi can only be synthesized once in an account. Re-synthesis the same ConFi in one account or multiple accounts is not supported. The synthesized ConFi has the value of the collection and can be traded among accounts. And in the following crypto ConFi ecosystem, players can participate in the upcoming online ConFi games.',
        'Q8. How can I draw a ConFi of the year-of-rat edition?',
        'A. The year-of-rat edition is sold in the same way of limited editions; you can buy all of them in the online official Taobao store. But it is not 100% that you can get the year-of-rat edition since all editions are distributed randomly. It is a probability matter.',
        'Q9. Why is the year-of-rat edition a special one?',
        'A. Because the year-of-rat edition represents 2020, the year of gold rat in China, and also the year when Conflux public chain is launched. It has great significance. A total of 1,000 year-of-rat editions ConFi were released only in the gold rat year, which gives it a great value of the collection.',
        'Q10. Can I transfer crypto ConFi on mobile devices?',
        'A. Please contact us docking the needs of project parties.',
      ],
    },
  ],
}

const en = {
  ...header,
  ...home,
  ...mySpace,
  ...create,
  ...createCollectible,
  ...createSellOrBuy,
  ...createError,
  ...detailModal,
  ...detailPage,
  ...explore,
  ...timeList,
  ...common,
  ...nftCardModal,
  ...card,
  ...infoModal,
  ...footer,
  ...privary,
}
export default en
