import React from 'react'
import { useTranslation } from 'react-i18next'
import { privary as privaryEn } from '@/assets/locales/en-US'
import { privary as privaryCn } from '@/assets/locales/zh-CN'
import styles from './style.module.scss'

const GameRuls = () => {
  const { i18n } = useTranslation()
  const data = i18n.language === 'en' ? privaryEn : privaryCn
  return (
    <div className={styles.privacyPage}>
      <div className={styles.pageTitle}>{data.privaryTitle}</div>
      {data.privaryDescs.map((item, index) => (
        <div className={styles.contentItem} key={index}>
          {item.title && <span className={styles.itemTitle}>{item.title}</span>}
          <div className={styles.itemSpanList}>
            {item.children.map((it, ind) => (
              <span className={styles.spanContent} key={ind}>
                {it}
              </span>
            ))}
          </div>
        </div>
      ))}
      <div className={styles.tips}>{data.privaryTips}</div>
      <div className={styles.team}>{data.privaryTeam}</div>
    </div>
  )
}

export default GameRuls
