import React, { useState } from 'react'
import { getLocalStorage } from '@/utils/localStorage'
import { useRequest } from 'ahooks'
import { useTranslation } from 'react-i18next'
import styles from './bidcard.module.scss'
import InfoModal from '@/components/InfoModal'
import { message, Tooltip } from 'antd'
import { format } from 'js-conflux-sdk'
import BigNumber from 'bignumber.js'
import {
  contractInterceptor,
  feePercentage,
  free_addr_test,
  getContractForEng,
  jsonToTuple,
  EnglishExchangeAddrTest,
} from '@/utils/contract'
import { getAvatar } from '@/utils/common'
import useGlobalStore from '@/stores/global'

interface IProps {
  obj: any
  unit: string
  isNotEnd: boolean
  detailInfo: { [key: string]: any }
  needRandom: () => void
}

const BidCard = ({ obj, unit, detailInfo, isNotEnd = true, needRandom }: IProps) => {
  // console.log(obj, 'obj')
  const { t } = useTranslation()
  const { userAddress } = useGlobalStore()
  const [showComfirm, setShowComfirm] = useState(false)
  const [, setActiveId] = useState('')
  const [showLodding, setShowLodding] = useState(false) // loadding显隐
  const [loddingType] = useState<any>('loading') // loadding类型
  const { run: runCancelBid } = useRequest<any>(API.trading.cancelBid.cancelBid, {
    manual: true,
  })
  // 日期处理
  const processingDate = (val: number) => {
    return val < 10 ? '0' + val : val
  }

  // 取消报价
  const cancelBid = async () => {
    const interc = await contractInterceptor()
    if (interc) {
      try {
        setShowLodding(true)
        const from = userAddress
        const orderSell = {
          owner: '0x0000000000000000000000000000000000000000', // 自己的钱包地址
          taker: format.hexAddress(from), // 购买者的地址，无
          operator: format.hexAddress(detailInfo?.listing?.operator), // 无
          orderType: 2, // OrderType {CREATE, SALE, OFFER, INVALID_TYPE}
          uid: obj?.id,
          startPrice: new BigNumber(obj.price).times(new BigNumber(1e18)).toFixed(), // 价格
          endTime: new Date(detailInfo?.listing?.expirationTime).getTime(), // 结束时间
          salt: obj?.salt, // 随机数，传时间戳
          assetAddress: format.hexAddress(detailInfo?.contractAddress), // 铸造时的地址
          id: detailInfo?.tokenId, // nft的id
          currencyAddress: format.hexAddress(detailInfo?.listing?.currencyAddress),
          feeRecipient: format.hexAddress(free_addr_test),
          feePercentage: feePercentage, // 手续费暂定为千分之一
        } // 获取签名bv
        await getContractForEng
          .cancelOrder(
            Buffer.from(detailInfo?.listing?.orderHash.slice(2), 'hex'),
            jsonToTuple(orderSell)
          )
          .sendTransaction({ from })
          .confirmed()
        setShowLodding(false)
        message.success(t('cancelOfferSuc'))
        window.location.reload()
      } catch (error) {
        setShowLodding(false)

        message.error(error.message)
      }
    }
  }

  return (
    <div className={styles.bidCard}>
      <InfoModal
        type="cancel"
        visible={showComfirm}
        onCancel={() => {
          setShowComfirm(false)
          setActiveId(obj?.id)
        }}
        onConfirm={() => {
          cancelBid()
        }}
      />
      <InfoModal type={loddingType} visible={showLodding} onCancel={() => setShowLodding(false)} />
      <div className={styles.left}>
        <img className={styles.avatar} src={getAvatar(obj?.bidder)} alt="avatar" />
        <div className={styles.info}>
          <p className={styles.price}>
            {obj?.price ? new BigNumber(obj?.price).toFormat() : '-'} {unit}
            {obj?.banlance < obj?.price && (
              <Tooltip placement="top" title={t('hoverTips')}>
                <span className={styles.tips}>ⓘ</span>
              </Tooltip>
            )}
          </p>
          <p className={styles.addressBox}>
            by <span className={styles.address}>{obj?.bidder}</span>
          </p>
        </div>
      </div>
      {obj?.bidder === getLocalStorage('account') && isNotEnd && (
        <div
          className={styles.btn}
          onClick={async () => {
            await setActiveId(obj.id)
            setShowComfirm(true)
          }}
        >
          cancel
        </div>
      )}
      <div className={styles.timeBox}>
        <p>{`${new Date(obj?.bidTime).getFullYear()}/${processingDate(
          new Date(obj?.bidTime).getMonth() + 1
        )}/${processingDate(new Date(obj?.bidTime).getDate())}`}</p>
        <p>{`${processingDate(new Date(obj?.bidTime).getHours())}:${processingDate(
          new Date(obj?.bidTime).getMinutes()
        )}:${processingDate(new Date(obj?.bidTime).getSeconds())}`}</p>
      </div>
    </div>
  )
}

export default BidCard
