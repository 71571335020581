import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { message } from 'antd'
import { removeLocalStorage, getLocalStorage } from '@/utils/localStorage'
import useGlobalStore from '@/stores/global'
import CONFIG from '@/config'

export default class Ajax {
  public static setHeader(headerName: string, value = '') {
    if (!headerName) {
      console.error('setHeader', '参数不合法')
      return
    }

    axios.interceptors.request.use(
      (config) => {
        config.headers[headerName] = value
        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
  }

  private baseUrl = CONFIG.baseUrl

  public request(params: AxiosRequestConfig): Promise<any> {
    const newParams: AxiosRequestConfig = {
      ...params,
      headers: {
        token: getLocalStorage('token'),
        walletAddress: getLocalStorage('account'),
      },
    }
    return new Promise((resolve, reject) => {
      axios({ ...newParams, url: `${this.baseUrl}${params.url}` })
        .then((res: AxiosResponse) => {
          if (res.status === 200) {
            if (res?.data?.code !== 0) {
              message.error(res?.data?.msg)
            }
            if (res?.data?.code === 401) {
              // token过期处理
              // 清空登陆信息
              removeLocalStorage('account')
              removeLocalStorage('token')
              useGlobalStore.setState({
                isLogin: false,
                userAddress: '',
                token: '',
              })
              message.warn('钱包已锁定，请重新登陆')
              reject(res?.data)
            } else {
              resolve(res.data)
            }
          }

          // if (res && res.status === 400) {
          //   console.log('前端传参异常了~')
          // } else if (res.data) {
          // if (res.data?.code === 200) {
          //   resolve(res.data)
          // } else {
          //   message.error(res.data.msg || '未知错误')
          //   reject(res.data)
          // }
          //   // if (res.data?.code === 0 || res.data?.ret === 0) {

          //   // } else if (res.data?.code === 10000) {
          //   //   // reject(res.data.msg)
          //   // } else {
          //   //   // reject(res.data.msg)
          //   // }
          // }
        })
        .catch((_err) => {
          const error = _err?.response
          message.error(error?.msg)
          // if (error?.status === 401) {
          //   message.error('鉴权失败，请重新登录')
          //   logoutFn()
          // }
          // if (error?.status === 400) {
          //   message.error('前端传参异常了~')
          // }
          // reject(err)
        })
    })
  }

  private queryString(url: string, query?: Record<string, string>): string {
    const str = []
    for (const key in query) {
      str.push(key + '=' + query[key])
    }
    const paramStr = str.join('&')
    return paramStr ? `${url}?${paramStr}` : url
  }

  public get(url = '', params?: Record<string, string>): Promise<any> {
    return this.request({
      method: 'get',
      url: this.queryString(`${url}`, params),
    })
  }

  public setBaseUrl = (url: string) => {
    this.baseUrl = url
  }

  public post(url: string, params?: Record<string, any>): Promise<any> {
    return this.request({
      method: 'post',
      url: `${this.baseUrl}${url}`,
      data: params,
    })
  }
}

// userAddress && Ajax.setHeader('walletAddress', userAddress)
// token && Ajax.setHeader('token', token)
