import React from 'react'
import moment from 'moment'
import BigNumber from 'bignumber.js'
import { getAvatar } from '@/utils/common'
import styles from './historycard.module.scss'

interface IProps {
  price: string
  unit: string
  from: string
  to: string
  time: string
  type: string
  [key: string]: string
}

const BidCard = ({ price, type, from, to, operationTime, unit }: IProps) => {
  const date = moment(operationTime).format('YYYY/MM/DD')
  const hour = moment(operationTime).format('HH:mm:ss')
  return (
    <div className={styles.historyCard}>
      <div className={styles.left}>
        <img
          className={styles.avatar}
          src={getAvatar(type === 'BOUGHT' ? to : from)}
          alt="avatar"
        />
        <div className={styles.info}>
          <p className={styles.type}>{type}</p>
          <p className={styles.addressBox}>
            by <span className={styles.address}>{type === 'BOUGHT' ? to : from}</span>
          </p>
        </div>
      </div>
      <p className={styles.price}>
        {price ? new BigNumber(price).toFormat() : '-'} {unit}
      </p>
      <div className={styles.timeBox}>
        <p>{date}</p>
        <p>{hour}</p>
      </div>
    </div>
  )
}

export default BidCard
