import React from 'react'
import cs from 'classnames'
import styles from './style.module.scss'

import { useTranslation } from 'react-i18next'

interface IProps {
  data?: any
  tab?: string | null
  onChange?: (v: string) => void
}

function Index({ tab, data = [], onChange }: IProps) {
  const { t } = useTranslation()
  const renderItem = (name: string, data: any) => {
    return (
      <div className={styles.categoryItem}>
        <div className={styles.title}>{name}</div>
        {data.map((item: any, index: number) => (
          <div key={index}>
            <div
              className={cs(styles.item, {
                [styles.itemAc]: tab === item?.id,
              })}
              onClick={() => {
                onChange && onChange(item?.id)
              }}
            >
              {item?.name.toUpperCase()}
            </div>
          </div>
        ))}
      </div>
    )
  }
  return <div className={styles.category}>{renderItem(t('collections'), data)}</div>
}

export default Index
