import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from './style.module.scss'
export interface CardProps {
  imgSrc: string
  text: string
  link?: string
}

function Index({ imgSrc = '', text, link }: CardProps) {
  const history = useHistory()

  const pathTo = () => {
    if (link) {
      window.open(link, '_blank')
    }
  }

  return (
    <div className={styles.container} onClick={pathTo}>
      <div className={styles.img}>
        <img src={imgSrc} alt="" />
      </div>
      <p className={styles.text}>{text}</p>
    </div>
  )
}

export default React.memo(Index)
