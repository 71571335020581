import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import CollectiblesCard from '@/components/CollectiblesCard'
import NftCard from '@/components/NftCard'
import { Spin } from 'antd'

import useRequest from '@ahooksjs/use-request'
import styles from './style.module.scss'
import { getQueryVariable } from '@/utils/common'
import useGlobalStore from '@/stores/global'
import { useTranslation } from 'react-i18next'
import useUrlState from '@ahooksjs/use-url-state'
import NoData from '@/components/NoData'

function Index() {
  const history = useHistory()
  const { t } = useTranslation()
  const [urlState] = useUrlState({ id: '' })
  const { userAddress, isLogin, token } = useGlobalStore()
  // 获取collectionId
  const collectionId = getQueryVariable(history, 'collectionId')
    ? getQueryVariable(history, 'collectionId')
    : ''

  // 获取未上架的作品集
  const { loading, data, run } = useRequest<any>(
    API.item.getNotOnSaleItemList.getNotOnSaleItemList,
    {
      manual: true,
    }
  )
  // 获取已上架的作品集
  const { data: putData, run: putRun } = useRequest<any>(
    API.item.getOnSaleItemList.getOnSaleItemList,
    {
      manual: true,
      formatResult: (res) => {
        const list = (res?.data?.itemList || []).map((item: any) => {
          return {
            ...item,
            imgSrc: item.image,
            text: item.name,
            highPrice: item.listing.price,
            type: item.image.substr(item.image.lastIndexOf('.') + 1) === 'mp4' ? 'video' : 'image',
          }
        })
        if (res?.data?.itemList) {
          res.data.itemList = list
        }
        return res
      },
    }
  )

  // 根据collectionID获取系列详情回填
  const { run: getCollection, data: collectionDetail } = useRequest(
    API.collection.getCollection.getCollection,
    {
      manual: true,
      formatResult: (res: any) => {
        const list = (res?.data?.categoryList || []).map((item: any) => {
          return {
            label: i18n.language === 'en' ? item.name_en : item.name,
            value: item.id,
          }
        })
        if (res?.data?.categoryList) {
          res.data.categoryList = list
        }
        return res
      },
    }
  )

  useEffect(() => {
    if (!isLogin) return
    run({
      bodyParams: {
        collectionId: collectionId,
        owner: userAddress,
      },
    })
    putRun({
      bodyParams: {
        collectionId: collectionId,
        owner: userAddress,
      },
    })
    getCollection({
      bodyParams: {
        id: urlState?.id,
      },
    })
  }, [token, isLogin])

  // 跳转到创建页面
  const pathToCreate = (record: any) => {
    history.push(
      record?.id
        ? `/create-collectible?itemID=${record?.id}&collectionId=${collectionId}`
        : `/create-collectible?collectionId=${collectionId}`
    )
  }

  // 跳转到上架页面
  const pathToPull = (record: any) => {
    history.push(`/sell-or-buy?itemID=${record?.id}&collectionId=${collectionId}`)
  }

  // 上架的作品集
  const renderItem = () => {
    if (Array.isArray(putData?.data?.itemList) && putData?.data?.itemList.length === 0) {
      return (
        <div className={styles.noData}>
          <NoData />
        </div>
      )
    } else {
      return (putData?.data?.itemList || []).map((item: any) => {
        return (
          <NftCard
            {...item}
            key={item?.id}
            imgSrc={item?.image}
            owner={item?.owner}
            text={item?.name}
            onClick={() => {
              history.push(`/detail?id=${item.id}&listingStatus=${item.listingStatus}`)
            }}
          />
        )
      })
    }
  }

  // 未上架的作品集
  const renderNotOnSellItem = () => {
    if (Array.isArray(data) && data?.data?.itemList.length === 0) {
      return (
        <div className={styles.noData}>
          <NoData />
        </div>
      )
    } else {
      return ((data && data?.data?.itemList) || []).map((item: any, index: number) => {
        return (
          <NftCard
            {...item}
            key={item?.id}
            imgSrc={item?.image}
            owner={item?.owner}
            text={item?.name}
            cardType="push"
            onClick={() => {
              history.push(`/sell-or-buy?itemID=${item?.id}&collectionId=${item.collectionId}`)
            }}
          />
        )
      })
    }
  }

  return (
    <Spin spinning={loading}>
      <div className={styles.container}>
        <div className="page-desc">— {t('collectionInfo')}</div>
        <div className="page-title">{collectionDetail?.data?.collection?.name}</div>
        <div className={styles.descriptionCon}>
          {collectionDetail?.data?.collection?.description}
        </div>
        <p className={styles.divider}></p>
        <div className={styles.content}>
          <p
            onClick={() => {
              history.goBack()
            }}
            className={styles.nftBack}
          >
            {t('back')}
          </p>
          <div className={styles.cards}>{renderItem()}</div>
        </div>
        <p className={styles.divider}></p>
        <div className={styles.notPutcards}>
          <NftCard
            type="image"
            cardType="create"
            onClick={() => {
              history.push(`/create-collectible`)
            }}
          />
          {renderNotOnSellItem()}
        </div>
      </div>
    </Spin>
  )
}

export default Index
