import React, { useEffect } from 'react'

import styles from './style.module.scss'
import useRequest from '@ahooksjs/use-request'
import useGlobalStore from '@/stores/global'
import NoData from '@/components/NoData'
import NftCard from '@/components/NftCard'
import { useHistory } from 'react-router-dom'

function Index() {
  const history = useHistory()
  const { userAddress, isLogin, token } = useGlobalStore()
  const { data, run } = useRequest<any>(API.trading.getMyBids.getMyBids, {
    manual: true,
    formatResult: (res: any) => {
      const list = (res?.data?.bidList || []).map((item: any) => {
        return {
          ...item,
          imgSrc: item.image,
          text: `# ${item.name} # ${item.tokenId}`,
          highPrice: item.maxBid.price,
          time: item.listing.expirationTime,
          yourPrice: item.yourBid.price,
        }
      })
      if (res?.data?.bidList) {
        res.data.bidList = list
      }
      return res
    },
  })

  useEffect(() => {
    if (!isLogin) return
    run({
      bodyParams: {
        bidder: userAddress,
        count: 0,
        pageSize: 1000,
      },
    })
  }, [token, isLogin])

  const renderItem = () => {
    if ((data?.data?.bidList || []).length === 0) {
      return (
        <div className={styles.noData}>
          <NoData />
        </div>
      )
    }
    return (data?.data?.bidList || []).map((item: any, index: number) => {
      return (
        <NftCard
          key={index}
          {...item}
          onClick={() => {
            history.push(`/detail?id=${item.id}&listingStatus=${item.listingStatus}`)
          }}
        />
      )
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.cards}>{renderItem()}</div>
    </div>
  )
}

export default Index
