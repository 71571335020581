import React, { useEffect, useState } from 'react'
import SelectList from '@/components/SelectList'
import Title from '@/components/Title'
import ExploreCard from '@/components/ExploreCard'

import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import { useRequest } from 'ahooks'

function Explore() {
  const { data, run } = useRequest<any>(API.item.browseItemList.browseItemList, {
    manual: true,
    formatResult: (res) => {
      const itemList = res?.data?.itemList
      const itemListMap = (itemList || []).map((item: any) => {
        return {
          ...item,
          imgSrc: item.image,
          text: item.name,
          highPrice: item?.maxBid?.price,
        }
      })
      res.data.itemList = itemListMap
      return res
    },
  })

  const { data: dataCategory, run: runCategory } = useRequest<any>(
    API.category.getCategoryList.getCategoryList,
    {
      manual: true,
      formatResult: (res) => {
        const categoryList = res?.data?.categoryList
        const categoryListMap = (categoryList || []).map((item: any) => {
          return {
            label: item.name,
            value: item.id,
            ...item,
          }
        })
        res.data.categoryList = categoryListMap
        return res
      },
    }
  )

  const { t, i18n } = useTranslation()

  const [tab, setTab] = useState('1')

  useEffect(() => {
    runCategory({
      bodyParams: {},
    })
  }, [])

  useEffect(() => {
    run({
      bodyParams: {
        category: tab,
        count: 0,
        pageSize: 8,
        sortBy: 'recentList',
        status: 'auction',
      },
    })
  }, [tab])

  const renderItem = () => {
    return (data?.data?.itemList || []).map((item: any, index: number) => {
      return <ExploreCard key={index} {...item} customStyle={styles.homeExplore} />
    })
  }

  const handleSelect = (v: any) => {
    setTab(v)
  }

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t('explore')}</p>
      <div className={styles.selectList}>
        <SelectList
          value={tab}
          onSelect={handleSelect}
          options={
            i18n.language === 'cn'
              ? dataCategory?.data?.categoryList
              : dataCategory?.data?.categoryList
          }
        />
      </div>

      <div className={styles.cardContainer}>{renderItem()}</div>
    </div>
  )
}

export default Explore
