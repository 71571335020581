import React, { useEffect, useState } from 'react'
import { useUnmount, useEffectOnce } from 'react-use'
import cs from 'classnames'
import styles from './style.module.scss'
import triUp from './tri-up.png'
import triDown from './tri-down.png'
import { useRequest } from 'ahooks'
import { useTranslation } from 'react-i18next'
import { getQueryVariable } from '@/utils/common'
import { useHistory } from 'react-router-dom'
import BaseSearch from '@/components/BaseSearch'
import useExploreStore from '@/stores/explore'

interface CategoryItem {
  id: any
  label: string
  value: any
}
interface Category {
  id: any
  label: string
  value: any
  valid?: string
  child?: CategoryItem[]
}

interface IProps {
  onChange?: (type: string, id: string) => void
}

function Index({ onChange }: IProps) {
  const { category } = useExploreStore.getState()

  const { t, i18n } = useTranslation()
  const history = useHistory()
  const [statusData, setStatusData] = useState([])
  const [curStatus, setStatus] = useState('1') // 当前status
  const [collectionList, setCollectionList] = useState([])
  const [curColl, setColl] = useState('') // 当前父分类value
  const [curChilColl, setChilColl] = useState(null) // 当前子分类value
  const [openCo, setOpenCo]: any = useState([null, null]) // 当前展开分类坐标
  const [value, setValue] = useState('')
  const urlItemId = getQueryVariable(history, 'id')

  // useEffect(() => {
  //   setStatus(defaultStatus)
  // }, [defaultStatus, defaultColl])

  const { run: runStatus, data: dataStatus }: any = useRequest(
    API.status.getStatusList.getStatusList,
    {
      manual: true,
      formatResult: (res: any) => {
        const list = (res?.data?.statusList || []).map((item: any) => {
          return {
            ...item,
            label: i18n.language === 'en' ? item.name_en : item.name,
            value: item.id,
          }
        })
        if (res?.data?.statusList) {
          res.data.statusList = list
        }
        return res
      },
    }
  )

  const { run: getBrowseCollectionList, data: dataGetBrowseCollectionList } = useRequest(
    API.collection.getBrowseCollectionList.getBrowseCollectionList,
    {
      manual: true,
      formatResult: (res: any) => {
        const list: Category[] = (res?.data?.collectionList || []).map((item: any) => {
          let itemChild: CategoryItem[] = []
          if (item?.categoryList && item?.categoryList.length) {
            itemChild = item?.categoryList.map((it: any) => {
              return {
                id: it.id,
                label: i18n.language === 'en' ? it.name_en : it.name,
                value: it.id,
              }
            })
          }
          return {
            id: item.id,
            label: item.name,
            value: item.id,
            valid: item.valid,
            child: itemChild,
          }
        })
        if (res?.data?.collectionList) {
          res.data.collectionList = [
            {
              id: '',
              label: i18n.language === 'en' ? 'ALL' : '全部',
              value: '',
            },
            ...list,
          ]
        }
        return res
      },
    }
  )

  useEffectOnce(() => {
    setStatus(category.curStatus || '1')
    setColl(category.curColl)
    setChilColl(category.curChilColl)
    setOpenCo(category.openCo)
  })

  useUnmount(() => {
    useExploreStore.setState({
      category: {
        curStatus,
        curColl,
        curChilColl,
        openCo,
      },
    })
  })

  useEffect(() => {
    runStatus({
      bodyParams: {
        count: 0,
        pageSize: 10,
      },
    })
    getBrowseCollectionList()
  }, [i18n.language])

  useEffect(() => {
    if (dataStatus && dataStatus.code === 0) {
      setStatusData(dataStatus?.data?.statusList)
    }
  }, [dataStatus])

  useEffect(() => {
    if (dataGetBrowseCollectionList && dataGetBrowseCollectionList.code === 0) {
      setCollectionList(dataGetBrowseCollectionList?.data?.collectionList)
      urlItemId && setColl(urlItemId)
    }
  }, [dataGetBrowseCollectionList])

  const valChange = (
    name: string,
    val: any,
    index: number | null,
    childInd: number | null,
    chilVal?: any
  ) => {
    if (name.toUpperCase() === 'STATUS') {
      setStatus(val)
    } else {
      setColl(val)
      setChilColl(chilVal || null)
      setOpenCo([index, childInd])
    }
    onChange && onChange(name, chilVal ? chilVal : val)
  }

  const handleChange = (e: any) => {
    console.log(e.target.value)
    setValue(e.target.value)
  }

  const handleSearch = () => {
    console.log(1)
  }

  const renderItem = (name: string, data: Category[], withSearch = false) => {
    return (
      <div className={styles.categoryItem}>
        <div className={styles.title}>{t(name)}</div>
        {/* {withSearch && <BaseSearch value={value} onChange={handleChange} onSearch={handleSearch} />} */}
        {data.map((item, index) => (
          <div key={item.value}>
            <div
              className={cs(styles.item, {
                [styles.itemAc]:
                  name.toUpperCase() === 'STATUS'
                    ? item.value === curStatus
                    : item.value === curColl && openCo[1] === null,
              })}
              onClick={() => valChange(name, item.value, index, null, null)}
            >
              {item?.label?.toUpperCase()}
              {item.child && item.child.length ? (
                /* eslint-disable */
                <img className={styles.triIc} src={openCo[0] === index ? triUp : triDown} />
              ) : null}
            </div>
            {item.child && item.child.length && openCo[0] === index
              ? item.child.map((it, ind) => (
                  <div
                    key={it.value}
                    className={cs(styles.item, styles.chileItem, {
                      [styles.itemAc]: it.value === curChilColl,
                    })}
                    onClick={() => valChange(name, item.value, index, ind, it.value)}
                  >
                    {it?.label?.toUpperCase()}
                  </div>
                ))
              : null}
          </div>
        ))}
      </div>
    )
  }
  return (
    <div className={styles.category}>
      {renderItem('status', statusData)}
      {renderItem(
        'collections',
        collectionList.filter((it: any) => it?.valid !== '0'),
        true
      )}
    </div>
  )
}

export default Index
