import React, { useEffect, useState } from 'react'
import { useUnmount, useEffectOnce } from 'react-use'
import SelectList from '@/components/SelectList'
import BaseSelect from '@/components/BaseSelect'
import List from './components/List'
import Category from './components/Category'
import styles from './style.module.scss'
import { useRequest } from 'ahooks'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getQueryVariable } from '@/utils/common'
import useExploreStore from '@/stores/explore'

function Explore() {
  const { t, i18n } = useTranslation()
  const filterList = [
    {
      label: t('recentList'),
      value: 'recentList',
    },
    {
      label: t('endingSoon'),
      value: 'endingSoon',
    },
    {
      label: t('priceHighToLow'),
      value: 'priceHighToLow',
    },
    {
      label: t('priceLowToHigh'),
      value: 'priceLowToHigh',
    },
    {
      label: t('highestBids'),
      value: 'highestBid',
    },
  ]
  const { curType, curSortByValue, curStatusFilter, curCollectionsFilter } = useExploreStore()
  const [typeData, setTypeData] = useState<any>([])
  const [typeDataActive, setTypeDataActive] = useState('') // 选中标签
  const [type, setType] = useState('')
  const [totalItem, setTotalItem] = useState(0)
  const [statusFilter, setStatusFilter] = useState('buyNow')
  const [collectionsFilter, setCollectionsFilter] = useState('')
  const [sortByValue, setSortByValue] = useState('recentList')
  const history = useHistory()
  const urlItemId = getQueryVariable(history, 'id')

  useEffectOnce(() => {
    setType(curType)
    setStatusFilter(curStatusFilter)
    setCollectionsFilter(curCollectionsFilter)
    setSortByValue(curSortByValue)
  })

  useUnmount(() => {
    // 页面销毁保存状态
    useExploreStore.setState({
      curType: type,
      curSortByValue: sortByValue,
      curStatusFilter: statusFilter,
      curCollectionsFilter: collectionsFilter,
    })
  })

  const filterChange = (val: any) => {
    setSortByValue(val)
  }

  const { run: getCategoryList, data: dataCategoryList } = useRequest<any>(
    API.category.getCategoryListWithoutALL.getCategoryListWithoutALL,
    {
      manual: true,
      formatResult: (res) => {
        const list = (res?.data?.categoryList || []).map((item: any) => {
          return {
            ...item,
            label: i18n.language === 'en' ? item.name_en : item.name,
            value: item.name_en,
          }
        })
        if (res?.data?.categoryList) {
          res.data.categoryList = [
            {
              label: i18n.language === 'en' ? 'ALL' : '全部',
              value: '',
            },
            ...list,
          ]
        }
        return res
      },
    }
  )

  useEffect(() => {
    getCategoryList({
      bodyParams: {
        count: 0,
        pageSize: 10,
      },
    })
  }, [i18n.language])

  useEffect(() => {
    if (dataCategoryList && dataCategoryList?.code === 0) {
      setTypeData(dataCategoryList?.data?.categoryList)
      setTypeDataActive(dataCategoryList?.data?.categoryList[0].value)
      urlItemId && setCollectionsFilter(urlItemId)
    }
  }, [dataCategoryList])

  const categoryChange = (name: string, id: string) => {
    name === 'status' && setStatusFilter(id === '1' ? 'buyNow' : 'auction') // 后端让这样传
    name === 'collections' && setCollectionsFilter(id)
  }
  return (
    <div className={styles.explorePage}>
      <div className="page-desc">— {t('variousDigitalItems')}</div>
      <div className="page-title">{t('explore')}</div>
      <div className={styles.content}>
        <Category onChange={(name, id) => categoryChange(name, id)} />
        <div className={styles.con}>
          <div className={styles.line1}>
            <SelectList
              options={typeData}
              value={typeDataActive}
              onSelect={(val) =>
                setType(
                  typeData.filter((item: any) => {
                    return item.value === val
                  })[0]?.id
                )
              }
            />
          </div>
          <div className={styles.line2}>
            <span className={styles.sum}>
              {totalItem} {t('items')}
            </span>
            <BaseSelect value={sortByValue} options={filterList} onChange={filterChange} />
          </div>

          <div className={styles.list}>
            <List
              statusFilter={statusFilter}
              collectionsFilter={collectionsFilter}
              type={type}
              sortByValue={sortByValue}
              totalChange={(val: any) => {
                setTotalItem(val)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Explore
