/**
     * @desc 报价
通过合约对NFT报价后，将报价信息保存到后端
id：通过/system/getId获得，用于监听合约，数据唯一标识
price：报价价格
bidder：报价人钱包地址
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.BidCreate
}

const ajax = new Ajax()

export function bid(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/trading/bid',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
