import React from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

interface IProps {
  visible: boolean
  toggleVisible: (val: boolean) => void
}

const SafeModal: React.FC<IProps> = ({ visible, toggleVisible }) => {
  const { i18n } = useTranslation()
  const words =
    i18n.language === 'en'
      ? {
          safeTitle: 'Risk Warning and Disclaimer',
          tips: 'Please read it carefully to fully understand all the terms contained in this Risk Warning and Disclaimer before using the service provided by this platform. Your acceptance of the service means that you confirm the following terms.',
          descs: [
            '1. When using the service, if the user or the counterparty fail to obey this Agreement or the relevant instructions on the website, or the operation instructions or rules, the Tspace Platform shall not undertake any damage caused thereby.',
            '2. You acknowledge and agree that Tspace discourages, prohibits and restricts any transaction or exchange that occurs through Tspace Platform, all transactions via Tspace Platform or a third party’s DApp are deemed to be the user’s individual behavior, and thus the binding contractual relationship is established only between the user and his counterparty, which is independent with the Tspace Platform and Tspace. No risks, liabilities, losses or expenses arising from the above trading activities shall be borne by the Tspace Platform and Tspace.',
            '3. We suggest that you shall use the Tspace Platform in a safe network environment to avoid potential security risks.',
            '4. Please be careful about frauds from third parties when using services provided by the Tspace Platform. Once such fraud occurred, we encourage you to inform us immediately.',
            '5. No liability shall be borne by the platform for the disclosure of personal profile and client information resulting from the fact that you inform others of your password, or share a registered account with others.',
            '6. Where permitted by law, such as the requirements by the judiciary, regulatory agencies and other relevant agencies, we may provide them with your relevant information.',
            '7. All actions performed under your Honeycomb Planet username are considered to be your actions, and the legal consequences shall be bore by you.',
          ],
        }
      : {
          safeTitle: '风险提示及免责声明',
          tips: '请您在使用本平台（页面）前，务必仔细阅读以下风险提示、免责声明的全部内容，在您全面理解并完全同意后方可继续使用本平台。',
          descs: [
            '1. 在使用Tspace平台服务时，若您或您的相对方未遵从本协议或相关网站说明中之操作提示、规则且Tspace平台不承担损害赔偿责任。',
            '2. Tspace阻止、禁止和限制通过Tspace平台进行的任何交易，所有您在Tspace平台进行的交易行为系您的个人行为，有约束力的合同关系在您和您的相对方之间建立，与Tspace平台及Tspace无关。Tspace平台及Tspace对因您的交易行为所引起的一切风险、责任、损失、费用不承担任何责任。',
            '3. 我们建议您在安全的网络环境中使用Tspace平台，以避免可能存在的安全隐患。',
            '4. 请您在使用我们的服务过程中，警惕非Tspace平台官方的诈骗行为。一旦发现此类行为，我们鼓励您第一时间告知我们。',
            '5. 由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人资料和客户信息的泄露，本站不承担任何法律责任。',
            '6. 在法律允许的情况下，如司法机关、监管机构及其他相关机构提出要求，Tspace有可能提供您的相关信息。',
            '7. 凡以您的用户名登录实施的一切行为均视为您的行为，所产生的法律后果由您承担。',
          ],
        }

  return (
    <Modal
      className={styles.safeModal}
      footer={null}
      centered
      maskStyle={reactStyle.maskStyle}
      visible={visible}
      onCancel={toggleVisible}
    >
      <div className={styles.content}>
        <p className={styles.title}>{words.safeTitle}</p>
        <p className={styles.tips}>{words.tips}</p>
        {words.descs.map((it, index) => (
          <p className={styles.con} key={`con${index}`}>
            {it}
          </p>
        ))}
      </div>
    </Modal>
  )
}

const reactStyle = {
  maskStyle: {
    background: '#000',
    opacity: 0.4,
    animation: 'none',
  },
}

export default SafeModal
