import React from 'react'
import { Button, Modal, ModalProps } from 'antd'
import cs from 'classnames'
import BaseInput, { BaseInputProps } from '@/components/BaseInput'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { Player, LoadingSpinner, ControlBar, PlayToggle } from 'video-react'

export interface IProps extends ModalProps {
  imgSrc: string
  text: string
  owner: string
  inputValue?: string
  onConfirm?: any
  inPutProps?: BaseInputProps
  onChange: (val: any) => void
  onCancel?: any
}
function Index({
  imgSrc,
  owner = '',
  text = '',
  inputValue = '',
  onConfirm,
  onCancel,
  onChange,
  inPutProps,
  ...modalProps
}: IProps) {
  const { t } = useTranslation()
  return (
    <Modal
      className={styles.editModal}
      //   centered
      destroyOnClose
      footer={false}
      onCancel={onCancel}
      title={<p className={styles.title}>{t('confirmTransfer')}</p>}
      {...modalProps}
    >
      <div className={styles.container}>
        <div className={styles.left}>
          <p className={styles.idInfo}>{t('artworkName')}</p>
          <p className={styles.idInfo}>{text}</p>
          <div className={styles.leftContent}>
            <div className={styles.leftImage}>
              {imgSrc.substr(imgSrc.lastIndexOf('.') + 1) === 'mp4' ? (
                <Player muted autoPlay className={styles.img} poster={imgSrc} src={imgSrc}>
                  <LoadingSpinner />
                  <ControlBar autoHide={false} disableDefaultControls={true}>
                    <PlayToggle />
                  </ControlBar>
                </Player>
              ) : (
                <img src={imgSrc} className={styles.img} alt="" />
              )}
            </div>
            <div className={styles.byContainer}>
              <span>by</span>
              <span className={cs('ellipsis', [styles.user])}>{owner}</span>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <p className={styles.transferLabel}>{t('transferTo')}</p>
          <BaseInput onChange={onChange}></BaseInput>
          <p className={styles.wallet}>{t('walletAddress')}</p>
          <p className={styles.desc}>{t('walletAddressDetail')}</p>

          <div className={styles.buttons}>
            <Button type="link" className={styles.neverMind} onClick={onCancel}>
              {t('nevermindButton')}
            </Button>
            <Button
              disabled={inputValue?.length === 0}
              className={cs({
                [styles.confirm]: inputValue?.length > 0,
                [styles.disableConfirm]: inputValue?.length === 0,
              })}
              onClick={onConfirm}
            >
              {t('nftCardModalConfirm')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default Index
