import React from 'react'
import styles from './style.module.scss'
import ImgUpLoad from '@/components/ImgUpLoad'
import cs from 'classnames'

export interface imgInfoProps {
  imgName?: string
  imgUrl?: string
  style?: string
  imgStyle?: string
  errorText?: string
  onChange: (res: string) => void
}

function Index({
  imgName = '',
  imgUrl = '',
  style = '',
  imgStyle = '',
  errorText = '',
  onChange,
}: imgInfoProps) {
  return (
    <section className={cs(styles.imgInfo, style)}>
      <section className={cs(styles.imgSection, imgStyle)}>
        <ImgUpLoad
          imgName={imgName}
          imgUrl={imgUrl}
          errorText={errorText}
          onChange={(res) => {
            onChange(res)
          }}
        />
      </section>
    </section>
  )
}

export default React.memo(Index)
