import create from 'zustand'
import { getLocalStorage } from '@/utils/localStorage'

type State = {
  searchKey: string // 顶部搜索框搜索内容
  isLogin: boolean // 是否登陆
  userAddress: string // 用户登陆钱包地址
  token: string
  isSellApproval: boolean //用户是否已经授权过，如果进行过一次授权，则不需要再次的授权
}

const useGlobalStore = create<State>((set) => ({
  searchKey: '',
  isLogin: Boolean(getLocalStorage('account')),
  userAddress: getLocalStorage('account') || '',
  token: getLocalStorage('token') || '',
  isSellApproval: false,
}))

export default useGlobalStore
