/**
     * @desc 查询作品集列表，包含自己创建的collection（必须有已铸造的NFT），和购买的NFT所属的collection
查询作品集列表，包含自己创建的collection（必须有已铸造的NFT），和购买的NFT所属的collection
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.NFTCollectionQuery
}

const ajax = new Ajax()

export function getCollectionListWithReference(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/collection/getCollectionListWithReference',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
