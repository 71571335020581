import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './style.module.scss'
import BaseInput from '@/components/BaseInput'
import BaseTypes from '@/components/BaseTypes'
import BaseSelect from '@/components/BaseSelect'
import PayModal from '@/components/PayModal'
import CreateHeader from '../components/Header'
import ImgInfo from '../components/ImgInfo'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import { getQueryVariable } from '@/utils/common'
import cs from 'classnames'

import { contractInterceptor, getContract } from '@/utils/contract'
import { format } from 'js-conflux-sdk'
import { message } from 'antd'
import InfoModal from '@/components/InfoModal'
import getLocalStorage from '@/stores/global'
import CheckList from './CheckList'
import { collectableSelect } from '@/assets/images'
import BaseTextArea from '@/components/BaseTextArea'

function CreateCollectible() {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const { userAddress } = getLocalStorage()
  const [itemName, setItemName] = useState('')
  const [description, setDescription] = useState('')
  const [categorySelectValue, setCategorySelectValue] = useState('')
  const [property, setProperty] = useState([{ type: '', name: '' }])
  const [imgUrl, setImgUrl] = useState('')
  const [showMoadl, setShowMoadl] = useState(false)
  const [unitSelectValue, setUnitSelectValue] = useState('CFX')
  const [nameNullCheck, setNameNullCheck] = useState('')
  const [descriptionNullCheck, setDescriptionNullCheck] = useState('')
  const [imgNullCheck, setImgNullCheck] = useState('')
  const [banlance, setBanlance] = useState(0)
  const [selectOptions, setSelectOptions] = useState([
    { label: 'Art', value: 'Art' },
    { label: 'Games', value: 'Games' },
    { label: 'Collectibles', value: 'Collectibles' },
  ])
  const [checked, setChecked] = useState(false)
  const [addItemId, setAddItemId] = useState('')
  const [showLodding, setShowLodding] = useState(false) // loadding显隐
  const [loddingType, setLoddingType] = useState('loading') // loadding类型

  // 获取url参数
  const urlPair = getQueryVariable(history, 'collectionId')
  const urlItemId = getQueryVariable(history, 'itemID')

  const { run: runCategory, data: dataCategory } = useRequest(
    API.category.getCategoryList.getCategoryList,
    {
      manual: true,
      formatResult: (res: any) => {
        const list = (res?.data?.categoryList || []).map((item: any) => {
          return {
            ...item,
            label: i18n.language === 'en' ? item.name_en : item.name,
            value: item.name_en,
          }
        })
        if (res?.data?.categoryList) {
          res.data.categoryList = list.filter((item) => item.id !== '1')
        }
        return res
      },
    }
  )

  const { run: runAddItem, data: dataAddItem }: any = useRequest(API.item.addItem.addItem, {
    manual: true,
  })

  const { run: getItem, data: dataGetItem }: any = useRequest(API.item.getItem.getItem, {
    manual: true,
  })

  useEffect(() => {
    runCategory({
      bodyParams: {
        count: 0,
        pageSize: 10,
      },
    })
    searchCfxBalance()
    urlItemId &&
      getItem({
        bodyParams: {
          id: urlItemId,
        },
      })
  }, [])

  useEffect(() => {
    setNameNullCheck('')
  }, [imgUrl])

  useEffect(() => {
    setNameNullCheck('')
  }, [itemName])

  useEffect(() => {
    setDescriptionNullCheck('')
  }, [description])

  useEffect(() => {
    if (dataCategory) {
      if (dataCategory.code === 0) setSelectOptions(dataCategory?.data?.categoryList)
    }
  }, [dataCategory])

  useEffect(() => {
    if (dataAddItem?.code === 0) {
      setShowMoadl(true)
      setAddItemId(dataAddItem?.data?.itemId)
    }
  }, [dataAddItem])

  // 如果是edit状态下，回填内容
  useEffect(() => {
    if (dataGetItem && dataGetItem?.code === 0) {
      setImgUrl(dataGetItem.data.item.image)
      setItemName(dataGetItem.data.item.name)
      setDescription(dataGetItem.data.item.description)
      setProperty(dataGetItem.data.item.properties)
    }
  }, [dataGetItem])

  const unitOptions = [
    // { label: "USDT", value: "USDT" },
    { label: 'CFX', value: 'CFX' },
    // { label: "FC", value: "FC" },
  ]

  // 类型卡牌列表
  const cards = [
    {
      title: 'CIP1155',
      value: t('CIP115'),
    },
  ]

  // 类型区域
  const Types = () => {
    return (
      <section className={styles.types}>
        <div className={styles.cards}>
          {cards.map((item, index) => {
            return (
              <div key={index} className={styles.card}>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.value}>{item.value}</div>
              </div>
            )
          })}
        </div>
      </section>
    )
  }

  // 弹窗内容区域
  const ModalContent = useMemo(() => {
    return (
      <section className={styles.modalContent}>
        {/* 价格版块 */}
        <div className={styles.priceDiv}>
          {/* 价格标题 */}
          <div className={styles.priceTitle}>{t('createCollectiblePriceTitle')}</div>
          {/* 价格 */}
          <div className={styles.priceSection}>
            {/* 价格 */}
            <div className={styles.priceNum}>5</div>
            {/* 单位 */}
            <div className={styles.priceUint}>{unitSelectValue}</div>
          </div>
          {/* 单位下拉列表 */}
          <div className={styles.priceSelect}>
            <BaseSelect
              value={unitSelectValue}
              options={unitOptions}
              onChange={(value) => {
                setUnitSelectValue(value)
              }}
              style={styles.selectStyle}
            />
          </div>
        </div>
        {/* 余额版块 */}
        <div className={styles.balance}>
          <div className={styles.balanceTitle}>{t('createCollectiblePriceBalance')}</div>
          <div className={styles.balanceValue}>
            {banlance} {unitSelectValue}
          </div>
        </div>

        {/* 提示语句 */}
        <div className={styles.checkList}>
          <CheckList onCheck={(val) => setChecked(val)} />
        </div>
      </section>
    )
  }, [checked])

  // 弹窗提交事件
  const modalComfirm = () => {
    castingMet(addItemId)
  }

  // 非空验证
  const nonNullCheck = () => {
    let boo = true
    if (!imgUrl) {
      setImgNullCheck('errorImg')
      boo = false
    }
    if (!itemName) {
      setNameNullCheck('errorName')
      boo = false
    }
    if (!description) {
      setDescriptionNullCheck('errorDescription')
      boo = false
    }
    if (boo) handleData()
  }

  const handleData = () => {
    if (urlItemId) {
      setShowMoadl(true)
      setAddItemId(urlItemId)
    } else {
      runAddItem({
        bodyParams: {
          collectionId: urlPair || '',
          creator: userAddress,
          description: description,
          externalLink: '',
          image: imgUrl,
          name: itemName,
          properties: property.map((item) => {
            return { name: item.name, type: item.type }
          }),
        },
      })
    }
  }

  // 合约调用
  const castingMet = async (id: string) => {
    const isLogin = await contractInterceptor()
    if (isLogin) {
      const from = format.hexAddress(userAddress)
      setShowLodding(true)
      try {
        const res = await getContract
          .mint(id)
          .sendTransaction({ from, value: '5000000000000000000' })
          .executed()
        if (res) {
          setLoddingType('success')
          setShowLodding(false)
          message.success('铸造成功！')
          history.push(`/sell-or-buy?itemID=${addItemId}&collectionId=${urlPair}`)
        }
      } catch (error) {
        setShowLodding(false)
        message.error(error.message)
      }
    }
  }

  // 合约查询cfx余额
  const searchCfxBalance = async () => {
    const isLogin = await contractInterceptor()
    if (isLogin) {
      const { Drip } = require('js-conflux-sdk')
      setBanlance(Drip(await window.confluxJS.getBalance(userAddress)).toCFX())
    }
  }

  return (
    <section className={styles.CreateCollectibleSetion}>
      <InfoModal
        type={loddingType}
        visible={showLodding}
        onCancel={() => {
          setShowLodding(false)
        }}
      />
      <CreateHeader
        miniTitle={t('createCollectibleMiniTitle')}
        title={t('createCollectibleTitle')}
      />
      <p className={styles.divider} />
      <section className={styles.createContent}>
        <section className={styles.imgInfoSection}>
          <ImgInfo
            title={t('createImgInfoTitle')}
            describe={t('createImgInfoDescribe')}
            imgStyle={styles.imgStyle}
            imgUrl={imgUrl}
            errorText={imgNullCheck}
            onChange={(res) => {
              if (typeof res === 'object') {
                setImgUrl('')
                setImgNullCheck(i18n.language === 'en' ? res?.msg_en : res?.msg)
              } else {
                setImgNullCheck('')
                setImgUrl(res)
              }
            }}
          />
        </section>
        <section className={styles.collectionSection}>
          <Types />
          <img className={styles.collectableSelect} src={collectableSelect} alt="" />
        </section>
      </section>
      {/* <Infos /> */}
      <section className={styles.infos}>
        {/* Item Name */}
        <div className={styles.names}>
          <section className={styles.inputSection}>
            <BaseInput
              text={t('createCollectibleInputName')}
              isRequired
              value={itemName}
              placeHolder={t('createCollectibleInputNamePlace')}
              onChange={(value) => setItemName(value)}
              error={nameNullCheck}
            />
          </section>
          <section className={styles.category}>
            <div className={styles.text}>{t('createIndexCategory')}</div>
            <div className={styles.categorySelect}>
              <BaseSelect
                value={categorySelectValue}
                options={selectOptions}
                onChange={(value) => {
                  setCategorySelectValue(value)
                }}
                style={styles.selectStyle}
              />
            </div>
          </section>
        </div>
        {/* description */}
        <div className={styles.description}>
          <BaseTextArea
            text={t('createCollectibleInputDescription')}
            isRequired
            value={description}
            onChange={(value) => setDescription(value)}
            error={descriptionNullCheck}
          />
        </div>
        {/* Properties */}
        <div className={styles.property}>
          {property.map((item, index) => {
            return (
              <div key={index} className={styles.propertyItem}>
                <BaseTypes
                  text={t('createCollectibleInputProperties')}
                  placeHolder={{ type: 'type', name: 'name' }}
                  value={item}
                  onChange={(value: any, type: string) => {
                    const list = [...property]
                    type === 'type' ? (list[index].type = value) : (list[index].name = value)
                    setProperty(list)
                  }}
                />
              </div>
            )
          })}
        </div>
        {/* addButton */}
        {property.length < 20 && (
          <div
            className={styles.addButton}
            onClick={() => setProperty([...property, { type: '', name: '' }])}
          >
            {t('createCollectibleAddButton')}
          </div>
        )}
        <p className={styles.divider} />
        {/* Tips */}
        <div className={styles.tips}>{t('createCollectibleTips')}</div>

        <div className={cs(styles.submit)} onClick={nonNullCheck}>
          {t('createCollectibleSubmitButton')}
        </div>
      </section>
      {showMoadl && (
        <PayModal
          title={t('createCollectiblePayModalTitle')}
          subTitle={t('createCollectiblePayModalSubTitle')}
          imgUrl={imgUrl}
          imgName={itemName}
          cancelOnClick={() => setShowMoadl(false)}
          comfirmOnClick={() => modalComfirm()}
          comfirmButtonDisable={!checked}
        >
          {ModalContent}
        </PayModal>
      )}
    </section>
  )
}

export default CreateCollectible
