/**
 * @desc 查询是否需要刷新我得NFTs
 */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  queryParams: IQueryParams
}

const ajax = new Ajax()

export function selectNewDataFlag(
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/user/selectNewDataFlag',
    method: 'post',
    data: {},
    params: {}
  })
}
