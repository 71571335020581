import React, { useMemo, useState } from 'react'
import { Button, message, Modal, ModalProps } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import cs from 'classnames'
export interface IProps extends ModalProps {
  onConfirm?: () => void
  onSign?: () => Promise<any>
  onApproval?: (() => Promise<any>) | null
}
function DetailModal({ title, onSign, onApproval, ...modalProps }: IProps) {
  const { t } = useTranslation()
  const [current, setCurrent] = useState(1)
  const length = useMemo(() => {
    let length = 0

    if (onApproval) {
      length++
    }
    if (onSign) {
      length++
      if (length === 1) length = 2
    }

    return length
  }, [onSign, onApproval])
  const handleSign = async () => {
    if (current !== length - 1) {
      message.error('请先完成上一步操作')
      return
    }

    if (onSign) {
      try {
        const res = await onSign()
        if (res) {
          const nowCurrent = current + 1
          setCurrent(nowCurrent)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  const handleApproval = async () => {
    if (current !== length) {
      message.error('请先完成上一步操作')
      return
    }
    if (onApproval) {
      try {
        const res = await onApproval()
        if (res) {
          const nowCurrent = current + 1
          setCurrent(nowCurrent)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <Modal
      className={styles.infoModal}
      wrapClassName={styles.wrapContainer}
      centered
      width={896}
      footer={false}
      title={<span className={styles.title}>{t('title')}</span>}
      {...modalProps}
    >
      <div className={styles.box}>
        {onSign && (
          <div className={styles.item}>
            <div className={styles.itemIndex}>{length - 1}</div>
            <div className={styles.itemInfo}>
              <p className={styles.main}> {t('main2')}</p>
              <p className={styles.desc}>{t('desc2')}</p>
            </div>
            <Button
              className={cs({
                [styles.activeButton]: current === length - 1,
                [styles.nextButton]: current !== length - 1,
              })}
              onClick={handleSign}
            >
              {current > length - 1 ? <CheckOutlined style={{ fontSize: '22px' }} /> : t('button2')}
            </Button>
          </div>
        )}

        {onApproval && (
          <div className={styles.item}>
            <div className={styles.itemIndex}>{length}</div>
            <div className={styles.itemInfo}>
              <p className={styles.main}>{t('main1')}</p>
              <p className={styles.desc}>{t('desc1')}</p>
            </div>
            <Button
              className={cs({
                [styles.activeButton]: current === length,
                [styles.nextButton]: current !== length,
              })}
              onClick={handleApproval}
            >
              {t('button1')}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default DetailModal
