/**
 * @desc 更新作品集
 */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.NFTCollectionUpdate
}

const ajax = new Ajax()

export function updateCollection(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/collection/updateCollection',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
