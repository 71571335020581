/**
 * @desc 查询作品集
 */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.Base
}

const ajax = new Ajax()

export function getCollection(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/collection/getCollection',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
