import { clock } from '@/assets/images'
import React, { useEffect, useState } from 'react'
import { Player, LoadingSpinner, ControlBar, PlayToggle } from 'video-react'
import { Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import BigNumber from 'bignumber.js'

import styles from './style.module.scss'
import 'video-react/dist/video-react.css'
import { useTranslation } from 'react-i18next'
import cs from 'classnames'

type IType = 'video' | 'image'
export interface CardProps {
  customStyle?: string
  listing?: any
  imgSrc: string
  id?: string
  name?: string
  text?: string
  price?: string
  tokenId?: string
  show?: boolean
  highPrice?: string
  isBid?: boolean
  type?: IType | any
}

function Index({
  listing,
  customStyle,
  imgSrc = '',
  name = '',
  tokenId = '',
  id,
  text,
  price,
  highPrice,
  // isBid = true,
  type = imgSrc.substr(imgSrc.lastIndexOf('.') + 1) === 'mp4' ? 'video' : 'image',
}: CardProps) {
  const { t } = useTranslation()
  const history = useHistory()
  const [hasTime, setTime] = useState(true) // 是否有倒计时
  const [time, setTimeDetail]: any = useState({
    h: 0,
    m: 0,
    s: 0,
  })

  useEffect(() => {
    const timer = setInterval(() => {
      bidCountdown(listing?.expirationTime)
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [listing])

  // 报价倒计时
  const bidCountdown = (expirationTime: string) => {
    const endTime = new Date(expirationTime).getTime()
    const now = new Date().getTime()
    const leftTime = endTime - now

    if (leftTime <= 0) {
      setTime(false)
      return
    }
    const h = Math.floor(leftTime / 1000 / 60 / 60)
    const m = Math.floor((leftTime / 1000 / 60) % 60)
    const s = Math.floor((leftTime / 1000) % 60)
    setTimeDetail({
      h: h < 10 ? `0${h}` : `${h}`,
      m: m < 10 ? `0${m}` : `${m}`,
      s: s < 10 ? `0${s}` : `${s}`,
    })
  }

  const render = () => {
    if (type === 'image') {
      return (
        <>
          <div className={styles.imgContainer}>
            <img className={styles.img} src={imgSrc} alt="" />
          </div>
          <div
            className={cs({
              [styles.cover]: hasTime,
              [styles.coverNoTime]: !hasTime,
            })}
          >
            {listing?.expirationTime && listing.method === 'bid' && hasTime && (
              <p className={styles.clockText}>
                <img src={clock} alt="" className={styles.clock} />
                <span>{`${time.h}:${time.m}:${time.s}`}</span>
              </p>
            )}

            {text && (
              <p className={styles.name}>
                # {name} # {tokenId}
              </p>
            )}
            {price && (
              <p className={styles.price}>
                {Number(price).toFixed(2)} {listing?.tokenName}
              </p>
            )}
            {highPrice ? (
              <p className={styles.highPriceContainer}>
                {/* {isBid && (
                  <span className={styles.hightPriceLabel}>
                    {t(listing.method === 'instant' ? 'instantPrice' : 'highestBid')}{' '}
                  </span>
                )} */}
                <span className={styles.hightPriceLabel}>{t('highestBid')}</span>
                <span className={styles.hightPriceContent}>
                  <Tooltip title={new BigNumber(highPrice).toFormat()}>
                    <span className={styles.num}>{new BigNumber(highPrice).toFormat()}</span>
                  </Tooltip>
                  &nbsp;
                  {listing?.tokenName}
                </span>
              </p>
            ) : (
              <p className={styles.highPriceContainer}>
                {/* {isBid && (
                  <span className={styles.hightPriceLabel}>
                    {t(listing.method === 'instant' ? 'instantPrice' : 'highestBid')}{' '}
                  </span>
                )} */}
                <span className={styles.hightPriceLabel}>{t('highestBid')}</span>
                <span className={styles.hightPriceContent}>
                  <Tooltip title={new BigNumber(listing?.minimumBid).toFormat()}>
                    <span className={styles.num}>
                      {new BigNumber(listing?.minimumBid).toFormat()}
                    </span>
                  </Tooltip>
                  &nbsp;
                  {listing?.tokenName}
                </span>
              </p>
            )}
          </div>
        </>
      )
    }
    if (type === 'video') {
      return (
        <>
          <div className={styles.imgContainer}>
            <Player loop muted autoPlay className="my-player" poster={imgSrc} src={imgSrc}>
              <LoadingSpinner />
              <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
              </ControlBar>
            </Player>
          </div>
          <div
            className={cs({
              [styles.cover]: listing?.expirationTime,
              [styles.coverNoTime]: !listing?.expirationTime,
            })}
          >
            {listing?.expirationTime && listing.method === 'bid' && hasTime && (
              <p className={styles.clockText}>
                <img src={clock} alt="" className={styles.clock} />
                <span>{`${time.h}:${time.m}:${time.s}`}</span>
              </p>
            )}

            {text && (
              <p className={styles.name}>
                # {name} # {tokenId}
              </p>
            )}
            {price && (
              <p className={styles.price}>
                {Number(price).toFixed(2)} {listing?.tokenName}
              </p>
            )}
            {highPrice ? (
              <p className={styles.highPriceContainer}>
                {/* {isBid && (
                  <span className={styles.hightPriceLabel}>
                    {t(listing.method === 'instant' ? 'instantPrice' : 'highestBid')}{' '}
                  </span>
                )} */}
                <span className={styles.hightPriceLabel}>{t('price')}</span>
                <span className={styles.hightPriceContent}>
                  <Tooltip title={new BigNumber(highPrice).toFormat()}>
                    <span className={styles.num}>{new BigNumber(highPrice).toFormat()}</span>
                  </Tooltip>
                  &nbsp;
                  {listing?.tokenName}
                </span>
              </p>
            ) : (
              <p className={styles.highPriceContainer}>
                {/* {isBid && (
                  <span className={styles.hightPriceLabel}>
                    {t(listing.method === 'instant' ? 'instantPrice' : 'highestBid')}{' '}
                  </span>
                )} */}
                <span className={styles.hightPriceLabel}>{t('price')}</span>
                <span className={styles.hightPriceContent}>
                  <Tooltip title={new BigNumber(listing?.minimumBid).toFormat()}>
                    <span className={styles.num}>
                      {new BigNumber(listing?.minimumBid).toFormat()}
                    </span>
                  </Tooltip>
                  &nbsp;
                  {listing?.tokenName}
                </span>
              </p>
            )}
          </div>
        </>
      )
    }
  }

  return (
    <div
      className={cs([styles.container], customStyle)}
      onClick={() => {
        history.push(`/detail?id=${id}`)
      }}
    >
      {render()}
    </div>
  )
}

export default React.memo(Index)
