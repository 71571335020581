import * as category from './category'
import * as collection from './collection'
import * as file from './file'
import * as item from './item'
import * as nftContract from './nftContract'
import * as paymentTokens from './paymentTokens'
import * as status from './status'
import * as systemToken from './systemToken'
import * as trading from './trading'
import * as user from './user'

;(window as any).API = {
  category,
  collection,
  file,
  item,
  nftContract,
  paymentTokens,
  status,
  systemToken,
  trading,
  user
}
