import React from 'react'

import styles from './style.module.scss'

interface TitleProps {
  text: string
}

function Index({ text }: TitleProps) {
  return <div className={styles.title}>{text}</div>
}

export default React.memo(Index)
