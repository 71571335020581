import React from 'react'
import { Button, Input, message } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { logo, search, eng, mySpace, chn } from '@/assets/images'
import useGlobalStore from '@/stores/global'
import { isLikeBase32Address, replaceAddr, replaceStr, versionStringCompare } from '@/utils/common'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import { setLocalStorage } from '@/utils/localStorage'
import { encodeQuery } from '@/utils/common'

import styles from './style.module.scss'

function Header() {
  const history = useHistory()
  const { searchKey, userAddress, isLogin } = useGlobalStore()
  const { t, i18n } = useTranslation()

  const { run } = useRequest(API.systemToken.getToken.getToken, {
    manual: true,
  })

  const changeLanguage = () => {
    i18n.changeLanguage('en')
    localStorage.setItem('language', i18n.language)
  }

  // 链接钱包
  const connectPortal = async () => {
    if (typeof window.conflux === 'undefined') {
      // 用户未安装portal,跳转到下载引导页
      // message
      message.warning(t('noInstallWorning'))
      window.open('https://fluentwallet.com/')
      return
    }
    if (!isLogin) {
      if (!versionStringCompare(window?.confluxJS?.version)) {
        message.warning(t('walletVersion'))
        return
      }
      // 用户已经安装portal
      const accouts = await window?.conflux?.request({ method: 'cfx_requestAccounts' })
      if (accouts.length > 0) {
        const res: any = await run({
          bodyParams: {
            walletAddress: accouts[0],
          },
        })
        // 存到storage中
        setLocalStorage('account', accouts[0])
        res && setLocalStorage('token', res.token)
        useGlobalStore.setState({
          isLogin: true,
          userAddress: accouts[0],
          token: res?.token,
        })
      }
    }
  }

  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.left}>
          <img className={styles.icon} src={logo} alt="" onClick={() => history.push('/')} />
          <Input
            value={searchKey}
            className={styles.search}
            onKeyUp={(e: any) => {
              if (e.code === 'Enter') {
                history.push({
                  pathname: '/explore',
                  search: encodeQuery({
                    search: searchKey,
                  }),
                })
              }
            }}
            onChange={(e: any) =>
              useGlobalStore.setState({
                searchKey: e.target.value,
              })
            }
            suffix={
              <div
                onClick={() => {
                  history.push(`/explore?search=${searchKey}`)
                }}
              >
                <img src={search} alt="" />
              </div>
            }
          ></Input>
        </div>
        <div className={styles.right}>
          <Link to="/explore" className={styles.marketplace}>
            {t('marketplace')}
          </Link>
          {/* <Button
            className={styles.create}
            onClick={() => history.push('/create')}
            disabled={!isLogin}
          >
            {t('create')}
          </Button> */}
          {isLogin ? (
            <Button className={styles.stringCode}>
              <span className={styles.dot}></span>
              <span>
                {isLikeBase32Address(userAddress)
                  ? replaceAddr(userAddress)
                  : replaceStr(userAddress, '****')}
              </span>
            </Button>
          ) : (
            <Button className={styles.connectWallet} onClick={() => connectPortal()}>
              {t('connect')}
            </Button>
          )}
          {/* <img
            onClick={changeLanguage}
            src={i18n.language === 'en' ? chn : eng}
            alt=""
            height={32}
            className={styles.language}
          /> */}
          <Link to="/my-space?tab=COLLECTIBLES">
            <img className={styles.mySpace} src={mySpace} alt="" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Header
