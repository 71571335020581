import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './style.module.scss'
import BaseInput from '@/components/BaseInput'
import BaseSelect from '@/components/BaseSelect'
import PayModal from '@/components/PayModal'
import ExpiryTime from './components/ExpiryTime'
import CreateHeader from '../components/Header'
import cs from 'classnames'
import { useTranslation } from 'react-i18next'
import { getQueryVariable } from '@/utils/common'
import { useRequest } from 'ahooks'
import BigNumber from 'bignumber.js'
import format from 'js-conflux-sdk/src/util/format'
import useGlobalStore from '@/stores/global'
import Moment from 'moment'
// import moduleName from '@/components/ProgressModal'
import {
  free_addr_test,
  getContractForFiex,
  jsonToTuple,
  FixedExchangeAddress,
  EnglishExchangeAddrTest,
  getContractForEng,
  setApprovalForAll,
  contractInterceptor,
  orderSign,
  feePercentage,
  isApprovedForThis,
} from '@/utils/contract'
import { message } from 'antd'
import InfoModal from '@/components/InfoModal'
import ProgressModal from '@/components/ProgressModal'
import { collectableSelect } from '@/assets/images'

function SellOrBuy() {
  const { userAddress } = useGlobalStore()
  const [salePrice, setSalePrice] = useState('') // sale版块价格输入框值
  const [salePriceError, setSalePriceError] = useState('') // sale版块价格输入框值错误提示
  const [bidPrice, setBidPrice] = useState('') // bid版块最低出价输入框值
  const [bidPriceError, setBidPriceError] = useState('') // bid版块最低出价输入框值错误提示
  const [showMoadl, setShowMoadl] = useState(false) // modal弹窗显隐
  const [unitOptions, setUnitOptions] = useState([]) // 代币列表
  const [unitSelectValue, setUnitSelectValue] = useState('CUSDT') // sale版块单位下拉框值
  const [expirydate, setExpirydate] = useState('') // bid版块过期日期输入框值 // bid弹窗单位下拉框值
  const [expiryDateLast, setExpiryDateLast] = useState('')
  const [, setBidModalUnitSelectValue] = useState('WCFX')
  const [bidSelectValue, setBidSelectValue] = useState('WCFX') // bid版块最低出价及低价下拉框值
  const [dateSelectValue, setDateSelectValue] = useState('24') // bid版块过期日期下拉框值
  const [typeActive, setTypeActive] = useState(0) // 选择的版块值
  const [nftDetail, setNftDetail] = useState({}) // nft详情
  const [showLodding, setShowLodding] = useState(false) // loadding显隐
  const [loddingType, setLoddingType] = useState('loading') // loadding类型
  const [fee, setFee] = useState({}) // 手续费
  const [progressModalShow, setProgressModalShow] = useState(false)
  const [approval, setApproval] = useState(null)
  const [signData, setSignData] = useState(null)
  const [orderState, setOrder] = useState(null)
  const [isApproval, setIsApproval] = useState(false) //是否已经授权
  const history = useHistory()
  const { t } = useTranslation()

  // 获取itemid
  const itemId = getQueryVariable(history, 'itemID')
    ? getQueryVariable(history, 'itemID')
    : getQueryVariable(history, 'itemId')

  // 获取collectionId
  const collectionId = getQueryVariable(history, 'collectionId')

  // 获取内容详情
  const { run: getItem, data: dataGetItem } = useRequest(API.item.getItem.getItem, {
    manual: true,
  })

  // 查询上架手续费
  const { run: getFee, data: dataGetFee } = useRequest(API.trading.getFee.getFee, {
    manual: true,
  })

  // 获取后端需要的id
  const { run: getId, data: idData } = useRequest(API.systemToken.getId.getId, {
    manual: true,
  })

  // 上架
  const { run: postListing } = useRequest(API.trading.postListing.postListing, {
    manual: true,
  })

  // 查询代币列表
  const { run: getPaymentTokensList, data: dataGetPaymentTokensList } = useRequest(
    API.paymentTokens.getPaymentTokensList.getPaymentTokensList,
    {
      manual: true,
      formatResult: (res: any) => {
        const list = (res?.data?.paymentTokensList || []).map((item: any) => {
          return {
            ...item,
            label: item.name,
            value: item.id,
          }
        })
        if (res?.data?.paymentTokensList) {
          res.data.paymentTokensList = list
        }
        return res
      },
    }
  )

  useEffect(() => {
    getItem({
      bodyParams: {
        id: itemId,
      },
    })
    getFee()
    getPaymentTokensList({
      bodyParams: {
        count: 0,
        pageSize: 10,
      },
    })
  }, [])

  useEffect(() => {
    if (dataGetItem && dataGetItem.code === 0) setNftDetail(dataGetItem?.data?.item)
  }, [dataGetItem])

  useEffect(() => {
    if (dataGetFee && dataGetFee.code === 0) setFee(dataGetFee?.data?.fee)
  }, [dataGetFee])

  useEffect(() => {
    if (dataGetPaymentTokensList && dataGetPaymentTokensList.code === 0) {
      setUnitOptions(dataGetPaymentTokensList?.data?.paymentTokensList)
      setUnitSelectValue(dataGetPaymentTokensList?.data?.paymentTokensList[0].label)
      setBidSelectValue(dataGetPaymentTokensList?.data?.paymentTokensList[0].label)
      setBidModalUnitSelectValue(dataGetPaymentTokensList?.data?.paymentTokensList[0].label)
    }
  }, [dataGetPaymentTokensList])

  // 版块切换，输入框值清空
  useEffect(() => {
    setSalePrice('')
    setBidPrice('')
    setSalePriceError('')
    setBidPriceError('')
  }, [typeActive])

  useEffect(() => {
    setSalePriceError('')
  }, [salePrice])

  useEffect(() => {
    setBidPriceError('')
  }, [bidPrice])

  useEffect(() => {
    if (!userAddress) return
    if (!nftDetail) return
    isApprovedForThis(
      userAddress,
      typeActive === 0 ? FixedExchangeAddress : EnglishExchangeAddrTest,
      nftDetail?.contractAddress
    ).then((res) => {
      setIsApproval(res)
    })
    return () => {}
  }, [userAddress, typeActive, nftDetail])

  // 小时单位下拉框列表
  const dateOptions = [
    { label: '24H', value: '24' },
    { label: '48H', value: '48' },
    { label: '72H', value: '72' },
    { label: t('sellOrBuyOneWeek'), value: '168' },
    { label: t('sellOrByOneMonth'), value: '720' },
    { label: t('sellOrBuyCustomize'), value: null },
  ]

  // 明细列表
  const priceDetailedList = [
    {
      name: `${t('sellOrBuyFee')}  (${fee?.fee}${fee?.unit})`,
      value: new BigNumber(salePrice)
        .times(new BigNumber(new BigNumber(fee.fee).div(100)))
        .toNumber(),
      unit: unitSelectValue,
    },
    {
      name: `${t('sellOrBuyTotalReceive')}`,
      value: new BigNumber(salePrice)
        .minus(new BigNumber(salePrice).times(new BigNumber(new BigNumber(fee.fee).div(100))))
        .toNumber(),
      unit: unitSelectValue,
    },
  ]

  // 明细列表(bid)
  const priceDetailedListForReservePrice = [
    {
      name: `${t('sellOrBuyFee')}  (${fee.fee}${fee.unit})`,
      value: new BigNumber(bidPrice)
        .times(new BigNumber(new BigNumber(fee.fee).div(100)))
        .toNumber(),
      unit: bidSelectValue,
    },
    {
      name: `${t('sellOrBuyTotalReceive')}`,
      value: new BigNumber(bidPrice)
        .minus(new BigNumber(bidPrice).times(new BigNumber(new BigNumber(fee.fee).div(100))))
        .toNumber(),
      unit: bidSelectValue,
    },
  ]

  // 类型卡牌列表
  const cards = [
    {
      title: t('typesCard1Title'),
      value: t('typesCard1Value'),
    },
    {
      title: t('typesCard2Title'),
      value: t('typesCard2Value'),
    },
  ]

  // 获取最后一次时间
  const getLastTime = useCallback((value: string) => {
    return value
  }, [])

  const PassButton = () => {
    // return <div className={styles.passButton}>Skip for now</div>;
    return (
      <div
        className={styles.passButton}
        onClick={() => history.push(`/my-space?collectionId=${collectionId}&tab=COLLECTIBLES`)}
      >
        {t('createPass')}
      </div>
    )
  }

  // 类型区域
  const Types = () => {
    return (
      <section className={styles.types}>
        <div className={styles.typesTitleBox}>
          <div className={styles.typesTitle}>{t('createSellOrBuyTypesTitle')}</div>
          <PassButton />
        </div>

        <div className={styles.cards}>
          {cards.map((item, index) => {
            return (
              <div
                key={index}
                className={cs(styles.card, typeActive === index && styles.active)}
                onClick={() => setTypeActive(index)}
              >
                {typeActive === index && (
                  <img className={styles.collectableSelect} src={collectableSelect} alt="" />
                )}
                <div className={styles.title}>{item.title}</div>
                <div className={styles.value}>{item.value}</div>
              </div>
            )
          })}
        </div>
      </section>
    )
  }

  // sale弹窗内容区域
  const SaleModalContent = () => {
    return (
      <section className={styles.modalContent}>
        {/* 价格版块 */}
        <div className={styles.priceDiv}>
          {/* 价格标题 */}
          <div className={styles.priceTitle}>{t('createSellOrBuySaleModalTitle')}</div>
          {/* 价格 */}
          <div className={styles.priceSection}>
            {/* 价格 */}
            <div className={styles.priceNum}>{salePrice}</div>
            {/* 单位 */}
            <div className={styles.priceUint}>{unitSelectValue}</div>
          </div>
          {/* tips */}
          <div className={styles.priceTips}>{t('createSellOrBuySaleModalTips')}</div>
        </div>
        {/* 明细版块 */}
        <div className={styles.priceDetailed}>
          {priceDetailedList.map((item, index) => {
            return (
              <div className={styles.detailedItem} key={index}>
                <div className={styles.detailName}>{item.name}</div>
                <div className={styles.detailPrice}>
                  {item.value}
                  <div className={styles.detailUnit}>{item.unit}</div>
                </div>
              </div>
            )
          })}
        </div>
      </section>
    )
  }

  // bid弹窗内容区域
  const BidModalContent = useMemo(() => {
    return (
      <section className={styles.modalContent}>
        {/* 价格版块 */}
        <div className={styles.priceDiv}>
          {/* 价格标题 */}
          <div className={styles.priceTitle}>{t('createSellOrBuyBidModalTitle')}</div>
          {/* 价格 */}
          <div className={styles.priceSection}>
            {/* 价格 */}
            <div className={styles.priceNum}>{bidPrice}</div>
            {/* 单位 */}
            <div className={styles.priceUint}>{bidSelectValue}</div>
          </div>
          {/* tips */}
          <div className={styles.priceTips}>{t('createSellOrBuyBidModalTips1')}</div>
          <div className={cs(styles.priceTips, styles.miniTips)}>
            {t('createSellOrBuyBidModalTips2')}
            <div className={styles.bolderText}>{expiryDateLast}</div>
          </div>
        </div>
        {/* 明细版块 */}
        <div className={styles.priceDetailed}>
          {priceDetailedListForReservePrice.map((item, index) => {
            return (
              <div key={index} className={styles.detailedItem}>
                <div className={styles.detailName}>{item.name}</div>
                <div className={styles.detailPrice}>
                  {item.value}
                  <div className={styles.detailUnit}>{item.unit}</div>
                </div>
              </div>
            )
          })}
        </div>
      </section>
    )
  }, [bidPrice, bidSelectValue, priceDetailedListForReservePrice])

  const getOrder = async () => {
    const isLogin = await contractInterceptor()
    if (isLogin) {
      const timestamp = new Date().getTime()
      const idRes = await getId()
      const order =
        typeActive === 0
          ? {
              owner: format.hexAddress(userAddress), // 自己的钱包地址
              taker: format.hexAddress('0x0000000000000000000000000000000000000000'), // 购买者的地址，无
              operator: format.hexAddress('0x0000000000000000000000000000000000000000'), // 无
              orderType: '1', // OrderType {CREATE, SALE, OFFER, INVALID_TYPE}
              uid: idRes?.data?.id,
              startPrice: new BigNumber(salePrice).times(new BigNumber(1e18)).toFixed(), // 价格
              salt: timestamp, // 随机数，传时间戳
              assetAddress: format.hexAddress(nftDetail?.contractAddress), // 铸造时的地址
              id: nftDetail?.tokenId, // nft的id
              currencyAddress: format.hexAddress(
                unitOptions.filter((ite) => ite.label === unitSelectValue)[0].contractAddress
              ),
              feeRecipient: format.hexAddress(free_addr_test),
              feePercentage: feePercentage, // 手续费暂定为千分之一
            }
          : {
              owner: format.hexAddress(userAddress), // 自己的钱包地址
              taker: '0x0000000000000000000000000000000000000000', // 购买者的地址，无
              operator: '0x0000000000000000000000000000000000000000', // 无
              orderType: '0', // OrderType {CREATE, SALE, OFFER, INVALID_TYPE}
              uid: idRes?.data?.id,
              startPrice: new BigNumber(bidPrice).times(new BigNumber(1e18)).toFixed(), // 价格
              endTime: new Date(expiryDateLast).getTime(), // 结束时间
              salt: timestamp, // 随机数，传时间戳
              assetAddress: format.hexAddress(nftDetail?.contractAddress), // 铸造时的地址
              id: nftDetail?.tokenId, // nft的id
              currencyAddress: format.hexAddress(
                unitOptions.filter((ite) => ite.name === bidSelectValue)[0].contractAddress
              ),
              feeRecipient: format.hexAddress(free_addr_test),
              feePercentage: feePercentage, // 手续费暂定为千分之一
            }

      return order
    }
  }

  // 获取签名
  const signConfim = async () => {
    const order: any = await getOrder()
    setOrder(order)
    const typeData = orderSign(order, typeActive === 0 ? 'FixedExchange' : 'EnglishExchange')
    window.confluxJS.provider.sendAsync(
      {
        method: 'cfx_signTypedData_v4',
        params: [window.conflux.selectedAddress, JSON.stringify(typeData)],
        from: window.conflux.selectedAddress,
      },
      async (err: any, res: any) => {
        if (err) {
          setShowLodding(false)

          message.error(err.message)
          return false
        } else {
          setSignData(res)
          return true
        }
      }
    )
  }

  // 授权后操作
  const handleFinish = async (order: any, signData: any, approval: any) => {
    if (!approval) return
    try {
      const from = userAddress
      setShowLodding(true)
      const par = {
        ...order,
        id: '',
        itemId: itemId,
        method: typeActive === 0 ? 'instant' : 'bid',
        price: salePrice,
        minimumBid: bidPrice,
        expirationTime: new Date(expiryDateLast).getTime(),
        tokenName: typeActive === 0 ? unitSelectValue : bidSelectValue,
        signData: signData.result,
        timeStamp: order.salt,
      }
      if (idData && idData.code === 0) {
        par.id = idData?.data?.id
        const postRes = await postListing({
          bodyParams: {
            ...par,
          },
        })
        if (postRes && postRes.code === 0) {
          if (typeActive === 0) {
            try {
              await getContractForFiex
                .createOrder(jsonToTuple(order), Buffer.from(signData?.result.slice(2), 'hex'))
                .sendTransaction({
                  from,
                })
                .executed()
            } catch (error) {
              message.error(error.message)

              setShowLodding(false)
            }
          } else {
            try {
              await getContractForEng
                .createOrder(jsonToTuple(order))
                .sendTransaction({
                  from,
                })
                .executed()
            } catch (error) {
              message.error(error.message)

              setShowLodding(false)
            }
            setLoddingType('success')
            setShowLodding(false)
            message.success(t('sellSuc'))
            history.push(`/my-space?collectionId=${collectionId}&tab=COLLECTIBLES`)
          }
          setLoddingType('success')
          setShowLodding(false)
          message.success(t('sellSuc'))
          history.push(`/my-space?collectionId=${collectionId}&tab=COLLECTIBLES`)
        } else {
          setShowLodding(false)
          message.error(postRes.msg)
        }
      }
    } catch (err) {
      setShowLodding(false)
    }
  }

  // 授权
  const sendApproval = (from: string, addr: string, address: string) => {
    try {
      return setApprovalForAll(from, addr, true, address)
    } catch (error) {
      message.error(error)

      setShowLodding(false)
    }
  }

  return (
    <section className={styles.SellOrBuy}>
      <InfoModal
        type={loddingType}
        visible={showLodding}
        onCancel={() => {
          setShowLodding(false)
        }}
      />
      <CreateHeader miniTitle={t('createSellOrBuyMiniTitle')} title={t('createSellOrBuyTitle')} />
      <section className={styles.createContent}>
        <section className={styles.collectionSection}>
          <Types />
          {typeActive === 0 ? (
            <section className={styles.infos}>
              <div className={styles.price}>
                <section className={styles.priceEdit}>
                  <section className={styles.inputSection}>
                    <BaseInput
                      text={t('createSellOrBuySaleInputName')}
                      value={salePrice}
                      error={salePriceError}
                      placeHolder={t('createSellOrBuySaleInputNamePlace')}
                      onChange={(value) => setSalePrice(value)}
                    />
                  </section>
                  <section className={styles.category}>
                    <div className={styles.categorySelect}>
                      <BaseSelect
                        value={unitSelectValue}
                        options={unitOptions}
                        onChange={(value) => {
                          setUnitSelectValue(
                            unitOptions.filter((item) => item.value === value)[0].label
                          )
                        }}
                        style={styles.selectStyle}
                      />
                    </div>
                  </section>
                </section>
              </div>
              <p className={styles.divider} />
              <div
                className={cs({
                  [styles.submit]: nftDetail?.erc721 !== '3',
                  [styles.btnDisabled]: nftDetail?.erc721 === '3',
                })}
                onClick={() => {
                  if (nftDetail?.erc721 === '3') return
                  if (Number(salePrice)) {
                    setShowMoadl(true)
                  } else {
                    if (!salePrice) {
                      setSalePriceError('error')
                    } else if (Number(salePrice) === 0) {
                      message.error('价格必须大于0')
                      return
                    } else if (isNaN(Number(salePrice))) {
                      message.error('输入有误')
                      return
                    }
                  }
                }}
              >
                {t('createIndexSubmitButton2')}
              </div>
              {nftDetail?.erc721 === '3' && <p className={styles.banCrc721}>{t('detailCRC721')}</p>}
            </section>
          ) : (
            <section className={styles.infos}>
              <div className={styles.price}>
                <section className={styles.priceEdit}>
                  <section className={styles.inputSection}>
                    <BaseInput
                      text={t('createSellOrBuyBidInput1')}
                      value={bidPrice}
                      error={bidPriceError}
                      placeHolder={t('createSellOrBuyBidInput1Place')}
                      onChange={(value) => setBidPrice(value)}
                    />
                  </section>
                  <section className={styles.category}>
                    <div className={styles.categorySelect}>
                      <BaseSelect
                        value={unitOptions.filter((item) => item.label === bidSelectValue)[0].value}
                        options={unitOptions}
                        onChange={(value) => {
                          setBidSelectValue(
                            unitOptions.filter((item) => item.value === value)[0].label
                          )
                        }}
                        style={styles.selectStyle}
                      />
                    </div>
                  </section>
                </section>
              </div>
              <div className={styles.price}>
                <section className={styles.priceEdit}>
                  <section className={styles.inputSection}>
                    <ExpiryTime
                      dateSelectValue={dateSelectValue}
                      value={expirydate}
                      onChange={setExpirydate}
                    />
                  </section>
                  <section className={styles.category}>
                    <div className={styles.categorySelect}>
                      <BaseSelect
                        value={dateSelectValue}
                        options={dateOptions}
                        onChange={(value) => {
                          setDateSelectValue(value)
                        }}
                        style={styles.selectStyle}
                      />
                    </div>
                  </section>
                </section>
                <div className={styles.tips}>{t('createSellOrBuyBidInput3Tips')}</div>
              </div>
              <p className={styles.divider} />
              <div
                className={cs({
                  [styles.submit]: nftDetail?.erc721 !== '3',
                  [styles.btnDisabled]: nftDetail?.erc721 === '3',
                })}
                onClick={() => {
                  if (nftDetail?.erc721 === '3') return
                  if (Number(bidPrice) && expirydate) {
                    setExpiryDateLast(expirydate)
                    setShowMoadl(true)
                  } else {
                    if (!bidPrice) {
                      setBidPriceError('bidPriceError')
                    } else if (Number(bidPrice) === 0) {
                      message.error('价格必须大于0')
                      return
                    } else if (isNaN(Number(bidPrice))) {
                      message.error('输入有误')
                      return
                    }
                  }
                }}
              >
                {t('createIndexSubmitButton2')}
              </div>

              {nftDetail?.erc721 === '3' && <p className={styles.banCrc721}>{t('detailCRC721')}</p>}
            </section>
          )}
        </section>
      </section>
      {showMoadl && (
        <PayModal
          title={t('createSellOrBuyPayModalTitle')}
          imgUrl={nftDetail.image}
          miniTitle={` # id ${nftDetail?.tokenId}`}
          subTitle={`${nftDetail.name}`}
          byAddr={nftDetail.owner}
          cancelOnClick={() => setShowMoadl(false)}
          comfirmText={t('createSellOrBuyPayModalComfirmButton')}
          comfirmOnClick={() => {
            if (!approval && !signData) {
              setProgressModalShow(true)
            } else {
              handleFinish(orderState, signData, approval)
            }
          }}
        >
          {typeActive === 0 ? <SaleModalContent /> : BidModalContent}
        </PayModal>
      )}
      <ProgressModal
        onCancel={() => {
          setProgressModalShow(false)
        }}
        visible={progressModalShow}
        onSign={async () => {
          setShowLodding(true)
          const order: any = await getOrder()
          setOrder(order)
          const typeData = orderSign(order, typeActive === 0 ? 'FixedExchange' : 'EnglishExchange')
          await window.confluxJS.provider.sendAsync(
            {
              method: 'cfx_signTypedData_v4',
              params: [window.conflux.selectedAddress, JSON.stringify(typeData)],
              from: window.conflux.selectedAddress,
            },
            async (err: any, res: any) => {
              if (err) {
                setShowLodding(false)

                message.error(err.message)

                return false
              } else {
                setShowLodding(false)
                setSignData(res)
                if (isApproval) {
                  setProgressModalShow(false)
                  setApproval(isApproval)
                  message.success(t('sellOrByApprovalSuccess'))
                }
                return true
              }
            }
          )
          return true
        }}
        onApproval={
          isApproval
            ? null
            : async () => {
                try {
                  const from = userAddress
                  setShowLodding(true)
                  const approvalRes = await sendApproval(
                    from,
                    typeActive === 0 ? FixedExchangeAddress : EnglishExchangeAddrTest,
                    nftDetail?.contractAddress
                  )
                  if (approvalRes) {
                    setApproval(approvalRes)
                    setShowLodding(false)
                    setProgressModalShow(false)
                    message.success(t('sellOrByApprovalSuccess'))
                    // TODO 同种类同类型的nft不需要再次授权
                    return true
                  }
                } catch (error) {
                  setShowLodding(false)
                  message.error(error.message)
                  return false
                }
              }
        }
      />
    </section>
  )
}

export default SellOrBuy
