import React from 'react'
import { useTranslation } from 'react-i18next'

import cs from 'classnames'

import styles from './style.module.scss'

export interface IProps {
  className?: string
}

function NoData({ className }: IProps) {
  const { t } = useTranslation()
  return <div className={cs(className, styles.noData)}>{t('noData')}</div>
}

export default NoData
