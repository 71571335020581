import React from 'react'
import cs from 'classnames'
import styles from './style.module.scss'
import moment from 'moment'
import { Player, LoadingSpinner, ControlBar, PlayToggle } from 'video-react'

export interface IProps {
  name?: string
  imgSrc?: string
  type?: string
  user?: string
  time?: string
  number?: string
  unit?: string
  item?: string
}

function MySpaceHistoryItem({ name, imgSrc = '', type, user, time, number, unit, item }: IProps) {
  const date = moment(time).format('YYYY/MM/DD hh:mm:ss')

  return (
    <div className={styles.border}>
      <ul className={styles.container}>
        <li className={styles.image}>
          {imgSrc.substr(imgSrc.lastIndexOf('.') + 1) === 'mp4' ? (
            <Player className={styles.imageContent} muted autoPlay poster={imgSrc} src={imgSrc}>
              <LoadingSpinner />
              <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
              </ControlBar>
            </Player>
          ) : (
            <img className={styles.imageContent} src={imgSrc} alt="" />
          )}

          <div className={styles.cover}>{name}</div>
        </li>
        <li className={styles.ids}># {item?.tokenId}</li>
        <li className={styles.type}>{type}</li>
        <li className={styles.byContainer}>
          <span>by</span>
          <span className={cs('.ellipsis', [styles.user])}>{user}</span>
        </li>
        <li className={styles.time}>{date}</li>
        <li className={styles.number}>
          {number} {unit}
        </li>
      </ul>
    </div>
  )
}

export default MySpaceHistoryItem
