/**
 *
 *  买家倒计时
 *
 */

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

export interface IProps {
  dataGetOnSaleItem: any
}

function CountDownOver({ dataGetOnSaleItem }: IProps) {
  const [time, setTimeDetail]: any = useState({
    h: null,
    m: null,
    s: null,
    s2: null,
    // s2: 0,
  })
  const [hasTime, setTime] = useState(true) // 是否有倒计时
  const { t } = useTranslation()
  useEffect(() => {
    if (dataGetOnSaleItem && dataGetOnSaleItem.code === 0) {
      const timer = setInterval(() => {
        bidCountdown(dataGetOnSaleItem?.data?.item?.listing?.expirationTime)
      }, 1000)
      return () => {
        clearInterval(timer)
      }
    }
  }, [dataGetOnSaleItem])

  // 报价倒计时
  const bidCountdown = (expirationTime: string) => {
    const endTime = new Date(expirationTime).getTime()
    const now = new Date().getTime()
    const leftTime = endTime - now
    console.log(leftTime)
    if (leftTime <= 0) {
      setTime(false)
      return
    }
    const h = Math.floor(leftTime / 1000 / 60 / 60 / 24)
    const m = Math.floor((leftTime / 1000 / 60 / 60) % 24)
    const s = Math.floor((leftTime / 1000 / 60) % 60)
    const s2 = Math.floor((leftTime / 1000) % 60)
    setTimeDetail({
      h: h < 10 ? `0${h}` : `${h}`,
      m: m < 10 ? `0${m}` : `${m}`,
      s: s < 10 ? `0${s}` : `${s}`,
      s2: s2 < 10 ? `0${s2}` : `${s2}`,
    })
  }

  const renderTime = () => {
    if (!time.h) return <></>
    if (time.h > 0) {
      return (
        <>
          <p className={styles.num}>{time.h}</p>
          <p className={styles.num}>{time.m}</p>
          <p className={styles.num}>{time.s}</p>
        </>
      )
    } else {
      return (
        <>
          <p className={styles.num}>{time.m}</p>
          <p className={styles.num}>{time.s}</p>
          <p className={styles.num}>{time.s2}</p>
        </>
      )
    }
  }
  const renderTimeTitle = () => {
    if (!time.h) return <></>
    if (time.h > 0) {
      return (
        <>
          <p className={styles.txt}>{t('days')}</p>
          <p className={styles.txt}>{t('hours')}</p>
          <p className={styles.txt}>{t('minutes')}</p>
        </>
      )
    } else {
      return (
        <>
          <p className={styles.txt}>{t('hours')}</p>
          <p className={styles.txt}>{t('minutes')}</p>
          <p className={styles.txt}>{t('seconds')}</p>
        </>
      )
    }
  }
  return (
    time && (
      <div className={styles.priceInfosItem}>
        <label className={styles.label}>{t('saleEnd')}</label>
        <div className={styles.timeLine}>
          {renderTime()}
          {/* <p className={styles.num}>{Math.floor(time.m / 60)}</p>
          <p className={styles.num}>{Math.floor(time.s / 60)}</p> */}
        </div>
        <div className={styles.timeLine}>{renderTimeTitle()}</div>
      </div>
    )
  )
}

export default React.memo(CountDownOver)
