import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Spin } from 'antd'
import MySpaceCard from '@/components/MySpaceCard'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import useRequest from '@ahooksjs/use-request'
import useGlobalStore from '@/stores/global'

export interface IProps {
  onCardClick?: any
}

function CollectionArea({ onCardClick }: IProps) {
  const { t } = useTranslation()
  const { userAddress, isLogin, token } = useGlobalStore()
  const { loading, data, run } = useRequest<any>(
    API.collection.getCollectionList.getCollectionList,
    {
      manual: true,
      formatResult: (res) => {
        const collectionList = (res?.data?.collectionList || []).map((item: any) => {
          return {
            ...item,
            imgSrc: item.featuredImage,
            text: item.name,
          }
        })

        if (res?.data?.collectionList) {
          res.data.collectionList = collectionList
        }

        return res
      },
    }
  )

  useEffect(() => {
    if (!isLogin) return
    run({
      bodyParams: {
        owner: userAddress,
      },
    })
  }, [token, isLogin])

  const history = useHistory()

  const renderCard = () => {
    return (data?.data?.collectionList || []).map((item: any) => {
      return (
        <MySpaceCard
          key={item?.id}
          onClick={() => {
            onCardClick(item?.id || '')
          }}
          imgSrc={item.imgSrc}
          text={item.text}
          edit={true}
          id={item?.id || ''}
        />
      )
    })
  }

  const showDivider =
    data && Array.isArray(data?.data?.collectionList) && data?.data?.collectionList.length > 0

  return (
    <Spin spinning={loading}>
      <div className={styles.cardContent}>
        <div className={styles.cards}>{renderCard()}</div>
        {showDivider && <p className={styles.divier} />}
        <div className={styles.cardCreate}>
          <div className={styles.cardCreateButton} onClick={() => history.push('/create')}>
            {t('create')}
          </div>
        </div>
      </div>
    </Spin>
  )
}

export default CollectionArea
