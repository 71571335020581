import React, { useMemo } from 'react'
import styles from './style.module.scss'
import cs from 'classnames'
import Card from '@/components/Card'
import { useTranslation } from 'react-i18next'
import { Player, LoadingSpinner, ControlBar, PlayToggle } from 'video-react'
import { Tooltip } from 'antd'
export interface PayModalProps {
  type?: number // 0为一口价购买 1为bid
  title: string // 必填，弹窗大标题
  imgUrl: string // 必填，图片地址
  imgName?: string // 必填，图片名称
  subTitle?: string // 非必填，图片上方带横杠的次级标题
  miniTitle?: string // 非必填，图片上方不带横杠的小标题
  byAddr?: string // 非必填，来源钱包地址
  cancelText?: string // 非必填，关闭按钮文案，默认值NEVERMIND
  cancelOnClick?: () => void // 非必填，关闭按钮点击事件
  cancelSytle?: string // 非必填，关闭按钮额外样式
  comfirmText?: string // 非必填，提交按钮文案，默认值PAY
  comfirmOnClick?: () => void // 非必填，提交按钮点击事件
  comfirmButtonDisable?: boolean // 非必填，提交按钮disable
  comfirmSytle?: string // 非必填，提交按钮额外样式
  style?: string // 非必填，额外样式
  children: any // 必填，子级内容
}

function Index({
  type,
  title,
  imgUrl,
  imgName,
  cancelText = '',
  cancelOnClick,
  cancelSytle = '',
  comfirmText = '',
  comfirmOnClick,
  comfirmButtonDisable = false,
  comfirmSytle = '',
  subTitle = '',
  miniTitle = '',
  byAddr = '',
  style = '',
  children,
}: PayModalProps) {
  const { t } = useTranslation()
  // 左侧图片区域
  const ModalLeft = () => {
    return (
      <section className={cs(styles.modalLeft, style)}>
        <div className={styles.titleBox}>
          {subTitle && (
            <div className={styles.titles}>
              <div className={styles.horizontalBar}>-</div>
              <div className={styles.subTitle}>{subTitle.toUpperCase()}</div>
            </div>
          )}
          {miniTitle && (
            <div className={cs(styles.titles, styles.miniTitle)}>
              <Tooltip
                title={miniTitle.toUpperCase()}
                overlayStyle={{ whiteSpace: 'nowrap', maxWidth: 920 }}
              >
                <div className={styles.subTitle}>{miniTitle.toUpperCase()}</div>
              </Tooltip>
            </div>
          )}
        </div>
        <div className={styles.imgs}>
          {imgUrl.substr(imgUrl.lastIndexOf('.') + 1) === 'mp4' ? (
            <Player muted autoPlay className="my-player" poster={imgUrl} src={imgUrl}>
              <LoadingSpinner />
              <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
              </ControlBar>
            </Player>
          ) : (
            <Card
              type=""
              text={imgName || ''}
              imgSrc={imgUrl}
              newStyles={styles.newStyles}
              childStyles={styles.childStyles}
            />
          )}
        </div>
        {byAddr && (
          <div className={styles.addrs}>
            <div className={styles.addr}>
              by <span className={styles.blueColor}>{byAddr}</span>
            </div>
          </div>
        )}
      </section>
    )
  }

  const renderType = useMemo(() => {
    switch (type) {
      case 0:
        return t('payModalComfirmButton')
      case 1:
        return t('bidModalComfirmButton')

      default:
        return t('createCollectibleButton')
    }
  }, [type])

  return (
    <div className={styles.payModal}>
      <div className={styles.bc} onClick={() => cancelOnClick()} />
      <section className={cs(styles.payModalContent, styles)}>
        <section className={styles.modalTitle}>{title}</section>
        <section className={styles.modalInfo}>
          <ModalLeft />
          <section className={styles.modalRight}>
            <section className={styles.modalChild}>{children}</section>
            <section className={styles.modalButtons}>
              {/* 关闭按钮 */}
              <div
                className={cs(styles.cancelButton, styles.buttons, cancelSytle)}
                onClick={() => cancelOnClick()}
              >
                {cancelText === '' ? t('payModalCancelButton') : cancelText}
              </div>
              {/* 提交按钮 */}
              <div
                className={cs(
                  styles.comfirmButton,
                  styles.buttons,
                  comfirmButtonDisable && styles.comfirmButtonDisable,
                  comfirmSytle
                )}
                onClick={() => comfirmOnClick()}
              >
                {comfirmText === '' ? renderType : comfirmText}
              </div>
            </section>
          </section>
        </section>
      </section>
    </div>
  )
}

export default React.memo(Index)
