import React from 'react'
import styles from './style.module.scss'
import { upload } from '@/assets/images'
import Card from '@/components/Card'
import cs from 'classnames'
import { message, Upload } from 'antd'
import { useTranslation } from 'react-i18next'
import { removeLocalStorage } from '@/utils/localStorage'
import useGlobalStore from '@/stores/global'
import { Player, LoadingSpinner, ControlBar, PlayToggle } from 'video-react'
const configs = require('@/config')

export interface ImgUpLoadProps {
  imgName?: string // 图片名称，如果没有则下方名称版块消失
  imgUrl?: string // 图片路径， 如果没有则为上传样式
  errorText?: string // 错误提示，如果有则显示警告样式
  style?: string // 额外的样式
  onChange: (res: string) => void // chang回调函数
}

function Index({
  imgName = '',
  imgUrl = '',
  errorText = '',
  style = '',
  onChange,
}: ImgUpLoadProps) {
  const { t } = useTranslation()
  const { userAddress, token } = useGlobalStore()
  // 文件上传参数
  const props = {
    maxCount: 1,
    showUploadList: false,
    action: `${configs.default.baseUrl}/files/uploadFile`,
    headers: {
      token,
      walletAddress: userAddress,
    },
    beforeUpload: (file: any) => {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        message.error(file.name + '图片大小超出限制，请修改后重新上传', 0.8)
        return isLt20M
      }
      if (
        file.type !== 'image/jpg' &&
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'image/gif' &&
        file.type !== 'audio/mpeg' &&
        file.type !== 'audio/mp4, video/mp4' &&
        file.type !== 'video/mp4' &&
        file.type !== 'image/webp'
      ) {
        message.error(`只能上传PNG/GIF/WEBP/MP4/MP3格式文件`)
      }
      return file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/gif' ||
        file.type === 'audio/mpeg' ||
        file.type === 'audio/mp4, video/mp4' ||
        file.type === 'video/mp4' ||
        file.type === 'image/webp'
        ? true
        : Upload.LIST_IGNORE
    },
    data: (file: any) => {
      return { mFile: file }
    },
    onChange: (info: any) => {
      if (info?.file?.response?.code === 401) {
        // token过期处理
        // 清空登陆信息
        removeLocalStorage('account')
        removeLocalStorage('token')
        useGlobalStore.setState({
          isLogin: false,
          userAddress: '',
        })
        message.warn('钱包已锁定，请重新登陆')
        return
      }
      if (info.fileList[0].status === 'done') {
        onChange(
          info?.fileList[0]?.response?.data?.url
            ? info?.fileList[0]?.response?.data?.url
            : { msg: info?.fileList[0]?.response?.msg, msg_en: info?.fileList[0]?.response?.msg_en }
        )
      }
    },
  }

  // 文件上传
  const UpLoadSection = () => {
    return (
      <Upload
        {...props}
        className={errorText ? cs(styles.imgUpload, styles.errorImgUpload) : styles.imgUpload}
      >
        <div className={errorText ? cs(styles.icon, styles.iconError) : styles.icon}>
          <img src={upload} alt="" />
        </div>
        {errorText && <div className={styles.error}>{errorText}</div>}
        <div className={styles.tips}>
          {t('ImgUpLoadTips')}
          <div>{t('ImgUpLoadTipsMax')}</div>
        </div>
      </Upload>
    )
  }

  return (
    <section className={cs(styles.upLoadSeciton, style)}>
      {imgUrl ? (
        <Upload
          {...props}
          className={errorText ? cs(styles.imgUpload, styles.errorImgUpload) : styles.imgUpload}
        >
          {imgUrl.substr(imgUrl.lastIndexOf('.') + 1) === 'mp4' ? (
            <Player muted autoPlay className="my-player" poster={imgUrl} src={imgUrl}>
              <LoadingSpinner />
              <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
              </ControlBar>
            </Player>
          ) : (
            <Card
              type={imgName ? 'trend' : ''}
              text={imgName}
              imgSrc={imgUrl}
              newStyles={styles.newStyles}
              childStyles={styles.childStyles}
            />
          )}
        </Upload>
      ) : (
        <UpLoadSection />
      )}
    </section>
  )
}

export default React.memo(Index)
