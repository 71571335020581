import React, { useEffect } from 'react'
import { useCopyToClipboard } from 'react-use'
import { message } from 'antd'
import { copy } from '@/assets/images'
import { getAvatar } from '@/utils/common'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import useGlobalStore from '@/stores/global'
import { useRequest } from 'ahooks'

function Index() {
  const { isLogin, userAddress } = useGlobalStore()
  const [, copyToClipboard] = useCopyToClipboard()
  const { t } = useTranslation()

  // 获取内容详情
  const { run: getMyProfile, data: dataMyProfile } = useRequest(
    API.user.getMyProfile.getMyProfile,
    {
      manual: true,
    }
  )

  useEffect(() => {
    getMyProfile({
      bodyParams: {
        walletAddress: userAddress,
      },
    })
  }, [])

  const handleCopy = () => {
    if (!isLogin) return
    copyToClipboard(userAddress)
    message.success(t('copySuccess'))
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.imgContainer}>
          <img className={styles.userHead} src={getAvatar(userAddress || '1') as any} alt="" />
        </div>
        <div className={styles.desc}>
          <div className={styles.title}>{t('address')}</div>
          <p className={styles.descInfo}>
            <span>{userAddress}</span>
            <img onClick={handleCopy} src={copy} alt="" />
          </p>
          {false && <p className={styles.edit}>{t('editProfile')}</p>}
        </div>
        <div className={styles.points}>
          <div className={styles.title}>{t('point')}</div>
          <div className={styles.value}>{dataMyProfile?.data?.profile?.mark || 0}</div>
        </div>
      </div>
    </div>
  )
}
export default Index
