/**
     * @desc 查询用户的交易历史信息列表
用于用户页HISTORY
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.UserTradingHistoryQuery
}

const ajax = new Ajax()

export function getUserTradingHistory(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/trading/getUserTradingHistory',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
