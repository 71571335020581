import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import cs from 'classnames'
import styles from './style.module.scss'

const { Option } = Select

export interface IOption {
  label: string
  value: any
}
interface IProps {
  options: IOption[]
  value?: any
  onChange: (val: any) => void
  style?: string
}

const BaseSelect = ({ options, value, onChange, style = '' }: IProps) => {
  const [acVal, setAcval] = useState(value ? value : options[0].value)
  useEffect(() => {
    if (options.find((it) => it.value === value)) {
      setAcval(value)
      onChange(value)
    }
  }, [value])

  const valChange = (val: any) => {
    setAcval(val)
    onChange(val)
  }

  // console.log(value, acVal);
  return (
    <div className={cs(styles.baseSelect, style)}>
      <Select defaultValue={acVal} value={acVal} onChange={valChange}>
        {options.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.label.toUpperCase()}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default BaseSelect
