import React, { useEffect, useState } from 'react'
import { Player, LoadingSpinner, ControlBar, PlayToggle } from 'video-react'
import { clock, transfer } from '@/assets/images'
import { transfer as transferFun } from '@/utils/contract'
import { useBoolean } from 'ahooks'
import Modal from './components/Modal'
import cs from 'classnames'
import styles from './style.module.scss'
import 'video-react/dist/video-react.css'
import { message, Tooltip } from 'antd'
import { isValidCfxAddress } from 'js-conflux-sdk/src/util/address'
import i18n from '@/assets/locales/i18n'
import BigNumber from 'bignumber.js'
import InfoModal from '../InfoModal'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

type IType = 'video' | 'image'
type CardType = 'create' | 'push' | ''
export interface CardProps {
  id?: string
  collectionId?: string
  tokenId?: string
  contractAddress?: string
  owner?: string
  imgSrc?: string
  erc721?: string
  text?: string
  price?: string
  listingStatus?: string
  show?: boolean
  highPrice?: string
  isBid?: boolean
  time?: string
  listing?: any
  maxBid?: any
  type?: IType | any
  cardType?: CardType
  onClick?: () => void
}

function Index({
  id,
  collectionId,
  listing,
  contractAddress = '',
  imgSrc = '',
  tokenId = '',
  text = '',
  price,
  listingStatus,
  highPrice,
  owner = '',
  time,
  maxBid,
  cardType = '',
  isBid = true,
  erc721 = '',
  type = imgSrc.substr(imgSrc.lastIndexOf('.') + 1) === 'mp4' ? 'video' : 'image',
  onClick,
}: CardProps) {
  const { t } = useTranslation()
  const history = useHistory()
  const [modalState, { setTrue, setFalse }] = useBoolean(false)
  const [showLodding, setShowLodding] = useState(false) // loadding显隐

  const [inputValue, setInputValue] = useState('')
  const [hasTime, setTime] = useState(true) // 是否有倒计时
  const [times, setTimeDetail]: any = useState({
    h: 0,
    m: 0,
    s: 0,
  })
  // 报价倒计时
  const bidCountdown = (expirationTime: string) => {
    const endTime = new Date(expirationTime).getTime()
    const now = new Date().getTime()
    const leftTime = endTime - now
    if (leftTime <= 0) {
      setTime(false)
      return
    }
    const h = Math.floor(leftTime / 1000 / 60 / 60)
    const m = Math.floor((leftTime / 1000 / 60) % 60)
    const s = Math.floor((leftTime / 1000) % 60)
    setTimeDetail({
      h: h < 10 ? `0${h}` : `${h}`,
      m: m < 10 ? `0${m}` : `${m}`,
      s: s < 10 ? `0${s}` : `${s}`,
    })
  }

  useEffect(() => {
    const timer = setInterval(() => {
      bidCountdown(listing?.expirationTime)
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [listing])

  const renderCover = () => {
    switch (cardType) {
      case 'create':
        return (
          <>
            <div className={styles.cardOption} onClick={onClick}>
              {t('create')}
            </div>
          </>
        )
      case 'push':
        return (
          <>
            <div
              className={styles.cardOption}
              onClick={(e) => {
                e.stopPropagation()
                history.push(`/sell-or-buy?itemID=${id}&collectionId=${collectionId}`)
              }}
            >
              {t('putOnSell')}
            </div>
          </>
        )
      case '':
        return (
          <>
            {listing?.expirationTime && listing.method === 'bid' && hasTime && (
              <p className={styles.clockText}>
                <img src={clock} alt="" className={styles.clock} />
                <span>{`${times.h}:${times.m}:${times.s}`}</span>
              </p>
            )}

            {text && (
              <p className={styles.name}>
                # {text} # {tokenId}
              </p>
            )}
            {price && (
              <p className={styles.price}>
                <span className={styles.priceTitle}>{t('price')}</span>
                <span className={styles.priceNumber}>
                  {price} {listing?.tokenName}
                </span>
              </p>
            )}
            {highPrice && (
              <p className={styles.price}>
                {/* {isBid && (
                  <span className={styles.hightPriceLabel}>
                    {t(listing.method === 'instant' ? 'instantPrice' : 'highestBid')}{' '}
                  </span>
                )} */}
                <span className={styles.priceTitle}>{t('highestBid')}</span>
                <span className={styles.priceNumber}>
                  <Tooltip title={new BigNumber(highPrice).toFormat()}>
                    <span className={styles.num}>{new BigNumber(highPrice).toFormat()}</span>
                  </Tooltip>
                  &nbsp;
                  {listing?.tokenName}
                </span>
              </p>
            )}
          </>
        )
      default:
        return <></>
    }
  }

  const render = () => {
    if (type === 'image') {
      return (
        <>
          <div
            onClick={() => {
              if (cardType === 'create') return
              onClick && onClick()
            }}
            className={cs({
              [styles.imgContainer]: cardType !== 'create',
              [styles.cardTypeImgContainer]: cardType === 'create',
            })}
          >
            <img src={imgSrc} alt="" className={styles.img} />
          </div>
          {listingStatus === '0' && (
            <img onClick={modalShow} className={styles.transfer} src={transfer} alt="" />
          )}
          <div
            className={cs({
              [styles.cover]: hasTime,
              [styles.coverNoTime]: !hasTime,
            })}
          >
            {renderCover()}
          </div>
        </>
      )
    }
    if (type === 'video') {
      return (
        <>
          <div
            className={cs({
              [styles.imgContainer]: cardType !== 'create',
              [styles.cardTypeImgContainer]: cardType === 'create',
            })}
            onClick={() => {
              if (cardType === 'create') return
              onClick && onClick()
            }}
          >
            <Player muted autoPlay className="my-player" poster={imgSrc} src={imgSrc}>
              <LoadingSpinner />
              <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
              </ControlBar>
            </Player>
          </div>
          {listingStatus === '0' && (
            <img onClick={modalShow} className={styles.transfer} src={transfer} alt="" />
          )}
          <div
            className={cs({
              [styles.cover]: listing?.expirationTime,
              [styles.coverNoTime]: !listing?.expirationTime,
            })}
          >
            {renderCover()}
          </div>
        </>
      )
    }
  }

  const modalShow = () => {
    setTrue()
  }

  const handleChange = (v: string) => {
    setInputValue(v)
  }

  const handleConfirm = async () => {
    if (listingStatus === '0') {
      // 校验地址是否合法
      if (!isValidCfxAddress(inputValue)) {
        message.error(
          i18n.language === 'en'
            ? 'The address entered is not the Conflux format address'
            : '您输入的地址非Conflux格式地址'
        )
        return
      }
      try {
        setShowLodding(true)
        const res = await transferFun(contractAddress, owner, inputValue, tokenId, erc721)
        if (res) {
          setShowLodding(false)
          setFalse()
          setInputValue(i18n.language === 'en' ? 'Transfer successful' : '转账成功')
          message.success(i18n.language === 'en' ? 'Transfer successful' : '转账成功')
        }
      } catch (error) {
        console.log(error.message)
        message.error(error.message)
        setShowLodding(false)
        setFalse()
      }
    } else {
      message.error('请先下架该的NFT')
    }
  }

  const handleCancel = () => {
    setFalse()
    setInputValue('')
  }

  return (
    <>
      <InfoModal
        type="loading"
        visible={showLodding}
        onCancel={() => {
          setShowLodding(false)
        }}
      />
      <div
        className={cs({
          [styles.container]: cardType !== 'create',
          [styles.cardTypeContainer]: cardType === 'create',
        })}
      >
        {render()}
      </div>
      <Modal
        text={text}
        owner={owner}
        imgSrc={imgSrc}
        inputValue={inputValue}
        onCancel={handleCancel}
        onChange={handleChange}
        onConfirm={handleConfirm}
        visible={modalState}
      />
    </>
  )
}

export default React.memo(Index)
