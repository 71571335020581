/**
     * @desc 查询未上架NFT作品列表
用于查询用户未上架的NFT列表，包括未铸造的，collectionId选填
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.NFTItemQuery
}

const ajax = new Ajax()

export function getNotOnSaleItemList(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/item/getNotOnSaleItemList',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
