const header = {
  marketplace: '交易市场',
  create: '创作',
  connect: '链接钱包',
  noInstallWorning: `你还没有安装钱包，请先下载conflux钱包，然后再试`,
}

const home = {
  break: '最新消息 烤仔即将上线',
  breakDesc:
    '艺术家发行认证的唯一版数字艺术品，在以太坊区块链上进行认证以防止伪造，并提供历史来源。',
  break2: '最新消息：京剧脸谱系列NFT作品上架Tspace',
  breakDesc2:
    '非物质文化遗产传承人徐亚娜老师携手Tspace, 传承华夏文明上下五千年的文化底蕴，运用数字化技术帮助非物质文化遗产迸发新的光彩。',
  break1: '最新消息：人类群星闪耀时系列NFT作品上架Tspace',
  breakDesc1:
    '虚拟小猫“Misaki-S26”创作的人类群星闪耀时（Decisive Moments in History）系列NFT作品，爱因斯坦、大卫鲍伊、乔布斯、商高、皮卡丘，真实也好、虚拟也罢，都是人类历史长河中耀眼的明星。当历史和人物遇到加密艺术，又会碰撞除怎样的火花呢。',
  break3: 'Kane Tanaka 丙烯酸漆和铅笔画艺术创作',
  breakDesc3: `田中凯恩是一位日本超级百岁老人，享年 116 岁零 81 天，是继 117 岁的宫古千代于 2018 年 7 月 22 日去世后世界上经核实在世最长寿的人。`,
  break4: 'ZODIAC系列登场',
  breakDesc4: `这一系列作品旨在营造现代浮世绘风格的氛围，同时对中国古代的图腾赋予了很多想象空间，致敬古典美学，运用的元素目前我们有虎/龙/猴…… `,
  break5: 'Ghost Warrior系列作品上架Tspace',
  breakDesc5: `The Ghost Warrior是NFT ERA 数字艺术创作实验室推出的第一个系列作品，也是数字艺术与原创音乐结合的一个全新尝试。The Ghost Warrior系列不仅仅表达了实验室对于NFT元宇宙的角色理解，还搭配了一段全新的NFT加密音乐，从视觉与听觉的角度诠释一个新形势的加密艺术作品。
  The Ghost Warrior is the firs`,
  discover: '发现',
  trending: '热门系列',
  various: '－多样化数字化藏品',
  explore: '探索',
  hotBids: '人气拍卖',
  findMore: '直接向卖家出价或者一口价带走。一旦成交，你就可以在二级市场上卖给其他人',
  findMoreButton: '更多藏品',
  trends: '— 行业趋势',
  lastNews: '新闻',
  trendsDesc: `虽然这不是一个完美的开始，但它会变得更好的`,
  lastNewsOne: `圆桌论坛：Web3.0时代的DeFi演进——新协议与新应用 | FAT颁奖典礼`,
  lastNewsTwo: '从《魔戒》谈起：《古国序列》的诞生',
}

const mySpace = {
  userInfo: '－用户信息',
  mySpace: '我的 Space',
  back: '回到我的藏品系列',
  editProfile: '主页编辑',
  putOnSell: '发售',
  editNFT: '继续编辑',
  highestBid: '最高报价',
  yourBid: '你的报价',
  emptyLabel: '您暂时没有收藏品',
  emptyStartLabel: '开始收集一些作品开启您的收藏吧！',
  emptyStartButton: '开始收藏',
  address: '钱包地址',
  point: '积分',
  mySpaceRefresh: '刷新我的NFTs',
  collectionInfo: '收藏品信息',
  mySpaceNftsMessage: 'NFTs 变化',
  mySpaceNftsDesc: '请点击刷新我的 NFTs按钮获取最新信息',
}

const create = {
  createIndexMiniTitle: '- 编辑信息',
  createIndexTitle: '创建您的收藏',
  createImgInfoTitle: '在下方输入信息',
  createImgInfoDescribe: '上传附件/输入信息后就可以预览藏品未来展出的样子。',
  ImgUpLoadTips: '格式: PNG, GIF, WBEP, MP4, MP3',
  ImgUpLoadTipsMax: '最大: 100 MB',
  createIndexInputName: '名称',
  createIndexInputNamePlace: '收藏品名称',
  createIndexInputDescription: '描述',
  createIndexCategory: '分类',
  createIndexSubmitButton: '创建',
  createIndexSubmitButton2: '确认',
  createPass: '跳过',
  createIndexSubmitButton3: '保存',
  payModalCancelButton: '取消',
  payModalComfirmButton: '支付',
  bidModalComfirmButton: '报价',
}

const createCollectible = {
  createCollectibleMiniTitle: '- 编辑您的收藏信息',
  createCollectibleTitle: '创建收藏品到您的收藏',
  CIP115:
    '管理多种类型合约的标准接口。单个部署的合约可以包括同质化通证、非同质化通证牌或其他配置的任何组合。',
  createCollectibleInputName: '名称',
  createCollectibleInputNamePlace: '请输入作品名称',
  createCollectibleInputDescription: '描述',
  createCollectibleInputProperties: '属性',
  createCollectibleInputPropertiesPlace: '属性',
  createCollectibleAddButton: '添加＋',
  createCollectibleTips: '我们不会接触您的私钥，更不会在未经允许的情况下动用您的任何资产',
  createCollectibleSubmitButton: '创建',
  createCollectibleButton: '付款铸造',
  createCollectiblePayModalTitle: '铸造价格',
  createCollectiblePayModalSubTitle: '确认铸造',
  createCollectiblePriceTitle: '费用',
  createCollectiblePriceBalance: '余额',
  createCollectiblePriceTips1: '每次铸造上链都会收取5USDT。',
  createCollectiblePriceTips2: '铸造上链后信息将无法更改，请仔细检查后再提交付款',
}

const createSellOrBuy = {
  createSellOrBuyMiniTitle: '－ 离准备好只有最后一步',
  createSellOrBuyTitle: '上架销售',
  createSellOrBuyTypesTitle: '选择交易方式',
  typesCard1Title: '挂单出售',
  typesCard1Value: '以一口价方式销售',
  typesCard2Title: '最高竞价',
  typesCard2Value: '价高者得',
  createSellOrBuySaleInputName: '一口价模式',
  createSellOrBuySaleInputNamePlace: '预定价格',
  createSellOrBuyBidInput1: '起拍价',
  createSellOrBuyBidInput1Place: '设置起拍价',
  createSellOrBuyBidInput2: '保留价格',
  createSellOrBuyBidInput2Tips: '如果任何出价高于此价格，它将在到期日结束时自动达成交易',
  createSellOrBuyBidInput3: '截止日期',
  createSellOrBuyBidInput3Tips: '您的拍卖将自动结束在这个时候，最高的投标人将获胜。',
  createSellOrBuyPayModalComfirmButton: '发售',
  createSellOrBuyPayModalTitle: '确认发售',
  createSellOrBuyPayModalSubTitle: '作品名称',
  createSellOrBuySaleModalTitle: '售价',
  createSellOrBuySaleModalTips: '你的藏品将以一口价销售。',
  createSellOrBuyBidModalTitle: '起拍价',
  createSellOrBuyBidModalTips1: '你的物品将被拍卖',
  createSellOrBuyBidModalTips2: '出价最高者将赢得它',
  sellOrBuyCustomize: '自定义',
  sellOrBuyOneWeek: '1周',
  sellOrByOneMonth: '1月',
  sellOrByExpiryTime: '选择日期',
  sellOrByApprovalSuccess: '请重点击按钮进行上架',
  createSellOrBuyCreateSuccess: '作品正在审核中，审核通过后可继续创建',
  createSellOrBuyCreateSuccessTitle: '温馨提示',
}

const createError = {
  errorName: '请输入作品名称',
  errorDescription: '请在空白处填写作品描述',
}

const detailModal = {
  title: '付款步骤',
  main1: '授权',
  main2: '签名',
  desc1: '平台合约执行您的操作',
  desc2: '您同意进行本次操作',
  button1: '授权',
  button2: '签名',
  makeBid: '出价',
  confirmOrder: '确认你的订单',
  yourBid: '出价金额',
  total: '合计',
  atLeast: '最低',
  yourBalance: '你的余额',
  bidSuc: '报价成功',
  getWcfx: '获取WCFX',
  pay: '付款',
}

const detailPage = {
  curPrice: '当前价格',
  highestPrice: '最高报价',
  miniPrice: '起拍价格',
  saleEnd: '拍卖倒计时',
  overEnd: '卖家确认倒计时',
  notOnSale: '未上架',
  accept: '接受报价',
  sell: '上架',
  cancelSell: '下架',
  bugNow: '立即购买',
  bid: '竞价',
  paySteps: '付款步骤',
  currentBids: '当前出价',
  history: '历史',
  noData: '无历史数据',
  sellTips: '上架后信息数据自动更新',
  info: '基础信息',
  chainInfo: '区块链信息',
  properties: '属性',
  contractAddress: '合约地址',
  tokenId: '代币 Id',
  blockchain: '区块链',
  owner: '持有人',
  creator: '创作人',
  hours: '时',
  seconds: '秒',
  minutes: '分',
  days: '天',
  tips1: '我明白此商品并未经过tspace平台的认证，并愿意承担购买风险。',
  tips2: '我同意Tspace风险',
  tips3: '警告和免责声明。',
  tipsCreate:
    '我保证上传的NFT不包含任何反动、黄色、盗版等违法信息和图片。如果违反，接受平台自动下架该NFT的处罚，同时接受不予返还铸造费的决定。',
  hoverTips: '余额不足',
  detailTitile: '藏品信息',
  verified: '系列已认证',
  unverified: '系列未认证',
  detailHideInfoLabel: '显示可解锁内容',
  detailHideInfoModalLabel: '解锁内容',
  detailHideInfoLogin: '请先登录后再操作',
  detailHideInfoBuy: '购买后解锁',
  detailCRC721: '暂时不支持CRC721协议NFT上架',
}

const explore = {
  variousDigitalItems: '藏品系列',
  explore: '探索',
  status: '交易方式',
  collections: '收藏系列',
  myCollections: '我的收藏',
  items: '项',
}
const timeList = {
  recentList: '最新上架',
  endingSoon: '即将结束',
  priceHighToLow: '价格从高到低',
  priceLowToHigh: '价格从低到高',
  highestBids: '最高出价',
}

const common = {
  acceptSuc: '接受报价成功',
  copySuc: '分享地址复制成功',
  cancelOfferSuc: '取消报价成功',
  cancelSuc: '下架成功',
  sellSuc: '上架成功',
  unLock: '解锁',
  disconnect: '钱包已断开',
  approveWallet: '请重新解锁授权钱包',
  copySuccess: '复制成功',
  walletVersion: '您的ConfluxPortal钱包版本过低，请升级',
  refresh: '刷新成功',
  price: '价格',
}
const nftCardModal = {
  confirmTransfer: '确认转账',
  artworkName: '— 作品名称',
  transferTo: '转移给',
  walletAddress: '钱包地址',
  walletAddressDetail: '为了NFT成功转账，请确认该钱包地址为有效地址。',
  nevermindButton: '取消',
  nftCardModalConfirm: '确认',
}

const card = {
  highestBid: '最高出价',
  instantPrice: '价格',
  minsLeft: '倒计时',
}

const infoModal = {
  infoLoading: '加载中',
  infoLoadingDesc: '请等一下，正在进行中',
  infoSucceed: '成功',
  infoSucceedTrans: '转账成功',
  infoSucceedDesc: '查看详情',
  infoCancel: '取消出价',
  infoCancelDesc: '是否取消出价?',
  infoCancelButton: '取消',
}

const footer = {
  footerTitle: '最新藏品',
  slogin: '让你的资产流动起来',
  developer: '开发者',
  developerDoc: '开发者文档',
  wcfxSwap: '兑换WCFX',
  confluxPortal: 'Conflux Portal',
  cnfiCify: '烤仔城市',
  ancientChineseGods: '古国序列',
  wallet: '钱包下载',
  download: '立即下载',
  about: '帮助中心',
  aboutUs: '关于我们',
  city: '烤仔城市',
  faq: '常见问题',
  contract: '联系我们',
  privacy: '隐私条款',
  terms: '法律政策',
  catalogueText: '分类',
  marketPlace: '交易市场',
  read: '我已认真阅读并同意以上条款',
  safeModal: {
    title: '风险提示及免责声明',
    tips: '请您在使用本平台（页面）前，务必仔细阅读以下风险提示、免责声明的全部内容，在您全面理解并完全同意后方可继续使用本平台。',
    descs: [
      '1. 在使用Tspace平台服务时，若您或您的相对方未遵从本协议或相关网站说明中之操作提示、规则且Tspace平台不承担损害赔偿责任。',
      '2. Tspace阻止、禁止和限制通过Tspace平台进行的任何交易，所有您在Tspace平台进行的交易行为系您的个人行为，有约束力的合同关系在您和您的相对方之间建立，与Tspace平台及Tspace无关。Tspace平台及Tspace对因您的交易行为所引起的一切风险、责任、损失、费用不承担任何责任。',
      '3. 我们建议您在安全的网络环境中使用Tspace平台，以避免可能存在的安全隐患。',
      '4. 请您在使用我们的服务过程中，警惕非Tspace平台官方的诈骗行为。一旦发现此类行为，我们鼓励您第一时间告知我们。',
      '5. 由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人资料和客户信息的泄露，本站不承担任何法律责任。',
      '6. 在法律允许的情况下，如司法机关、监管机构及其他相关机构提出要求，Tspace有可能提供您的相关信息。',
      '7. 凡以您的用户名登录实施的一切行为均视为您的行为，所产生的法律后果由您承担。',
    ],
    agree: '我同意',
  },
  view: '查看详情',
  topBoxText: ['Conflux链上虚拟资产交易市场'],
  sellOrBuyFee: '手续费',
  sellOrBuyTotalReceive: '总计收款',
}

export const privary = {
  privaryTitle: 'TSPACE平台隐私政策',

  privaryDescs: [
    {
      title: '',
      children: [
        '尊敬的用户：',
        'Tspace NFT交易平台（以下简称“Tspace”或“我们”）尊重并保护用户（以下简称“您”或“用户”）的隐私，您使用Tspace平台时，Tspace将按照本隐私政策（以下简称“本政策”）收集、使用您的个人信息。',
        'Tspace建议您在使用本产品（以下简称“Tspace平台”）之前仔细阅读并理解本政策全部内容,针对免责声明等条款在内的重要信息将以加粗的形式体现。本政策有关关键词定义与Tspace《Tspace平台服务协议》保持一致。',
        '本政策可由Tspace随时更新，更新后的政策一旦公布即代替原来的政策，如果您不接受修改后的条款，请立即停止使用Tspace平台，您继续使用Tspace平台将被视为接受修改后的政策。经修改的政策一经在Tspace平台上公布，立即自动生效。您知悉本政策及其他有关规定适用于Tspace平台及Tspace平台上Tspace所自主拥有的DApp。',
      ],
    },
    {
      title: '一、我们收集您的哪些信息',
      children: [
        '请您知悉，我们收集您的以下信息是出于满足您在Tspace平台服务需要的目的，且我们十分重视对您隐私的保护。在我们收集您的信息时，将严格遵守“合法、正当、必要”的原则。且您知悉，若您不提供我们服务所需的相关信息，您在Tspace平台的服务体验可能因此而受到影响。',
        '1. 我们将收集您的移动设备信息、操作记录、交易记录、钱包地址等个人信息。',
        '2. 为满足您的特定服务需求，我们将收集您的姓名、手机号码、邮件地址等信息。',
        '3. 您知悉：您在Tspace平台上的钱包密码、私钥、助记词、Keystore并不存储或同步至Tspace服务器。Tspace不提供找回您的钱包密码、私钥、助记词、Keystore的服务。',
        '4. 除上述内容之外，您知悉在您使用Tspace平台特定功能时，我们将在收集您的个人信息前向您作出特别提示，要求向您收集更多的个人信息。如您选择不同意，则视为您放弃使用Tspace平台该特定功能。',
        '5. 当您跳转到第三方DApp后，第三方DApp会向您收集个人信息。Tspace平台不持有第三方DApp向您收集的个人信息。',
        '6. 在法律法规允许的范围内，Tspace可能会在以下情形中收集并使用您的个人信息无需征得您的授权同意：',
        '（1） 与国家安全、国防安全有关的；',
        '（2） 与公共安全、公共卫生、重大公共利益有关的',
        '（3） 与犯罪侦查、起诉、审判和判决执行等有关的；',
        '（4） 所收集的个人信息是您自行向社会公众公开的；',
        '（5） 从合法公开披露的信息中收集您的个人信息，如合法的新闻报道，政府信息公开等渠道；',
        '（6） 用于维护服务的安全和合规所必需的，例如发现、处理产品和服务的故障；',
        '（7） 法律法规规定的其他情形。',
        '7. 我们收集信息的方式如下：',
        '（1） 您向我们提供信息。例如，您在“个人中心”页面中填写姓名或手机号码，或在反馈问题时提供邮件地址，或在使用我们的特定服务时，您额外向我们提供。',
        '（2） 我们在您使用Tspace平台的过程中获取信息，包括您移动设备信息以及您对Tspace平台的操作记录等信息。',
      ],
    },
    {
      title: '二、我们如何使用您的信息',
      children: [
        '1. 我们通过您移动设备的唯一序列号，确认您与您的钱包的对应关系。',
        '2. 我们将向您及时发送重要通知，如网站更新、服务协议及本政策条款的变更。',
        '3. 我们通过收集您公开的钱包地址和提供的移动设备信息来处理您向我们提交的反馈。',
        '4. 我们收集您的个人信息进行Tspace内部审计、数据分析和研究等，以期不断提升我们的服务水平。',
        '5. 依照《Tspace平台服务协议》及Tspace其他有关规定，Tspace将利用用户信息对用户的使用行为进行管理及处理。',
        '6. 法律法规规定及与监管机构配合的要求。',
      ],
    },
    {
      title: '三、您如何控制自己的信息',
      children: [
        '我们努力为用户的信息安全提供保障，以防止信息的丢失、不当使用、未经授权访问或披露。',
        '我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会使用加密技术、匿名化处理等手段来保护你的个人信息。',
        '我们建立专门的管理制度、流程和组织以保障信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。',
        '若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知你。',
      ],
    },
    {
      title: '四、我们可能分享或传输您的信息',
      children: [
        '未经您事先同意，Tspace不会将您的个人信息向任何第三方共享或转让，但以下情况除外：',
        '（1） 事先获得您明确的同意或授权；',
        '（2） 所收集的个人信息是您自行向社会公众公开的；',
        '（3） 所收集的个人信息系从合法公开披露的信息中收集，如合法的新闻报道，政府信息公开等渠道；',
        '（4） 与Tspace的关联方共享，我们只会共享必要的用户信息，且受本隐私条款中所声明的目的的约束；',
        '（5） 根据适用的法律法规、法律程序的要求、行政机关或司法机关的要求进行提供；',
        '（6） 在涉及合并、收购时，如涉及到个人信息转让，Tspace将要求个人信息接收方继续接受本政策的约束。',
      ],
    },
    {
      title: '五、我们如何保护您的信息',
      children: [
        '1. 如Tspace停止运营，Tspace将及时停止继续收集您个人信息的活动，将停止运营的通知公告在Tspace平台上，并对所持有的您的个人信息在合理期限内进行删除或匿名化处理。',
        '2. 为了保护您的个人信息，Tspace将采取数据安全技术措施，提升内部合规水平，增加内部员工信息安全培训，并对相关数据设置安全访问权限等方式安全保护您的隐私信息。',
        '3. 我们将在Tspace平台“联系邮件tspaceservice@gmal.com”中向您发送有关信息安全的消息，并不时在Tspace平台“FAQ”版块更新钱包使用及信息保护的资料，供您参考。',
      ],
    },
    {
      title: '六、对未成年人的保护',
      children: [
        '我们对保护未满18周岁的未成年人做出如下特别约定：',
        '1. 未成年人应当在父母或监护人指导下使用Tspace相关服务。',
        '2. 我们建议未成年人的父母和监护人应当在阅读本政策、《Tspace平台服务协议》及我们的其他有关规则的前提下，指导未成年人使用Tspace平台。',
        '3. Tspace平台将根据国家相关法律法规的规定保护未成年人的个人信息的保密性及安全性。',
      ],
    },
    {
      title: '七、免责声明',
      children: [
        '1. 请您注意，您通过Tspace平台接入第三方DApp后，将适用该第三方DApp发布的隐私政策。该第三方DApp对您个人信息的收集和使用不为Tspace所控制，也不受本政策的约束。Tspace无法保证第三方DApp一定会按照Tspace的要求采取个人信息保护措施。',
        '2. 您应审慎选择和使用第三方DApp，并妥善保护好您的个人信息，Tspace对其他第三方DApp的隐私保护不负任何责任。',
        '3. Tspace将在现有技术水平条件下尽可能采取合理的安全措施来保护您的个人信息，以避免信息的泄露、篡改或者毁损。Tspace系利用无线方式传输数据，因此，Tspace无法确保通过无线网络传输数据的隐私性和安全性。',
      ],
    },
    {
      title: '八、其他',
      children: [
        '1. 您需全面了解并遵守您所在司法辖区与使用Tspace服务所有相关法律、法规及规则。',
        '2. 您在使用Tspace服务过程中，如遇到任何有关个人信息使用的问题，您可以通过在Tspace平台提交反馈等方式联系我们。',
        '3. 您可以在Tspace平台中查看本政策及Tspace其他服务规则。我们鼓励您在每次访问Tspace平台时都查阅Tspace的服务协议及隐私政策。',
        '4. 本政策的任何译文版本仅为方便用户而提供，无意对本政策的条款进行修改。如果本政策的中文版本与非中文版本之间存在冲突，应以此版本为准。',
        '5. 本政策自2020年11月1日起适用。',
      ],
    },
  ],
  privaryTips: '本政策未尽事宜，您需遵守Tspace不时更新的公告及相关规则。',
  privaryTeam: 'Tspace平台运营团队',
}

export const terms = {
  title: 'Tspace平台用户服务条款',
  descs: [
    {
      title: '',
      children: [
        '尊敬的用户：',
        '感谢您选择Tspace平台服务。《Tspace平台服务协议》（以下简称“本协议”）由Tspace团队（以下简称“Tspace”或“我们”）和用户（以下简称“您”或“用户”）签订，本协议在您与Tspace之间具有合同上的法律效力。',
        'Tspace在此特别提醒您在使用Tspace平台（以下简称“Tspace” 或“本网站”）之前，请认真阅读《Tspace平台服务协议》及后文提到的相关协议，尤其是本协议规定的“免责及责任限制”等条款将以加粗的形式体现，确保您充分理解本协议中各条款，并自主考虑风险。',
      ],
    },
    {
      title: '一、关于本协议的确认与接纳',
      children: [
        '1. 您理解本协议及有关协议适用于Tspace平台及Tspace平台上Tspace团队所自主开发和拥有的去中心化应用（简称“DApp”）（排除第三方开发的DApp）。',
        '2. 您打开Tspace平台网页并创建或导入钱包，即视为您已经充分阅读并接受本协议全部条款，本协议立即生效，对双方具有约束力。',
        '3. 本协议可由Tspace随时更新，经修改的协议一经在Tspace平台上公布，立即自动生效，恕不再另行通知。在Tspace公布修改协议条款后，如果您不接受修改后的条款，请立即停止使用Tspace平台，您继续使用Tspace平台将被视为接受修改后的协议。',
        '4. 如果您未满18周岁，或者是无民事行为能力人或限制民事行为能力人，请在父母或监护人指导下使用Tspace平台。',
      ],
    },
    {
      title: '二、服务内容',
      children: [
        '1. 创建或导入钱包。对Tspace平台支持的数字代币，您可以使用Tspace平台生成新钱包或导入相关区块链系统的其它钱包工具生成的兼容钱包。',
        '2. 管理数字资产。您可以使用Tspace平台添加、保管并移除Tspace平台所支持的数字代币。',
        '3. 浏览DApp。用户通过在Tspace平台上的链接，可以跳转至DApp并使用该DApp（包括Tspace自己的DApp和第三方DApp）提供的服务。',
        '4. 兑换记录。我们将通过区块链系统拷贝您全部或部分的兑换记录。但兑换记录以区块链系统的记载为准。',
        '5. 其他Tspace认为有必要提供的服务。',
      ],
    },
    {
      title: '三、您的权利义务',
      children: [
        '（一）创建或导入钱包',
        '1. 创建或导入钱包：您有权在您的移动设备上通过Tspace平台创建和/或导入钱包，有权设定钱包的钱包密码等信息。',
        '2. 身份验证：按照有关法律法规和政策要求，特定用户在使用Tspace平台提供的有关服务时，应当按照Tspace平台的提示及时完成相关身份验证，要求您提交包括但不限于您的姓名、手机号码等个人信息。否则该特定用户将无法使用有关服务，因特定用户拖延造成的损失由您自行承担。',
        '（二）使用',
        '1. 您在使用 “Tspace平台 ”之前，必须先行注册，并填写注册资料，取得Tspace平台账号、密码。Tspace平台账号注册资料包括但不限于您的账号名称、头像、密码、注册或更新Tspace平台账号时输入的所有信息。用户应自行妥善保管移动设备、钱包密码、私钥、助记词、Keystore等信息。Tspace不负责为用户保管以上信息。因您遗失移动设备、主动或被动泄露、遗忘钱包密码、私钥、助记词、Keystore或遭受他人攻击、诈骗等所引起的一切风险、责任、损失、费用应由您自行承担。',
        '2. 遵循信息提示。您了解并同意遵循Tspace平台对您做出的信息提示，按照信息提示的内容进行操作，否则，由此引起的一切风险、责任、损失、费用等应由您自行承担。',
        '3. 您知悉并理解Tspace平台没有义务对链接的第三方DApp服务或其他行为履行尽职调查义务，您应当理性做出决策并自主承担相应的风险。',
        '4. 积极完成身份验证。当Tspace平台合理认为您的行为出现异常的，或认为您的身份信息存在疑点的，或Tspace平台认为应核对您身份证件或其他必要文件的情形时，请您积极配合Tspace平台核对您的有效身份证件或其他必要文件，及时完成相关的身份验证。',
        '5. 合法合规。您知悉在Tspace平台进行操作时或利用Tspace平台进行兑换时，您应当遵循有关法律法规、国家政策的要求。',
        '6. 公告通知。Tspace平台会以网站公告、电子邮件、发送短信、电话、消息中心信息、弹窗提示或客户端通知等方式向您发送通知，例如通知您兑换进展情况，或者提示您进行相关操作，请您及时予以关注。',
        '7. 服务费用与纳税义务。Tspace平台暂时不向您收取任何形式的服务费或手续费，将来需对某些服务进行收费时将另行约定或公布规则。',
      ],
    },
    {
      title: '四、风险提示',
      children: [
        '1. 在使用Tspace平台服务时，若您或您的相对方未遵从本协议或相关网站说明中之操作提示、规则且Tspace平台不承担损害赔偿责任。',
        '2. Tspace阻止、禁止和限制通过Tspace平台进行的任何交易，所有您在Tspace平台进行的交易行为系您的个人行为，有约束力的合同关系在您和您的相对方之间建立，与Tspace平台及Tspace无关。Tspace平台及Tspace对因您的交易行为所引起的一切风险、责任、损失、费用不承担任何责任。',
        '3. 您理解当您在Tspace平台上创建或导入钱包之后，您的Keystore、私钥、助记词等信息仅存储在当前的这台移动设备中，不存储在Tspace平台或Tspace的服务器上。您可以按照Tspace平台提供的操作指南采取同步钱包等方式更换移动设备。但若您未保存或备份钱包密码、私钥、助记词、Keystore等信息且在您移动设备丢失的情况下，您的数字代币将因此丢失，Tspace无法为您找回，您需自行承担相应损失。若您在导出、保存或备份钱包密码、私钥、助记词、Keystore等信息的时候泄密，或保存或备份上述信息的设备或服务器被黑客攻击或控制等情况下，您的数字代币将因此丢失，Tspace无法为您找回，您需自行承担相应损失。',
        '4. 我们建议您在创建或导入钱包时对您钱包的钱包密码、私钥、助记词及Keystore等信息做好安全备份。我们提请您注意，请不要采用以下备份方式：截图、邮件、记事本、短信、微信、QQ等电子备份方式。我们建议您在纸质记事本上抄写助记词和Keystore等信息，同时您亦可将电子数据保管至密码管理器。',
        '5. 我们建议您在安全的网络环境中使用Tspace平台，以避免可能存在的安全隐患。',
        '6. 请您在使用我们的服务过程中，警惕非Tspace平台官方的诈骗行为。一旦发现此类行为，我们鼓励您第一时间告知我们。',
      ],
    },
    {
      title: '五、服务的变更、中断、终止',
      children: [
        '1. 您同意Tspace为保证自主业务经营权可以暂时提供部分服务功能，或于将来暂停部分服务功能或开通新的服务功能。当任何功能减少或者增加或者变化时，只要您仍然使用Tspace提供的服务，表示您仍然同意本协议或者本协议修正后的条款。',
        '2. 您理解存在如下情形时，Tspace将暂停提供服务：',
        '（1）因设备、区块链系统维修、升级、故障和通信中断等技术原因而中断业务；',
        '（2）因台风、地震、海啸、洪水、停电、战争或恐怖袭击等不可抗力因素，病毒、木马、黑客攻击、系统不稳定或政府行为等原因，造成Tspace系统不能提供服务或Tspace合理认为继续提供服务会有较大风险的；',
        '（3）发生Tspace无法控制或合理预见的其他情形。',
        '3. 当您出现如下情况时，Tspace可单方面中止或终止您使用Tspace平台的部分或全部功能：',
        '（1）用户死亡；',
        '（2）盗用他人的钱包信息或移动设备；',
        '（3）填写个人信息时提供虚假信息、在回答能力测评问卷时非本人操作；',
        '（4）拒绝Tspace为提升Tspace平台功能而发起的强制更新操作；',
        '（5）将Tspace平台用于违法或犯罪活动；',
        '（6）妨碍其他用户正常使用；',
        '（7）伪称Tspace的工作人员或管理人员；',
        '（8）攻击、侵入、更改或以任何其他方式威胁Tspace计算机系统的正常运作；',
        '（9）利用Tspace平台宣传垃圾广告；',
        '（10）散布谣言，损害Tspace和Tspace平台商誉；',
        '（11）违法行为，其他违反本协议的行为，及Tspace合理认为应当暂停功能的情形。',
        '4. 当您与Tspace之间的服务关系变更、中断、终止时，您仍有权在合理时间内导出您钱包等信息。',
      ],
    },
    {
      title: '六、您合法使用Tspace服务的承诺',
      children: [
        '1. 您应遵守您所居住的国家或地区的法律法规，不得将Tspace平台用于任何非法目的，也不得以任何非法方式使用Tspace服务。',
        '2. 您不得利用Tspace平台从事违法或犯罪的行为，包括但不限于：',
        '（1）反对宪法所确定的基本原则，危害国家安全、泄漏国家秘密、颠覆国家政权、破坏国家统一的；',
        '（2）从事任何违法犯罪行为，包括但不限于洗钱、非法集资等；',
        '（3）通过使用任何自动化程序、软件、引擎、网络爬虫、网页分析工具、数据挖掘工具或类似工具，接入Tspace服务、收集或处理Tspace所提供的内容，干预或试图干预任何用户或任何其他方式接入Tspace服务的行为；',
        '（4）提供赌博资讯或以任何方式引诱他人参与赌博；',
        '（5）侵入他人Tspace平台钱包盗取数字代币；',
        '（6）从事任何侵害或可能侵害Tspace平台服务系统、数据之行为；',
        '（7）其他违法以及Tspace有正当理由认为不适当的行为。',
        '3. 您理解并同意，如因您违反有关法律（包括但不限于海关及税务方面的监管规定）或者本协议之规定，使Tspace遭受任何损失、受到任何第三方的索赔或任何行政管理部门的处罚，您应对Tspace进行赔偿，包括合理的律师费用。',
        '4. 您承诺按时缴纳Tspace的服务费用（如有），否则Tspace有权暂停或中止对您提供的服务。',
      ],
    },
    {
      title: '七、隐私条款',
      children: [
        'Tspace十分重视对用户隐私的保护，相关隐私保护政策请参考Tspace公布并不时更新的《Tspace平台隐私政策》。',
      ],
    },
    {
      title: '八、免责及责任限制',
      children: [
        '1. Tspace仅对本协议中所列明的义务承担责任。',
        '2. 您理解和同意，在法律所允许的范围内，Tspace只能按照现有的技术水平和条件提供服务。因下列原因导致Tspace平台无法正常提供服务，Tspace不承担责任：',
        '（1）Tspace平台系统停机维护或升级；',
        '（2）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；',
        '（3）您的移动设备软硬件和通信线路、供电线路出现故障的；',
        '（4）您操作不当或未通过Tspace授权或认可的方式使用Tspace服务的；',
        '（5）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行等原因或政府行为等原因；',
        '（6）非因Tspace的原因而引起的任何其它原因。',
        '3. Tspace对以下情形不承担责任：',
        '（1）因用户遗失移动设备、删除且未备份Tspace平台、删除且未备份钱包、钱包被盗或遗忘钱包密码、私钥、助记词、Keystore而导致的数字代币丢失；',
        '（2）因用户自行泄露钱包密码、私钥、助记词、Keystore，或借用、转让或授权他人使用自己的移动设备或Tspace平台钱包，或未通过Tspace官方渠道下载Tspace平台应用程序或其他不安全的方式使用Tspace平台应用程序导致的数字代币丢失；',
        '（3）因用户误操作（包括但不限于您输错转账地址、您自身选择转账节点服务器的问题）导致的数字代币丢失；',
        '（4）因用户不理解区块链技术的性质而进行误操作导致的数字代币丢失；',
        '（5）因时间滞后、区块链系统不稳定等原因导致Tspace拷贝用户在区块链上的兑换记录发生偏差；',
        '（6）用户在第三方DApp上操作产生的风险和后果。',
        '4. Tspace不提供以下形式的保证：',
        '（1）Tspace服务将符合您的全部需求；',
        '（2）您经由Tspace服务取得的任何技术、产品、服务、资讯将符合您的期望。',
        '5. 在任何情况下，Tspace对本协议所承担的违约赔偿责任总额不超过一万元人民币。',
        '6. 您理解Tspace平台仅作为用户显示兑换信息的工具，Tspace不提供法律、税务或投资建议等服务。您应当自行向法律、税务、投资方面的专业人士寻求建议，且您在使用我们服务过程中所遭受的损失，Tspace概不负责。',
        '7. 您理解我们可能不时更改我们的用户准入标准，限定向某一特定群体提供服务的范围和方式等。',
      ],
    },
    {
      title: '九、完整协议',
      children: [
        '1. 本协议由《Tspace平台服务协议》、《Tspace平台隐私政策》及Tspace不时公布的各项规则（包括“帮助中心”的内容）组成。',
        '2. 本协议部分内容被有管辖权的法院认定为违反或无效的，不因此影响其他内容的效力。',
        '3. 本协议的任何译文版本仅为方便用户而提供，无意对本协议的条款进行修改。如果本协议的中文版本与非中文版本之间存在冲突，应以中文版本为准。',
      ],
    },
    {
      title: '十、知识产权保护',
      children: [
        'Tspace平台系Tspace开发并拥有知识产权的应用程序。 Tspace平台中显示的任何内容（包括本协议、公告、文章、视频、音频、图片、档案、资讯、资料、商标或标识）的知识产权归Tspace或第三方权利人所有。用户仅可为持有和管理数字代币之目的使用Tspace平台应用程序及其中的内容。未经Tspace或第三方权利人的事先书面同意，任何人不得擅自使用、修改、反向编译、复制、公开传播、改变、散布、发行或公开发表上述应用程序及内容。',
      ],
    },
    {
      title: '十一、法律适用与争议解决',
      children: [
        '1. 本协议及其修订版之效力、解释、变更、执行与争议解决均适用【】法律，如无相关法律规定，则应当适用国际商业惯例和（或）行业惯例。',
        '2. 若您和Tspace之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，任何一方可提交Tspace所在地有管辖权的人民法院管辖。',
      ],
    },
    {
      title: '十二、其他',
      children: [
        '1. 您需全面了解并遵守您所在司法辖区与使用Tspace服务所有相关法律、法规及规则。',
        '2. 您在使用Tspace服务过程中，如遇到任何问题，您可以通过在Tspace平台提交反馈等方式联系我们。',
        '3. 您可以在Tspace平台中查看本协议。Tspace鼓励您在每次访问Tspace平台时都查阅Tspace的服务协议。',
        '4. 本协议自2020年11月1日起适用。',
      ],
    },
  ],
  tips: '本协议未尽事宜，您需遵守Tspace不时更新的公告及相关规则。',
  team: 'Tspace平台运营团队',
}

export const about = {
  rules: [
    {
      title: 'Tspace是什么',
      descs: [
        'Tspace-宝藏宇宙，是首个基于树图（Conflux）区块链网络的去中心化数字资产交易平台，能够服务于多方用户管理数字收藏品NFT（Non-Fungible Token）的需求。NFT具有独一无二、稀缺性、不可复制的独特属性，可应用于多种虚拟与现实应用场景。Tspace凭借Conflux网络高速、安全、低成本的技术优势，能够更好地支持NFT的铸造、展示和流通，为用户提供一个开放和包容的综合管理平台，和一个健康繁荣的数字商品市场。',
        '作为一个由Conflux技术社区孵化的生态项目，Tspace团队通过创新技术和平台优势为加密艺术家及收藏者们提供一个优质的数字资产管理平台，建立Conflux链上非同质化的可操作合约标准，为开发者，艺术家、有想法的用户提供一个工具和集成市场。Tspace团队致力于探索和实践普及区块链技术的方法，引导人们走进神奇的加密艺术世界，享受科技给生活带来的改变。',
      ],
    },
  ],
  aboutUs: '关于我们',
}

export const faq = {
  rules: [
    {
      title: 'FAQs',
      descs: [
        'Q1. 什么是NFT？',
        'A. NFT（Non-Fungible Token），一种稀缺性为标准的差异化资产，提供了一种标记原生数字资产所有权的方法，非同质化、不可拆分的特性，可以锚定现实世界总商品，使任何有价值的事物通证化，形成价值互联，信息互通的数字资产世界。',
        'Q2. 平台钱包是什么？',
        'A. Conflux Portal, 用户可通过https://portal.confluxnetwork.org/进行下载。',
        'Q3. 平台支持铸造发行个人NFT吗？',
        'A. 支持，用户可上传自己发行的NFT。',
        'Q4. 平台铸造收取手续费吗？',
        'A. 是的，为保证NFT价值，平台收取5CFX为铸造手续费。',
        'Q5. 如何兑换WCFX？',
        'A. 可通过Conflux Scan上的工具进行兑换：https://confluxscan.io/swap。',
        'Q6. 平台是否支持NFT的转账',
        'A. 是的。',
        'Q7. 平台有几种交易方式？',
        'A. 两种：一口价和英式拍卖。',
        'Q8. 拍卖模式是否有截止时间？',
        'A. 是的，用户根据需求进行时间设置。',
        'Q9. 平台交易手续费是多少？',
        'A. 按照交易额收取2.5%。',
        'Q10. 平台支持其他项目方的NFT吗？',
        'A. 可与我们联系，对接项目方需求。',
      ],
    },
  ],
}
const cn = {
  ...header,
  ...home,
  ...mySpace,
  ...create,
  ...createCollectible,
  ...createSellOrBuy,
  ...createError,
  ...detailModal,
  ...detailPage,
  ...explore,
  ...timeList,
  ...common,
  ...nftCardModal,
  ...card,
  ...infoModal,
  ...footer,
  ...privary,
}
export default cn
