import React, { useEffect, useMemo, useState } from 'react'
import { useRequest, useVirtualList } from 'ahooks'
import { useTranslation } from 'react-i18next'
import SelectList from '@/components/SelectList'
import NoData from '@/components/NoData'
// import { currencyAbi } from '@/vendor/contracts'
// import useGlobalStore from '@/stores/global'
import BidCard from './BidCard'
import HistoryCard from './HistoryCard'
import styles from './style.module.scss'
import { currencyAbi } from '@/vendor/contracts'
// import useGlobalStore from '@/stores/global'
import { compact, sortBy } from 'lodash'
import Moment from 'moment'
import refreshIc from './refresh.png'
import { message } from 'antd'

interface IProps {
  id: string
  listingId: string
  randomNumber: number
  unit: string
  isSell: boolean // 商品是否上架
  isSelf: boolean
  isAuction: boolean
  isNotEnd: boolean
  bidsList: any
  detailInfo: { [key: string]: any }
  needRandom: () => void
}

function List({
  id,
  listingId,
  randomNumber,
  unit,
  isSell,
  isSelf,
  isAuction,
  isNotEnd,
  detailInfo,
  bidsList,
  needRandom,
}: IProps) {
  const { t } = useTranslation()
  const [curTab, setCurtab] = useState('')
  const [listData, setListData] = useState<any>([])
  const isAuctionOptions = [
    {
      label: t('history'),
      value: '2',
    },
  ]

  const isNotAuctionOptions = [
    {
      label: t('currentBids'),
      value: '1',
    },
    {
      label: t('history'),
      value: '2',
    },
  ]

  // 获取历史数据
  const { run: runGetItemTradingHistory } = useRequest(
    API.trading.getItemTradingHistory.getItemTradingHistory,
    {
      manual: true,
      formatResult: (res: any) => {
        const data = (res?.data?.tradingHistory || []).map((item: any) => {
          return {
            type: item?.event,
            time: item?.date,
            from: item?.to,
            ...item,
          }
        })
        return data
      },
    }
  )
  // 获取转移过的历史数据
  const { run: runGetItemTradingHistoryHistory, data: dataGetItemTradingHistoryHistory } =
    useRequest(API.trading.getItemTradingHistoryHistory.getItemTradingHistoryHistory, {
      manual: true,
      formatResult: (res: any) => {
        const data = (res?.data?.tradingHistory || []).map((item: any) => {
          return {
            type: item?.event,
            time: item?.date,
            from: item?.to,
            ...item,
          }
        })
        return data
      },
    })
  // 获取currentBids 数据
  const {
    run: runGetListBids,
    data: dataGetListBids,
    mutate: mutateGetListBids,
  } = useRequest(API.trading.getListBids.getListBids, {
    manual: true,
    formatResult: (res: any) => {
      const data = (res?.data?.bidList || []).map((item: any) => {
        return {
          time: item?.expirationTime,
          from: item?.bidder,
          ...item,
        }
      })
      return data
    },
  })
  const dataFormat = async (dataArr: any[]) => {
    if (!dataArr?.length) return
    const currencyAddress = detailInfo?.listing?.currencyAddress
    const data = await Promise.all(
      dataArr.map(async (item: any) => {
        // 获取对应账户对应代币余额
        const currencyCon =
          window.confluxJS &&
          window.confluxJS.Contract({
            address: currencyAddress,
            abi: currencyAbi,
          })
        const banlance = await currencyCon.balanceOf(item?.bidder || '')
        return {
          ...item,
          banlance: banlance / 1e18,
          time: item?.expirationTime,
          from: item?.bidder,
        }
      })
    )

    const newData = [...data]
    mutateGetListBids(newData)
    return newData
  }

  useEffect(() => {
    if (id && detailInfo) {
      Promise.all([
        runGetItemTradingHistory({
          bodyParams: {
            tokenId: detailInfo?.tokenId,
            contractAddress: detailInfo?.contractAddress,
            count: 0,
            pageSize: 100,
            itemId: id,
          },
        }),
        runGetItemTradingHistoryHistory({
          bodyParams: {
            tokenId: detailInfo?.tokenId,
            contractAddress: detailInfo?.contractAddress,
            count: 0,
            pageSize: 100,
            itemId: id,
          },
        }),
      ]).then((res) => {
        const data = sortBy(compact(res).flat() || [], (o) => {
          return !Moment(o?.operationTime).valueOf()
        })

        setListData(data)
      })
    }
  }, [id, detailInfo])

  useEffect(() => {
    if (!listingId) return

    if (!isSelf) return
    const timer = setInterval(
      () =>
        runGetListBids({
          bodyParams: {
            count: 0,
            pageSize: 100,
            listingId: listingId,
          },
        }),
      10000
    )
    return () => {
      clearInterval(timer)
    }
  }, [listingId])

  useEffect(() => {
    setCurtab(isAuction ? isAuctionOptions[0].value : isNotAuctionOptions[0].value)
  }, [isAuction])

  useEffect(() => {
    if (dataGetListBids) {
      dataFormat(dataGetListBids)
    }
  }, [dataGetListBids])

  const reFresh = async () => {
    if (!listingId) return
    await runGetListBids({
      bodyParams: {
        count: 0,
        pageSize: 100,
        listingId: listingId,
      },
    })
    message.success(t('refresh'))
  }

  const currentData = useMemo(() => {
    switch (curTab) {
      case '1':
        return dataGetListBids || bidsList
      case '2':
        return listData
      default:
        return []
    }
  }, [curTab, dataGetListBids, listData])

  const { containerProps, wrapperProps } = useVirtualList(currentData || [], {
    overscan: 30,
    itemHeight: 80,
  })

  const render = () => {
    return (currentData || []).map((item: any, index: number) => {
      return curTab === '1' ? (
        <BidCard
          key={index}
          obj={item}
          unit={unit}
          detailInfo={detailInfo}
          isNotEnd={isNotEnd}
          needRandom={() => {
            needRandom && needRandom()
          }}
        />
      ) : (
        <HistoryCard key={index} {...item} />
      )
    })
  }

  return (
    <div className={styles.list}>
      <div className={styles.tabLine}>
        {curTab === '1' && (
          <img src={refreshIc} alt="refresh" className={styles.icon} onClick={reFresh} />
        )}
        <SelectList
          options={isAuction ? isAuctionOptions : isNotAuctionOptions}
          value={curTab}
          onSelect={(val) => setCurtab(val)}
        />
      </div>
      {isSell ? (
        <div className={styles.content} {...containerProps}>
          <div {...wrapperProps} className={styles.con}>
            {Array.isArray(currentData) && currentData.length > 0 ? render() : <NoData />}
          </div>
        </div>
      ) : (
        <div className={styles.noData}>
          {/* <p className={styles.txt1}>{t('noData')}</p> */}
          {isSelf ? <p className={styles.txt2}>{t('sellTips')}</p> : null}
        </div>
      )}
    </div>
  )
}

export default List
