import React, { useEffect } from 'react'
import { useToggle } from 'react-use'
import { useTranslation } from 'react-i18next'
import SafeModal from '../SafeModal'
import styles from './style.module.scss'
import uncheckIc from './uncheck.png'
import checkedIc from './checked.png'

interface IProps {
  onCheck: (val: boolean) => void
}
const CheckList = ({ onCheck }: IProps) => {
  const { t } = useTranslation()
  const [check1, toogleCheck1] = useToggle(false)
  const [check2, toogleCheck2] = useToggle(false)
  const [visile, setVisible] = useToggle(false)

  useEffect(() => {
    onCheck(check1 && check2)
  }, [check1, check2])
  return (
    <div className={styles.checkList}>
      <div className={styles.item}>
        <img src={check1 ? checkedIc : uncheckIc} alt="" onClick={toogleCheck1} />
        <p>{t('tips1')}</p>
      </div>
      <div className={styles.item}>
        <img src={check2 ? checkedIc : uncheckIc} alt="" onClick={toogleCheck2} />
        <p>
          {t('tips2')}&nbsp;<span onClick={setVisible}>{t('tips3')}</span>
        </p>
      </div>
      {visile && <SafeModal visible={visile} toggleVisible={setVisible} />}
    </div>
  )
}

export default CheckList
