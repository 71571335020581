import React from 'react'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { getQueryVariable } from '@/utils/common'
import { useHistory } from 'react-router-dom'

export interface CreateHeaderProps {
  miniTitle: string // 次级标题
  title?: string // 大标题
  needPassButton?: boolean // 是否需要跳过按钮
}

function Index({ miniTitle, title = '', needPassButton = false }: CreateHeaderProps) {
  const history = useHistory()
  // 获取collectionId
  const collectionId = getQueryVariable(history, 'collectionId')
  const { t } = useTranslation()
  // 标题版块
  const TitileDiv = () => {
    return (
      <div className={styles.titileDiv}>
        <div className={styles.title}>{title}</div>
        {needPassButton && <PassButton />}
      </div>
    )
  }

  // 跳过按钮
  const PassButton = () => {
    // return <div className={styles.passButton}>Skip for now</div>;
    return (
      <div
        className={styles.passButton}
        onClick={() => history.push(`/my-space?collectionId=${collectionId}&tab=COLLECTIBLES`)}
      >
        {t('createPass')}
      </div>
    )
  }

  return (
    <section className={styles.createHeader}>
      <div className={styles.miniTitle}>{miniTitle}</div>
      <TitileDiv />
    </section>
  )
}

export default React.memo(Index)
