/**
     * @desc 查询首页HotBids
用于首页HotBids
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {
  /** 总数 */
  count?: number
  /** 页大小 */
  pageSize?: number
}

export interface IParams {
  queryParams: IQueryParams
}

const ajax = new Ajax()

export function getHotBids(
  { queryParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/trading/getHotBids',
    method: 'post',
    data: {},
    params: queryParams
  })
}
