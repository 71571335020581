import React, { useEffect } from 'react'
import BaseInput from '@/components/BaseInput'
import Moment from 'moment'
import { useTranslation } from 'react-i18next'
import { DatePicker } from 'antd'
import { endsWith } from 'lodash'
export interface IProps {
  dateSelectValue: string
  value: string
  onChange: (value: string) => void
}

let timer: NodeJS.Timeout

function disabledDate(current: any) {
  // Can not select days before today and today
  const start = Moment().subtract(1, 'days').endOf('day')
  const end = Moment().add(3, 'months').endOf('day')
  return current && (current < start || current > end)
}

function ExpiryTime({ dateSelectValue = '24', value, onChange }: IProps) {
  const { t } = useTranslation()
  useEffect(() => {
    if (dateSelectValue) {
      const time = timeChange(Moment())
      timer = setInterval(() => {
        const expiryTime = time.add(1, 's').format('YYYY-MM-DD HH:mm:ss')
        onChange(expiryTime)
      }, 1000)
    } else {
      if (timer) clearInterval(timer)
      onChange('')
    }
    return () => {
      clearInterval(timer)
    }
  }, [dateSelectValue])

  const timeChange = (moment: Moment.Moment) => {
    return moment.add(Number(dateSelectValue) / 24, 'd')
  }

  const render = () => {
    if (dateSelectValue) {
      return (
        <BaseInput
          text={t('createSellOrBuyBidInput3')}
          readonly="readonly"
          value={value}
          onChange={(value) => onChange(value)}
        />
      )
    } else {
      return (
        <DatePicker
          showTime
          placeholder={t('sellOrByExpiryTime')}
          disabledDate={disabledDate}
          onOk={(value) => {
            const valueFormat = value.format('YYYY-MM-DD HH:mm:ss')
            onChange(valueFormat)
          }}
        />
      )
    }
  }

  return render()
}

export default React.memo(ExpiryTime)
