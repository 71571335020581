/**
     * @desc 铸造NFT
通过合约铸造NFT后，传入item的id，更新item的pending状态，pending=0：非pending，pending=1：pending
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.Base
}

const ajax = new Ajax()

export function mintedNFT(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/item/mintedNFT',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
