import React, { useEffect, useState, useRef, useMemo } from 'react'
import moment from 'moment'
import { useToggle, useFullscreen, useCopyToClipboard } from 'react-use'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Tag from './components/tag'
import Infos from './components/Infos'
import List from './components/List'
import BuyModal from './components/BuyModal'
import Countdown from './components/Countdown'
import CountdownOver from './components/CountDownOver'
import styles from './style.module.scss'
import * as imgs from './images'
import { getQueryVariable } from '@/utils/common'
import { useBoolean, useRequest } from 'ahooks'
import { format } from 'js-conflux-sdk'
import BigNumber from 'bignumber.js'
import { currencyAbi } from '@/vendor/contracts'
import { isValidCfxAddress } from 'js-conflux-sdk/src/util/address'
import { arrowLeftCircle, lock, transfer } from '@/assets/images'
import cs from 'classnames'
import {
  contractInterceptor,
  free_addr_test,
  getContractForEng,
  getContractForFiex,
  jsonToTuple,
  tokenToUsdt,
  feePercentage,
  transfer as transferFun,
} from '@/utils/contract'
import { message, Modal, Tooltip } from 'antd'
import InfoModal from '@/components/InfoModal'
import useGlobalStore from '@/stores/global'
import { Player, LoadingSpinner, ControlBar, PlayToggle } from 'video-react'
import { ExclamationCircleOutlined, LeftCircleTwoTone, RollbackOutlined } from '@ant-design/icons'
import TransferModal from '@/components/NftCard/components/Modal'

function Explore() {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const imgRef = useRef(null)

  const { userAddress, isLogin } = useGlobalStore()
  const [isSell, setIsSell] = useToggle(false) // 商品是否上架
  const [isSelf, setIsSelf] = useToggle(false) // 当前nft是否是自己的
  const [isAuction, setIsAuction] = useToggle(false) // 是否为一口价
  const [hasPrice, setHasPrice] = useToggle(false) // 是否有报价
  const [randomNumber, setRandomNumber] = useState(0) // 是否有报价
  const [bugModalVisible, setBuyModal] = useToggle(false)
  const [detailInfo, setDetailInfo] = useState<any>(null)
  const [showLodding, setShowLodding] = useToggle(false) // loadding显隐
  const [loddingType] = useState<any>('loading') // loadding类型
  const [isNotEnd, setIsNotEnd] = useState<any>(true) // 拍卖是否未结束
  const [canDown, setCanDown] = useState<any>(true) // 是否可以下架
  const id = getQueryVariable(history, 'id')
  const listingStatus = getQueryVariable(history, 'listingStatus')
  const [isFull, toogleFull] = useToggle(false)
  useFullscreen(imgRef, isFull, { onClose: () => toogleFull(false) })
  const [, copyToClipboard] = useCopyToClipboard()
  const [highestPrice, setPrice] = useState('-')
  const [modalState, { setTrue, setFalse }] = useBoolean(false)
  const [inputValue, setInputValue] = useState('')
  const [hideModalVisiable, setHideModalVisiable] = useState(false) //显示隐藏信息栏
  const { confirm } = Modal

  // 获取页面数据(已上架)
  const { run: getOnSaleItem, data: dataGetOnSaleItem } = useRequest<any>(
    API.item.getOnSaleItem.getOnSaleItem,
    {
      manual: true,
    }
  )

  // 获取页面数据(未上架)
  const { run: getNoSaleItem, data: dataGetNoSaleItem } = useRequest<any>(
    API.item.getItem.getItem,
    {
      manual: true,
    }
  )
  // collection详情
  const { data: getCollectionData, run: getCollectionRun } = useRequest<any>(
    API.collection.getCollection.getCollection,
    {
      manual: true,
    }
  )
  // 下架接口
  const { run: cancelListing } = useRequest<any>(API.trading.cancelListing.cancelListing, {
    manual: true,
  })

  // 下架接口
  const { run: runAcceptBid } = useRequest<any>(API.trading.acceptBid.acceptBid, {
    manual: true,
  })

  // 获取currentBids 数据
  const {
    run: runGetListBids,
    data: dataGetListBids,
    mutate: mutateGetListBids,
  } = useRequest(API.trading.getListBids.getListBids, {
    manual: true,
    formatResult: (res: any) => {
      const data = (res?.data?.bidList || []).map((item: any) => {
        return {
          time: item?.expirationTime,
          from: item?.bidder,
          ...item,
        }
      })
      return data
    },
  })
  const dataFormat = async (dataArr: any[]) => {
    if (!dataArr?.length) return
    const currencyAddress = detailInfo?.listing?.currencyAddress
    const data = await Promise.all(
      dataArr.map(async (item: any) => {
        // 获取对应账户对应代币余额
        const currencyCon =
          window.confluxJS &&
          window.confluxJS.Contract({
            address: currencyAddress,
            abi: currencyAbi,
          })
        const banlance = await currencyCon.balanceOf(item?.bidder || '')
        return {
          ...item,
          banlance: banlance / 1e18,
          time: item?.expirationTime,
          from: item?.bidder,
        }
      })
    )

    const newData = [...data]
    mutateGetListBids(newData)
    return newData
  }

  useEffect(() => {
    if (dataGetListBids) {
      dataFormat(dataGetListBids)
    }
  }, [dataGetListBids])

  useEffect(() => {
    listingStatus && listingStatus === '0'
      ? getNoSaleItem({
          bodyParams: {
            id: id,
          },
        })
      : getOnSaleItem({
          bodyParams: {
            id: id,
          },
        }).then((res) => {})
  }, [randomNumber])

  useEffect(() => {
    if (dataGetOnSaleItem) {
      getCollectionRun({
        bodyParams: {
          id: dataGetOnSaleItem?.data?.item?.collectionId,
        },
      })
    }
  }, [dataGetOnSaleItem])

  useEffect(() => {
    handleOffTheShelf()
    isSell && transPrice()
  }, [detailInfo])

  const transPrice = async () => {
    const amount = returnPrice()
    const newPrice = await tokenToUsdt(amount, detailInfo?.listing?.currencyAddress)
    setPrice(newPrice || amount)
  }

  // 页面详情返回值(已上架)
  useEffect(() => {
    if (dataGetOnSaleItem && dataGetOnSaleItem.code === 0) {
      setDetailInfo(dataGetOnSaleItem?.data?.item)
      setIsAuction(dataGetOnSaleItem?.data?.item.listing.method === 'instant')
      setIsSelf(userAddress === dataGetOnSaleItem?.data?.item.owner)
      setHasPrice(dataGetOnSaleItem?.data?.item?.maxBid !== null)
      setIsSell(dataGetOnSaleItem?.data?.item?.listingStatus === '1')
    }
  }, [dataGetOnSaleItem])

  // 页面详情返回值(未上架)
  useEffect(() => {
    if (dataGetNoSaleItem && dataGetNoSaleItem.code === 0) {
      getCollectionRun({
        bodyParams: {
          id: dataGetNoSaleItem?.data?.item?.collectionId,
        },
      })
      setDetailInfo(dataGetNoSaleItem?.data?.item)
      // setIsAuction(dataGetNoSaleItem?.data?.item.listing.method === 'instant')
      setIsSelf(userAddress === dataGetNoSaleItem?.data?.item.owner)
      // setHasPrice(dataGetNoSaleItem?.data?.item?.maxBid)
      setIsSell(dataGetNoSaleItem?.data?.item?.listingStatus === '1')
    }
  }, [dataGetNoSaleItem])

  // 处理下架逻辑
  const handleOffTheShelf = async () => {
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    let sen = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    let thisTime = `${year}-${month}-${day} ${hour}:${min}:${sen}`
    let boo = moment(thisTime).isBefore(detailInfo?.listing?.expirationTime)
    if (detailInfo?.listing?.method === 'bid') {
      let bidList = await runGetListBids({
        bodyParams: {
          count: 0,
          pageSize: 50,
          listingId: detailInfo?.listing?.id,
        },
      })
      if (bidList) {
        let banlanceList = await dataFormat(bidList)
        let obj = banlanceList?.find((item) => item?.banlance >= parseFloat(item?.price))
        // 当前拍卖未到期
        if (boo) {
          setIsNotEnd(boo)
          // 存在有效报价
          if (obj) {
            setCanDown(false)
          }
          // 不存在有效报价
          else {
            setCanDown(true)
          }
        } else {
          if (isSelf) {
            // 拍卖倒计时截止后24小时以内，如果存在有效报价，卖家只能点击成交。如果不存在有效报价，卖家可以点击下架。
            if (
              date.getTime() - new Date(detailInfo?.listing?.expirationTime).getTime() <=
              86400000
            ) {
              // 存在有效报价
              if (obj) {
                setCanDown(false)
              }
              // 不存在有效报价
              else {
                setCanDown(true)
              }
            }
            // 拍卖截止后超过24小时，无论是否存在有效报价，卖家都只能点击下架。
            else {
              setHasPrice(false)
              setCanDown(true)
            }
          } else {
            setIsNotEnd(false)
            setCanDown(false)
          }
        }
      }
    }
  }

  // 返回最高出价
  const returnPrice = () => {
    if (isSell) {
      if (detailInfo?.listing.method === 'instant') {
        return detailInfo?.listing.price
      } else {
        if (detailInfo?.maxBid && detailInfo?.maxBid.price) {
          return detailInfo.maxBid.price
        } else {
          return detailInfo?.listing.minimumBid
        }
      }
    }
  }

  // 下架操作
  const offTheShelfhandle = async () => {
    const isLogin = await contractInterceptor()
    if (isLogin) {
      setShowLodding(true)
      const from = userAddress
      const obj = dataGetOnSaleItem?.data?.item
      const dataCancelListing = await cancelListing({
        bodyParams: {
          id: obj?.listing?.id,
        },
      })
      if (dataCancelListing && dataCancelListing?.code === 0) {
        const timestamp = new Date().getTime()
        const order = isAuction
          ? {
              owner: format.hexAddress(obj?.owner), // 自己的钱包地址
              taker: format.hexAddress('0x0000000000000000000000000000000000000000'), // 购买者的地址，无
              operator: format.hexAddress('0x0000000000000000000000000000000000000000'), // 无
              orderType: '0', // OrderType {CREATE, SALE, OFFER, INVALID_TYPE}
              uid: obj?.listing?.id,
              startPrice: new BigNumber(obj?.listing?.price).times(new BigNumber(1e18)).toFixed(), // 价格
              salt: timestamp, // 随机数，传时间戳
              assetAddress: format.hexAddress(obj?.contractAddress), // 铸造时的地址
              id: obj?.tokenId, // nft的id
              currencyAddress: format.hexAddress(obj?.listing?.currencyAddress),
              feeRecipient: format.hexAddress(free_addr_test),
              feePercentage: feePercentage, // 手续费暂定为千分之一
            }
          : {
              owner: format.hexAddress(obj?.owner), // 自己的钱包地址
              taker: format.hexAddress('0x0000000000000000000000000000000000000000'), // 购买者的地址，无
              operator: format.hexAddress('0x0000000000000000000000000000000000000000'), // 无
              orderType: '0', // OrderType {CREATE, SALE, OFFER, INVALID_TYPE}
              uid: obj?.listing?.id,
              startPrice: new BigNumber(obj?.listing?.minimumBid)
                .times(new BigNumber(1e18))
                .toFixed(), // 价格
              endTime: new Date(obj.listing?.expirationTime).getTime(), // 结束时间
              salt: timestamp, // 随机数，传时间戳
              assetAddress: format.hexAddress(obj?.contractAddress), // 铸造时的地址
              id: obj?.tokenId, // nft的id
              currencyAddress: format.hexAddress(obj?.listing?.currencyAddress),
              feeRecipient: format.hexAddress(free_addr_test),
              feePercentage: feePercentage, // 手续费暂定为千分之一
            }
        if (isAuction) {
          try {
            await getContractForFiex
              .reverseOrder(jsonToTuple(order))
              .sendTransaction({
                from,
              })
              .executed()
            message.success(t('cancelSuc'))
            history.push('/my-space?tab=COLLECTIBLES')
          } catch (error) {
            message.error(error.message)
            history.push('/my-space?tab=COLLECTIBLES')
          }
        } else {
          try {
            await getContractForEng
              .reverseOrder(jsonToTuple(order))
              .sendTransaction({
                from,
              })
              .executed()
            message.success(t('cancelSuc'))
            history.push('/my-space?tab=COLLECTIBLES')
          } catch (error) {
            message.error(error.message)
            history.push('/my-space?tab=COLLECTIBLES')
          }
        }
      }
    }
  }

  // 报价成功，修改随机数用于刷新数据
  const handleComfirm = () => {
    setRandomNumber(Math.random())
  }

  // 接受报价
  const acceptBid = async () => {
    const interc = await contractInterceptor()
    if (interc) {
      try {
        setShowLodding(true)
        let bidList = await runGetListBids({
          bodyParams: {
            count: 0,
            pageSize: 50,
            listingId: detailInfo?.listing?.id,
          },
        })
        if (bidList) {
          let banlanceList = await dataFormat(bidList)
          let obj = banlanceList?.find((item) => item?.banlance >= parseFloat(item?.price))
          if (obj?.id !== banlanceList[0]?.id) {
            confirm({
              title: '当前最高有效报价',
              icon: <ExclamationCircleOutlined />,
              content: `当前最高有效报价为${obj?.price}`,
              onOk: async () => {
                const from = userAddress
                const orderSell = {
                  owner: '0x0000000000000000000000000000000000000000', // 自己的钱包地址
                  taker: format.hexAddress(obj?.bidder), // 购买者的地址，无
                  operator: format.hexAddress(detailInfo?.listing?.operator), // 无
                  orderType: detailInfo?.listing?.orderType, // OrderType {CREATE, SALE, OFFER, INVALID_TYPE}
                  uid: obj?.id,
                  startPrice: new BigNumber(obj?.price).times(new BigNumber(1e18)).toFixed(), // 价格
                  endTime: new Date(detailInfo?.listing?.expirationTime).getTime(), // 结束时间
                  salt: detailInfo?.listing?.timeStamp, // 随机数，传时间戳
                  assetAddress: format.hexAddress(detailInfo?.contractAddress), // 铸造时的地址
                  id: detailInfo?.tokenId, // nft的id
                  currencyAddress: format.hexAddress(detailInfo?.listing?.currencyAddress),
                  feeRecipient: format.hexAddress(free_addr_test),
                  feePercentage: feePercentage, // 手续费暂定为千分之一
                } // 获取签名
                const res = await getContractForEng
                  .fillOrder(
                    Buffer.from(obj?.orderHash.slice(2), 'hex'),
                    jsonToTuple(orderSell),
                    Buffer.from(obj?.signData.slice(2), 'hex')
                  )
                  .sendTransaction({ from })
                  .executed()
                if (res) {
                  const accepRes = await runAcceptBid({
                    bodyParams: {
                      id: obj?.id,
                    },
                  })
                  if (accepRes && accepRes.code === 0) {
                    setShowLodding(false)
                    message.success(t('acceptSuc'))
                    history.push('/my-space?tab=COLLECTIBLES')
                  }
                }
              },
              onCancel() {
                setShowLodding(false)
              },
            })
          } else {
            const from = userAddress
            const orderSell = {
              owner: '0x0000000000000000000000000000000000000000', // 自己的钱包地址
              taker: format.hexAddress(obj?.bidder), // 购买者的地址，无
              operator: format.hexAddress(detailInfo?.listing?.operator), // 无
              orderType: detailInfo?.listing?.orderType, // OrderType {CREATE, SALE, OFFER, INVALID_TYPE}
              uid: obj?.id,
              startPrice: new BigNumber(obj?.price).times(new BigNumber(1e18)).toFixed(), // 价格
              endTime: new Date(detailInfo?.listing?.expirationTime).getTime(), // 结束时间
              salt: detailInfo?.listing?.timeStamp, // 随机数，传时间戳
              assetAddress: format.hexAddress(detailInfo?.contractAddress), // 铸造时的地址
              id: detailInfo?.tokenId, // nft的id
              currencyAddress: format.hexAddress(detailInfo?.listing?.currencyAddress),
              feeRecipient: format.hexAddress(free_addr_test),
              feePercentage: feePercentage, // 手续费暂定为千分之一
            } // 获取签名
            const res = await getContractForEng
              .fillOrder(
                Buffer.from(obj?.orderHash.slice(2), 'hex'),
                jsonToTuple(orderSell),
                Buffer.from(obj?.signData.slice(2), 'hex')
              )
              .sendTransaction({ from })
              .executed()
            if (res) {
              const accepRes = await runAcceptBid({
                bodyParams: {
                  id: obj?.id,
                },
              })
              if (accepRes && accepRes.code === 0) {
                setShowLodding(false)
                message.success(t('acceptSuc'))
                history.push('/my-space?tab=COLLECTIBLES')
              }
            }
          }
        }
      } catch (error) {
        setShowLodding(false)
        message.error(error.message)
      }
    }
  }

  const modalShow = () => {
    setTrue()
  }

  const handleChange = (v: string) => {
    setInputValue(v)
  }

  const handleConfirm = async () => {
    if (listingStatus === '0') {
      // 校验地址是否合法
      if (!isValidCfxAddress(inputValue)) {
        message.error(
          i18n.language === 'en'
            ? 'The address entered is not the Conflux format address'
            : '您输入的地址非Conflux格式地址'
        )
        return
      }
      try {
        setShowLodding(true)
        const res = await transferFun(
          detailInfo?.contractAddress,
          detailInfo?.owner,
          inputValue,
          detailInfo?.tokenId,
          detailInfo?.erc721
        )
        if (res) {
          setShowLodding(false)
          setFalse()
          setInputValue(i18n.language === 'en' ? 'Transfer successful' : '转账成功')
          message.success(i18n.language === 'en' ? 'Transfer successful' : '转账成功')
        }
      } catch (error) {
        message.error(error.message)
        setShowLodding(false)
        setFalse()
      }
    } else {
      message.error('请先下架该的NFT')
    }
  }

  const handleCancel = () => {
    setFalse()
    setInputValue('')
  }

  //  显示隐藏信息
  const showHideInfoModal = () => {
    if (isLogin && userAddress && userAddress === detailInfo?.owner) {
      setHideModalVisiable(true)
    }
    if (isLogin && userAddress && userAddress !== detailInfo?.owner) {
      message.warning(t('detailHideInfoBuy'))
    }
    if (!isLogin) {
      message.error(t('detailHideInfoLogin'))
    }
  }

  const priceFormat = new BigNumber(
    Number(returnPrice() || ''.replace(/^(.*\..{4}).*$/, '$1'))
  ).toFormat()

  // 是渲染正在拍卖时还是已结束时
  const renderCountDown = useMemo(() => {
    if (!dataGetOnSaleItem) return <></>
    const endTime = new Date(dataGetOnSaleItem?.data?.item?.listing?.expirationTime).getTime()
    const now = new Date().getTime()
    const leftTime = endTime - now
    if (leftTime > 0) {
      return <Countdown dataGetOnSaleItem={dataGetOnSaleItem} />
    } else {
      return <CountdownOver leftTime={endTime} />
    }
  }, [dataGetOnSaleItem])

  return (
    <div className={styles.detailPage}>
      <InfoModal type={loddingType} visible={showLodding} onCancel={() => setShowLodding(false)} />
      <div className={cs('page-desc', styles.pageDesc)}>
        <img
          src={arrowLeftCircle}
          onClick={() => {
            history.goBack()
          }}
          className={styles.goBack}
        />
        {t('detailTitile')}{' '}
      </div>
      <div className={styles.titleLine}>
        <Tooltip
          title={` ${detailInfo?.name} #${detailInfo?.tokenId}`}
          overlayStyle={{ whiteSpace: 'nowrap', maxWidth: 920 }}
        >
          <div className="page-title">
            {detailInfo?.name} #{detailInfo?.tokenId}
          </div>
        </Tooltip>

        <Tag
          isVerify={detailInfo?.valid === '1'}
          txt={getCollectionData?.data?.collection?.name || ''}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.imgBox}>
            <div className={styles.operate}>
              <img className={styles.operateItem} src={imgs.IcFull} alt="" onClick={toogleFull} />
              {detailInfo?.listingStatus === '1' && (
                <img
                  className={styles.operateItem}
                  src={imgs.IcShare}
                  alt=""
                  onClick={() => {
                    let str =
                      detailInfo?.listing?.method === 'bid'
                        ? i18n.language === 'en'
                          ? `${window.location.href} ${getCollectionData?.data?.collection?.name}-${detailInfo?.name} #${detailInfo?.tokenId} is available for auction at Tspace～`
                          : `${window.location.href} ${getCollectionData?.data?.collection?.name}-${detailInfo?.name} #${detailInfo?.tokenId} 正在Tspace拍卖中～`
                        : i18n.language === 'en'
                        ? `${window.location.href} ${getCollectionData?.data?.collection?.name}-${detailInfo?.name} #${detailInfo?.tokenId} is now on sale at Tspace～`
                        : `${window.location.href} ${getCollectionData?.data?.collection?.name}-${detailInfo?.name} #${detailInfo?.tokenId} 正在Tspace售卖中～`
                    copyToClipboard(str)
                    message.success(t('copySuc'))
                  }}
                />
              )}
              {detailInfo?.listingStatus === '0' && (
                <img onClick={modalShow} className={styles.operateItem} src={transfer} alt="" />
              )}
            </div>
            <div className={styles.imgCon} ref={imgRef}>
              {detailInfo?.image.substr(detailInfo?.image.lastIndexOf('.') + 1) === 'mp4' ? (
                <Player
                  loop
                  autoPlay
                  className="my-player"
                  poster={detailInfo?.image}
                  src={detailInfo?.image}
                >
                  <LoadingSpinner />
                  <ControlBar autoHide={false}>
                    <PlayToggle />
                  </ControlBar>
                </Player>
              ) : (
                <img className={styles.img} src={detailInfo?.image} alt="" />
              )}
            </div>
          </div>
          <Infos detailInfo={detailInfo} />
        </div>
        <div className={styles.right}>
          <div className={styles.rightItem}>
            {isSell ? (
              <div className={styles.priceInfos}>
                <div className={styles.priceInfosItem}>
                  <label className={styles.label}>
                    {isAuction ? t('curPrice') : hasPrice ? t('highestPrice') : t('miniPrice')}
                    <span className={styles.unit}>{detailInfo?.listing.tokenName}</span>
                  </label>
                  <p className={styles.num}>{priceFormat}</p>
                  <p className={styles.txt}>
                    {detailInfo?.listing.tokenName.toUpperCase().indexOf('USDT') > -1
                      ? `$${priceFormat}`
                      : `$${highestPrice}`}
                  </p>
                </div>
                {!isAuction && renderCountDown}
              </div>
            ) : (
              <div className={styles.notSell}>
                <p>{t('curPrice')}</p>
                <p className={styles.txt1}>{t('notOnSale')}</p>
                <p className={styles.txt2}>$--</p>
              </div>
            )}
          </div>
          {detailInfo && detailInfo?.privateMetaData && (
            <div className={cs(styles.rightItem, styles.hideInfoBox)}>
              <img className={styles.lock} src={lock} alt="" />
              <a
                className={styles.hideInfoLabel}
                href="javascript:void(0)"
                onClick={showHideInfoModal}
              >
                {t('detailHideInfoLabel')}
              </a>
            </div>
          )}

          <div className={styles.rightItem}>
            <List
              id={id}
              listingId={detailInfo?.listing?.id}
              randomNumber={randomNumber}
              unit={detailInfo?.listing?.tokenName}
              isSell={isSell}
              isSelf={isSelf}
              isNotEnd={isNotEnd}
              isAuction={isAuction}
              detailInfo={detailInfo}
              needRandom={() => handleComfirm()}
              bidsList={dataGetListBids}
            />
            {isSelf ? (
              isSell ? (
                <div className={styles.btnLine}>
                  {hasPrice ? (
                    <div
                      className={cs({
                        [styles.btn]: detailInfo?.erc721 !== '3',
                        [styles.btnDisabled]: detailInfo?.erc721 === '3',
                      })}
                      onClick={() => {
                        if (detailInfo?.erc721 === '3') return
                        acceptBid()
                      }}
                    >
                      {t('accept')}
                    </div>
                  ) : null}
                  {canDown && (
                    <div
                      className={cs({
                        [styles.btn]: detailInfo?.erc721 !== '3',
                        [styles.btnDisabled]: detailInfo?.erc721 === '3',
                      })}
                      onClick={() => {
                        if (detailInfo?.erc721 === '3') return
                        offTheShelfhandle()
                      }}
                    >
                      {t('cancelSell')}
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.btnLine}>
                  <div
                    className={cs({
                      [styles.btn]: detailInfo?.erc721 !== '3',
                      [styles.btnDisabled]: detailInfo?.erc721 === '3',
                    })}
                    onClick={() => {
                      if (detailInfo?.erc721 === '3') return
                      history.push(
                        `/sell-or-buy?itemID=${detailInfo?.id}&collectionId=${detailInfo?.collectionId}`
                      )
                    }}
                  >
                    {t('sell')}
                  </div>
                </div>
              )
            ) : isAuction ? (
              <div className={styles.btnLine}>
                <div
                  className={cs({
                    [styles.btn]: detailInfo?.erc721 !== '3',
                    [styles.btnDisabled]: detailInfo?.erc721 === '3',
                  })}
                  onClick={() => {
                    if (detailInfo?.erc721 === '3') return
                    setBuyModal(true)
                  }}
                >
                  {t('bugNow')}
                </div>
              </div>
            ) : (
              isNotEnd && (
                <div className={styles.btnLine}>
                  <div
                    className={cs({
                      [styles.btn]: detailInfo?.erc721 !== '3',
                      [styles.btnDisabled]: detailInfo?.erc721 === '3',
                    })}
                    onClick={() => {
                      if (detailInfo?.erc721 === '3') return
                      setBuyModal(true)
                    }}
                  >
                    {t('bid')}
                  </div>
                </div>
              )
            )}
          </div>
          {detailInfo?.erc721 === '3' && <p className={styles.banCrc721}>{t('detailCRC721')}</p>}
        </div>
      </div>
      {bugModalVisible ? (
        <BuyModal
          record={dataGetOnSaleItem?.data?.item}
          type={isAuction ? 0 : 1}
          onCancel={() => setBuyModal(false)}
          onConfirm={() => handleComfirm()}
        />
      ) : null}
      {detailInfo && (
        <TransferModal
          text={detailInfo?.name}
          owner={detailInfo?.owner}
          imgSrc={detailInfo?.image}
          inputValue={inputValue}
          onCancel={handleCancel}
          onChange={handleChange}
          onConfirm={handleConfirm}
          visible={modalState}
        />
      )}
      {/* 隐藏信息栏 */}
      <Modal
        visible={hideModalVisiable}
        width={552}
        title={t('detailHideInfoModalLabel')}
        footer={null}
        onCancel={() => {
          setHideModalVisiable(false)
        }}
      >
        <div className={styles.hideModalInfoContent}>
          <Player
            loop
            autoPlay
            className="my-player"
            poster={detailInfo?.image}
            src={detailInfo?.privateMetaData?.uri}
          >
            <LoadingSpinner />
            <ControlBar autoHide={false}>
              <PlayToggle />
            </ControlBar>
          </Player>
        </div>
      </Modal>
    </div>
  )
}

export default Explore
