import React from 'react'
import News from './components/News'
import TrendingCollections from './components/TrendingCollections'
import HotBids from './components/HotBids'
import Explore from './components/Explore'
import FindMore from './components/FindMore'
import LastNews from './components/LastNews'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

function Home() {
  const { t } = useTranslation()
  return (
    <>
      <div>
        <News />
        <div className={styles.contentContainer}>
          <TrendingCollections />
          <HotBids />
          <p className={styles.various}>{t('various')}</p>
          <Explore />
          {/* <FindMore /> */}
          <LastNews />
        </div>
      </div>
    </>
  )
}

export default Home
