export class Base {
  /** id */
  id = ''
}

export class BidCreate {
  /** 报价人(已改为从token获取，可以不传) */
  bidder = ''

  /** 过期时间 */
  expirationTime = ''

  /** id */
  id = ''

  /** 挂单ID */
  listingId = ''

  /** 链上HASH */
  orderHash = ''

  /** 报价 */
  price = ''

  /** 时间戳 */
  salt = ''

  /** 买家签名 */
  signData = ''
}

export class Browse {
  /** 分类id，只传一个 */
  category = ''

  /** 作品集id，多个id用英文逗号间隔 */
  collections = ''

  /** 总数 */
  count = undefined

  /** 页大小 */
  pageSize = undefined

  /** 查询内容：NFT标题 */
  search = ''

  /** 排序 */
  sortBy = 'recentList'

  /** 状态名称，只传一个 */
  status = 'buyNow'

  /** 代币名称，只传一个 */
  tokenNames = ''
}

export class ClassificationOfQueryObject {
  /** 总数 */
  count = undefined

  /** 页大小 */
  pageSize = undefined
}

export class ItemLevels {
  /** /第一个值 */
  firstValue = ''

  /** 名称 */
  name = ''

  /** 第二个值 */
  secondValue = ''
}

export class ItemProperties {
  /** 名称 */
  name = ''

  /** 类型 */
  type = ''
}

export class ItemStats {
  /** /第一个值 */
  firstValue = ''

  /** 名称 */
  name = ''

  /** 第二个值 */
  secondValue = ''
}

export class ItemTradingHistoryQuery {
  /** Contract Adress */
  contractAddress = ''

  /** 总数 */
  count = undefined

  /** NFT作品ID */
  itemId = ''

  /** 页大小 */
  pageSize = undefined

  /** Token Id */
  tokenId = ''
}

export class ListingBidQuery {
  /** 总数 */
  count = undefined

  /** Listing ID */
  listingId = ''

  /** 页大小 */
  pageSize = undefined
}

export class ListingCreate {
  /** NFT合约地址 */
  assetAddress = ''

  /** 交易使用的代币的合约地址 */
  currencyAddress = ''

  /** 过期时间 */
  expirationTime = ''

  /** 手续费比例 */
  feePercentage = ''

  /** 收交易手续费的地址 */
  feeRecipient = ''

  /** id */
  id = ''

  /** NFT作品ID */
  itemId = ''

  /** 方式 */
  method = 'instant'

  /** 最低报价 */
  minimumBid = ''

  /** 操作者地址 */
  operator = ''

  /** 订单类型 */
  orderType = ''

  /** 售价 */
  price = ''

  /** 底价 */
  reservePrice = ''

  /** 上架人(已改为从token获取，可以不传) */
  seller = ''

  /** 签名 */
  signData = ''

  /** 买家公钥地址 */
  taker = ''

  /** 时间戳 */
  timeStamp = ''

  /** 代币名称 */
  tokenName = ''
}

export class MyBidQuery {
  /** 报价人(已改为从token获取，可以不传) */
  bidder = ''

  /** 总数 */
  count = undefined

  /** 页大小 */
  pageSize = undefined
}

export class NFTCollectionCreate {
  /** 分类列表 */
  categoryList = []

  /** 描述 */
  description = ''

  /** 主图 */
  featuredImage = ''

  /** 链接 */
  links = ''

  /** 图标 */
  logo = ''

  /** 名称 */
  name = ''

  /** 拥有者(已改为从token获取，可以不传) */
  owner = ''
}

export class NFTCollectionQuery {
  /** 总数 */
  count = undefined

  /** 拥有者(已改为从token获取，可以不传) */
  owner = ''

  /** 页大小 */
  pageSize = undefined
}

export class NFTCollectionUpdate {
  /** 分类列表 */
  categoryList = []

  /** 描述 */
  description = ''

  /** 主图 */
  featuredImage = ''

  /** id */
  id = ''

  /** 链接 */
  links = ''

  /** 图标 */
  logo = ''

  /** 名称 */
  name = ''
}

export class NFTItemCreate {
  /** 作品集id */
  collectionId = ''

  /** 创建者(已改为从token获取，可以不传) */
  creator = ''

  /** 描述 */
  description = ''

  /** 外部链接 */
  externalLink = ''

  /** 图片 */
  image = ''

  /** 级别列表 */
  levels = []

  /** 名称 */
  name = ''

  /** 属性列表 */
  properties = []

  /** 统计列表 */
  stats = []
}

export class NFTItemQuery {
  /** 作品集id */
  collectionId = ''

  /** 总数 */
  count = undefined

  /** 拥有者(已改为从token获取，可以不传) */
  owner = ''

  /** 页大小 */
  pageSize = undefined
}

export class NFTItemUpdate {
  /** 描述 */
  description = ''

  /** 外部链接 */
  externalLink = ''

  /** id */
  id = ''

  /** 图片 */
  image = ''

  /** 级别列表 */
  levels = []

  /** 名称 */
  name = ''

  /** 属性列表 */
  properties = []

  /** 统计列表 */
  stats = []
}

export class Result {
  /** code */
  code = undefined

  /** data */
  data = undefined

  /** msg */
  msg = ''

  /** msg_en */
  msg_en = ''

  /** token */
  token = ''
}

export class UserQuery {
  /** 钱包地址 */
  walletAddress = ''
}

export class UserTradingHistoryQuery {
  /** 总数 */
  count = undefined

  /** 用户钱包地址 */
  owner = ''

  /** 页大小 */
  pageSize = undefined
}

export class UserUpdate {
  /** 背景图片 */
  backImage = ''

  /** 描述 */
  description = ''

  /** 邮箱 */
  email = ''

  /** 头像 */
  headImage = ''

  /** id */
  id = ''

  /** 昵称 */
  username = ''
}
