import React, { useEffect, useState } from 'react'
import { useRequest } from 'ahooks'
import useScroll from '@/hooks/useScroll'
import { useHistory, useLocation } from 'react-router-dom'
import ExploreCard from '@/components/ExploreCard'
import styles from './style.module.scss'
import { Spin } from 'antd'
import { getQueryVariable } from '@/utils/common'
import NoData from '@/components/NoData'
import { decodeQuery } from '@/utils/common'
import { chunk } from 'lodash'
interface ListIProps {
  statusFilter?: any
  collectionsFilter?: string
  type?: string
  sortByValue?: any
  totalChange: (val: any) => void
}

function List({
  statusFilter,
  collectionsFilter = '',
  type = '1',
  sortByValue,
  totalChange,
}: ListIProps) {
  // 获取collectionId

  const { count, setCount } = useScroll({ number: 20, overHeight: 1200 })
  const history = useHistory()
  const search = getQueryVariable(history, 'search')
  const location = useLocation()
  const [listData, setListData] = useState([])
  const [isStop, setIsStop] = useState(false) //是否停止滚动加载
  const {
    loading: showLodding,
    run: browseItemList,
    data: dataBrowseItemList,
  } = useRequest<any>(API.item.browseItemList.browseItemList, {
    manual: true,
    formatResult: (res) => {
      const list = (res?.data?.itemList || []).map((item: any) => {
        return {
          ...item,
          imgSrc: item.image,
          text: `# ${item.name} #${item.tokenId}`,
          highPrice: item.maxBid
            ? item.maxBid.price
            : item.listing?.price
            ? item.listing.price
            : item.listing.minimumBid,
          type: item.image.substr(item.image.lastIndexOf('.') + 1) === 'mp4' ? 'video' : 'image',
        }
      })
      if (res?.data?.itemList) {
        res.data.itemList = list
      }
      return res
    },
  })

  // 滚动加载数据加载
  useEffect(() => {
    if (count === 0) return
    if (isStop) return
    browseItemList({
      bodyParams: {
        category: type,
        collections: collectionsFilter,
        count: count,
        pageSize: 20,
        search: decodeQuery(location.search)?.search || '',
        sortBy: sortByValue,
        status: statusFilter,
      },
    }).then((res) => {
      if (res && res?.code === 0) {
        setListData([...listData, ...res?.data?.itemList])
        totalChange(res?.data?.total)
      }
    })
  }, [statusFilter, type, sortByValue, search, count])

  useEffect(() => {
    if (
      dataBrowseItemList &&
      Array.isArray(dataBrowseItemList?.data?.itemList) &&
      dataBrowseItemList?.data?.itemList.length === 0
    ) {
      setIsStop(true)
    }
    return () => {}
  }, [dataBrowseItemList])

  // 切换页面时候的数据
  useEffect(() => {
    setCount(0)
    setIsStop(false)
    window.scrollTo({
      top: 50,
    })
    browseItemList({
      bodyParams: {
        category: type,
        collections: collectionsFilter,
        count: 0,
        pageSize: 20,
        search: decodeQuery(location.search)?.search || '',
        sortBy: sortByValue,
        status: statusFilter,
      },
    }).then((res) => {
      if (res && res?.code === 0) {
        setListData(res?.data?.itemList)
        totalChange(res?.data?.total)
      }
    })
  }, [statusFilter, type, sortByValue, search, collectionsFilter])

  const render = () => {
    return (chunk(listData, 4) || []).map((item, index) => {
      return (
        <div className={styles.itemContainer} key={index}>
          {item.map((item2) => {
            return <ExploreCard key={item2?.id} {...item2} customStyle={styles.card} />
          })}
        </div>
      )
    })
  }

  return (
    <Spin spinning={showLodding}>
      <div className={styles.list}>
        <div className={styles.con}>{render()}</div>
        {!showLodding && Array.isArray(listData) && listData.length === 0 && (
          <NoData className={styles.listNoData} />
        )}
      </div>
    </Spin>
  )
}

export default List
