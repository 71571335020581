import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Title from '@/components/Title'
import Card from '@/components/Card'
import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'
import { useRequest } from 'ahooks'

function HotBids() {
  const history = useHistory()

  const { data, run } = useRequest<any>(API.trading.getHotBids.getHotBids, {
    manual: true,
  })
  const { t } = useTranslation()

  useEffect(() => {
    run({
      queryParams: {
        pageSize: 4,
        count: 0,
      },
    })
  }, [])

  const pathToDetail = (record: any) => {
    history.push(`/detail?id=${record?.id}`)
  }

  const renderItem = () => {
    return (data?.data?.bidList || []).map((item: any, index: number) => {
      return (
        <Card
          onClick={() => {
            pathToDetail(item)
          }}
          key={index}
          type="hot"
          {...item}
          imgSrc={item?.image}
          text={item?.name}
          price={item?.maxBid?.price || item?.listing?.minimumBid}
          unit={item?.listing?.tokenName}
          newStyles={styles.card}
        />
      )
    })
  }

  return (
    <div className={styles.container}>
      <Title text={t('hotBids')} />
      <div className={styles.box}>{renderItem()}</div>
    </div>
  )
}

export default HotBids
