/**
     * @desc 查询BROWSE页作品集列表
用于BROWSE页左侧collection列表
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  queryParams: IQueryParams
}

const ajax = new Ajax()

export function getBrowseCollectionList(
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/collection/getBrowseCollectionList',
    method: 'post',
    data: {},
    params: {}
  })
}
