import { debounce, throttle } from 'lodash'
import React, { useEffect, useState } from 'react'
// 滚动加载hooks

export interface UseScrollProps {
  number: number //每次加载多少个数量
  overHeight?: number //用于判断离底部多少时候开始滚动加载
}

function useScroll({ number, overHeight = 800 }: UseScrollProps) {
  const [count, setCount] = useState(0)
  useEffect(() => {
    console.log(count)
    window.addEventListener('scroll', scrollPage)
    return () => {
      removeEventListener('scroll', scrollPage)
    }
  }) //TODO:支持任意容易的滚动加载

  const scrollPage = debounce(() => {
    const scrollHeight = document.documentElement.scrollTop
    const viewHeight = document.documentElement.clientHeight
    const contentHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight
    )
    const scrolllBtmHeight = contentHeight - scrollHeight - viewHeight
    const over = scrolllBtmHeight < overHeight

    if (over) {
      setCount(count + number)
    }
  }, 200)

  return { count, setCount }
}
export default useScroll
