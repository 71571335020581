/**
 * 买家bid或一口价购买
 */
import React, { useEffect, useState } from 'react'
import { useToggle } from 'react-use'
import { useTranslation } from 'react-i18next'
import format from 'js-conflux-sdk/src/util/format'
import BigNumber from 'bignumber.js'
import PayModal from '@/components/PayModal'
import BaseInput from '@/components/BaseInput'

import CheckList from '../CheckList'
import styles from './style.module.scss'
import {
  contractInterceptor,
  free_addr_test,
  getContractForFiex,
  jsonToTuple,
  orderSign,
  approvalPay,
  FixedExchangeAddress,
  EnglishExchangeAddrTest,
  getBalance,
  tokenToUsdt,
  feePercentage,
  getBalanceToNumber,
} from '@/utils/contract'
import { useRequest } from 'ahooks'
import InfoModal from '@/components/InfoModal'
import { message } from 'antd'
import ProgressModal from '@/components/ProgressModal'
import useGlobalStore from '@/stores/global'
import { useHistory } from 'react-router-dom'
import { currencyAbi } from '@/vendor/contracts'

interface IProps {
  record?: any
  type: number // 0为一口价购买 1为bid
  onCancel: () => void
  onConfirm: () => void
}

const BuyModal = ({ type, record, onCancel, onConfirm }: IProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { userAddress } = useGlobalStore()
  const [detailModalShow, setDetailModalShow] = useState(false)
  const [price, setPrice] = useState('')
  const [allChecked, setAllChecked] = useToggle(false)
  const [showLodding, setShowLodding] = useState(false) // loadding显隐
  const [orderState, setOrder] = useState(null)
  const [signData, setSignData] = useState(null)
  const [approval, setApproval] = useState(null)
  const [timeStamp, setTimeStamp] = useState(0)
  const unit = record?.listing?.tokenName
  const [isEnough, setIsEnough] = useState(true)
  const [highestPrice, setHighestPrice] = useState('-')
  const [userBalance, setBalance] = useState('-')
  const [userBalanceNumber, setBalanceNumber] = useState(0)
  const [getIds, setGetIds] = useState('')
  const [placeText, setPlaceText] = useState('') // 输入框占位符
  const [effectiveMaxBid, setEffectiveMaxBid] = useState(0) // 当前最高有效报价

  // 获取后端需要的id
  const { run: getId } = useRequest(API.systemToken.getId.getId, {
    manual: true,
  })

  // 购买接口
  const { run: bid } = useRequest(API.trading.bid.bid, {
    manual: true,
  })

  // 获取currentBids 数据
  const { run: runGetListBids, mutate: mutateGetListBids } = useRequest(
    API.trading.getListBids.getListBids,
    {
      manual: true,
      formatResult: (res: any) => {
        const data = (res?.data?.bidList || []).map((item: any) => {
          return {
            time: item?.expirationTime,
            from: item?.bidder,
            ...item,
          }
        })
        return data
      },
    }
  )
  const dataFormat = async (dataArr: any[]) => {
    if (!dataArr?.length) return
    const currencyAddress = record?.listing?.currencyAddress
    const data = await Promise.all(
      dataArr.map(async (item: any) => {
        // 获取对应账户对应代币余额
        const currencyCon =
          window.confluxJS &&
          window.confluxJS.Contract({
            address: currencyAddress,
            abi: currencyAbi,
          })
        const banlance = await currencyCon.balanceOf(item?.bidder || '')
        return {
          ...item,
          banlance: banlance / 1e18,
          time: item?.expirationTime,
          from: item?.bidder,
        }
      })
    )

    const newData = [...data]
    // if (isLogin) {
    //   // 将自己的报价提到第一条
    //   const myOffer = data.find((it: any) => it.bidder === userAddress)
    //   const myOfferIndex = data.findIndex((it: any) => it.bidder === userAddress)
    //   newData.splice(myOfferIndex, 1)
    //   newData.unshift(myOffer)
    // }
    mutateGetListBids(newData)
    return newData
  }

  useEffect(() => {
    transPrice()
    getUserBalance()
  }, [record])

  // 获取价格
  const returnPrice = () => {
    return record?.maxBid ? record?.maxBid?.price : record?.listing?.price
  }
  // cfx转为usdt
  const transPrice = async () => {
    const amount = returnPrice()
    const newPrice = await tokenToUsdt(amount, record?.listing?.currencyAddress)
    setHighestPrice(new BigNumber(newPrice).toFormat() || new BigNumber(amount).toFormat())
  }

  // 获取用户余额
  const getUserBalance = async () => {
    const res = await getBalance(record?.listing?.currencyAddress)
    const resNumber = await getBalanceToNumber(record?.listing?.currencyAddress)
    setBalance(res)
    setBalanceNumber(parseFloat(resNumber))
    returnNewBidList()
  }

  // 一口价出价
  const handleComfirmForFix = async () => {
    const interc = await contractInterceptor()

    if (interc) {
      setShowLodding(true)
      const from = userAddress
      const orderSell = {
        owner: format.hexAddress(record?.owner), // 自己的钱包地址
        taker: record?.listing?.taker
          ? format.hexAddress(record?.listing?.taker)
          : format.hexAddress('0x0000000000000000000000000000000000000000'), // 购买者的地址，无
        operator: format.hexAddress(record?.listing?.operator), // 无
        orderType: record?.listing?.orderType, // OrderType {CREATE, SALE, OFFER, INVALID_TYPE}
        uid: record?.listing?.id,
        startPrice: new BigNumber(record?.listing?.price).times(new BigNumber(1e18)).toFixed(), // 价格
        salt: record?.listing?.timeStamp, // 随机数，传时间戳
        assetAddress: format.hexAddress(record?.contractAddress), // 铸造时的地址
        id: record?.tokenId, // nft的id
        currencyAddress: format.hexAddress(record?.listing?.currencyAddress),
        feeRecipient: format.hexAddress(free_addr_test),
        feePercentage: feePercentage, // 手续费暂定为千分之一
      }

      try {
        const approvalRes = await sendApproval(
          format.hexAddress(record?.listing?.currencyAddress),
          FixedExchangeAddress,
          new BigNumber(record?.listing?.price).times(new BigNumber(1e18)).toFixed(),
          from
        )
        if (typeof approvalRes === 'object') {
          setOrder(orderSell)
          setApproval(approvalRes)
          setShowLodding(false)
          setDetailModalShow(false)
        } else {
          if (approvalRes) {
            setOrder(orderSell)
            setApproval({ boo: true })
            message.success('您已有足额授权')
            setShowLodding(false)
            setDetailModalShow(false)
          } else {
            message.error('请重新授权额度')
            setApproval(null)
            setShowLodding(false)
          }
        }
      } catch (error) {
        console.log(error.message)
        message.error(error.message)
        setShowLodding(false)
        setDetailModalShow(false)
      }
    }
  }

  const handleComfirmForEng = async () => {
    try {
      const interc = await contractInterceptor()
      if (interc) {
        setShowLodding(true)
        let date = new Date(new Date(record?.listing?.expirationTime).getTime() + 86400000)
        let year = date.getFullYear()
        let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
        let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
        let sen = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        const idRes = await getId()
        await setGetIds(String(idRes?.data?.id))
        const timestamp = new Date().getTime()
        const from = userAddress
        const orderSell = {
          owner: '0x0000000000000000000000000000000000000000', // 自己的钱包地址
          taker: format.hexAddress(from), // 购买者的地址，无
          operator: format.hexAddress(record?.listing?.operator), // 无
          orderType: 2, // OrderType {CREATE, SALE, OFFER, INVALID_TYPE}
          uid: String(idRes?.data?.id),
          startPrice: new BigNumber(price).times(new BigNumber(1e18)).toFixed(), // 价格
          endTime: date, // 结束时间
          salt: timestamp, // 随机数，传时间戳
          assetAddress: format.hexAddress(record?.contractAddress), // 铸造时的地址
          id: record?.tokenId, // nft的id
          currencyAddress: format.hexAddress(record?.listing?.currencyAddress),
          feeRecipient: format.hexAddress(free_addr_test),
          feePercentage: feePercentage, // 手续费暂定为千分之一
        }
        setTimeStamp(timestamp)
        // 获取签名
        sign(orderSell, 'EnglishExchange')
      }
    } catch (error) {
      message.error(error.message)
      console.log(error.message)
      return false
    }
  }

  const signSuccess = () => {
    setShowLodding(false)
    message.success(t('bidSuc'))
    window.location.reload()
    // onCancel()
    // onConfirm()
  }

  const sign = (order: any, type: string) => {
    // 获取签名
    const typeData = orderSign(order, type)
    window.confluxJS.provider.sendAsync(
      {
        method: 'cfx_signTypedData_v4',
        params: [window.conflux.selectedAddress, JSON.stringify(typeData)],
        from: window.conflux.selectedAddress,
      },
      async (err: any, res: any) => {
        if (err) {
          console.log(err.message)
          message.error(err.message)
          setShowLodding(false)
        } else {
          setSignData(res)
          setShowLodding(false)
        }
      }
    )
  }

  // 授权
  const sendApproval = (currencyAddress: string, address: string, amount: any, sender: string) => {
    try {
      return approvalPay(currencyAddress, address, amount, sender)
    } catch (error) {
      console.log(error.message)
      message.error(error.message)
      setShowLodding(false)
    }
  }

  const handleFinishEng = async () => {
    setShowLodding(true)
    let date = new Date(new Date(record?.listing?.expirationTime).getTime() + 86400000)
    let year = date.getFullYear()
    let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    let sen = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    const bidRes = await bid({
      bodyParams: {
        expirationTime: String(new Date(`${year}-${month}-${day} ${hour}:${min}:${sen}`).getTime()),
        id: getIds,
        listingId: record?.listing?.id,
        orderHash: record?.listing?.orderHash,
        price: price,
        salt: String(timeStamp),
        signData: signData?.result,
      },
    })
    if (bidRes && bidRes?.code === 0) {
      signSuccess()
    }
  }

  const handleFinishFix = async () => {
    try {
      setShowLodding(true)
      const from = userAddress
      const res = await getContractForFiex
        .fillOrder(jsonToTuple(orderState), Buffer.from(record?.listing?.signData.slice(2), 'hex'))
        .sendTransaction({ from })
        .executed()
      if (res) {
        setShowLodding(false)
        message.success(t('bidSuc'))
        // onCancel()
        // onConfirm()
        history.push('/my-space?tab=COLLECTIBLES')
      }
    } catch (error) {
      setShowLodding(false)
      message.error(error.message)
      console.log(error.message)
    }
  }

  // pay按钮点击事件
  const payModalOnClick = () => {
    if (!isEnough && price > effectiveMaxBid && unit.indexOf('WCFX') > -1) {
      window.open('https://confluxscan.io/swap')
      return
    }
    if (type === 0) {
      if (userBalanceNumber < parseFloat(record.listing.price)) {
        if (unit.indexOf('WCFX') > -1) {
          window.open('https://confluxscan.io/swap')
          return
        } else {
          message.error('您的余额不足')
          return
        }
      }
      if (!approval && !signData) {
        setDetailModalShow(true)
      } else {
        handleFinishFix()
      }
    } else {
      if (!isEnough) {
        message.error('报价不能低于当前最高有效报价')
        return
      }
      if (!record.maxBid && Number(price) < Number(record.listing.minimumBid)) {
        message.error('报价不能低于最低报价')
        return
      }
      if (parseFloat(price) > userBalanceNumber) {
        message.error('您的余额不足')
        return
      }

      if (approval && signData) {
        handleFinishEng()
      } else {
        setDetailModalShow(true)
      }
    }
  }

  // 返回最新的报价列表
  const returnNewBidList = async () => {
    let bidList = await runGetListBids({
      bodyParams: {
        count: 0,
        pageSize: 50,
        listingId: record?.listing?.id,
      },
    })
    if (bidList) {
      // 格式化数据
      let banlanceList = await dataFormat(bidList)
      // 获取最高有效报价
      let obj = banlanceList?.find((item) => item?.banlance >= parseFloat(item?.price))
      setPlaceText(new BigNumber(obj ? obj.price : record?.listing?.minimumBid).toFormat())
      setEffectiveMaxBid(new BigNumber(obj ? obj.price : record?.listing?.minimumBid).toNumber())
    }
  }

  // 按钮文案处理
  const returnButtonText = () => {
    if (record?.listing?.method === 'instant') {
      const price = parseFloat(record?.listing?.price)
      if (userBalanceNumber < price && unit.indexOf('WCFX') > -1) {
        return t('getWcfx')
      }
      return t('pay')
    } else {
      if (isEnough && price) {
        return t('bidModalComfirmButton')
      } else {
        if (!isEnough && price) {
          if (price > effectiveMaxBid && unit.indexOf('WCFX') > -1) {
            return t('getWcfx')
          } else {
            return t('bidModalComfirmButton')
          }
        } else {
          return t('bidModalComfirmButton')
        }
      }
    }
  }

  // 按钮状态处理，使用请【取反】
  const returnButtonStatus = () => {
    if (allChecked) {
      if (record.listing.method === 'instant') {
        if (userBalanceNumber >= parseFloat(record.listing.price)) {
          return true
        } else {
          return unit.indexOf('WCFX') > -1
        }
      } else {
        if (price) {
          if (isEnough) {
            return true
          } else {
            if (price > effectiveMaxBid && unit.indexOf('WCFX') > -1) {
              return unit.indexOf('WCFX') > -1
            } else {
              return false
            }
          }
        } else {
          return false
        }
      }
    } else {
      return false
    }
  }

  return (
    <>
      <PayModal
        title={type ? t('makeBid') : t('confirmOrder')}
        cancelOnClick={onCancel}
        imgUrl={record?.image}
        subTitle={`${record?.name}`}
        miniTitle={`# id ${record?.tokenId}`}
        byAddr={`${record?.owner}`}
        comfirmText={returnButtonText()}
        comfirmOnClick={payModalOnClick}
        comfirmButtonDisable={!returnButtonStatus()}
        type={type}
      >
        <InfoModal
          type="loading"
          visible={showLodding}
          onCancel={() => {
            setShowLodding(false)
          }}
        />
        <div className={styles.item}>
          <label>{type ? t('yourBid') : t('total')}</label>
          {type ? (
            <div className={styles.line}>
              <div className={styles.inputBox}>
                {!price && (
                  <p className={styles.placeholder}>
                    {t('atLeast')} <span className={styles.num}>{placeText || 0}</span>
                  </p>
                )}
                <BaseInput
                  inputType="number"
                  onChange={async (e) => {
                    setPrice(e)
                    // 验价，获取最新的报价列表并进行比对
                    let bidList = await runGetListBids({
                      bodyParams: {
                        count: 0,
                        pageSize: 50,
                        listingId: record?.listing?.id,
                      },
                    })
                    if (bidList) {
                      // 格式化数据
                      let banlanceList = await dataFormat(bidList)
                      // 获取最高有效报价
                      let obj = banlanceList?.find(
                        (item) => item?.banlance >= parseFloat(item?.price)
                      )
                      // 有最高有效报价
                      if (obj) {
                        setIsEnough(
                          parseFloat(e) <= userBalanceNumber &&
                            parseFloat(e) > parseFloat(obj.price)
                        )
                      }
                      // 无最高有效报价或无报价
                      else {
                        setIsEnough(parseFloat(e) <= userBalanceNumber)
                      }
                    }
                  }}
                  inputStyle={styles.input}
                />
              </div>
              <span>{unit}</span>
            </div>
          ) : (
            <div className={styles.total}>
              <span className={styles.txt1}>
                {new BigNumber(returnPrice() || '').toFormat()} {record?.listing?.tokenName}
              </span>
              <span className={styles.txt2}>${new BigNumber(highestPrice).toFormat()}</span>
            </div>
          )}
        </div>
        <div className={styles.item1}>
          <label>{t('yourBalance')}</label>
          <span>
            {userBalance} {unit}
          </span>
        </div>
        <div className={styles.checkList}>
          <CheckList onCheck={(val) => setAllChecked(val)} />
        </div>
      </PayModal>
      {type !== 0 ? (
        <ProgressModal
          onCancel={() => {
            setDetailModalShow(false)
          }}
          onSign={async () => {
            const res = await handleComfirmForEng()
            return !(res === false)
          }}
          onApproval={async () => {
            try {
              setShowLodding(true)
              const from = userAddress
              const approvalRes = await sendApproval(
                record?.listing?.currencyAddress,
                EnglishExchangeAddrTest,
                new BigNumber(price).times(new BigNumber(1e18)).toFixed(),
                from
              )
              if (typeof approvalRes === 'object') {
                setApproval(approvalRes)
                setShowLodding(false)
                setDetailModalShow(false)
              } else {
                if (approvalRes) {
                  setApproval({ boo: true })
                  message.success('您已有足额授权')
                  setShowLodding(false)
                  setDetailModalShow(false)
                } else {
                  message.error('请重新授权额度')
                  setApproval(null)
                  setShowLodding(false)
                  return false
                }
              }
            } catch (error) {
              setShowLodding(false)
              console.log(error.message)
              message.error(error.message)
            }
          }}
          title={t('paySteps')}
          visible={detailModalShow}
        />
      ) : (
        <ProgressModal
          onCancel={() => {
            setDetailModalShow(false)
          }}
          onApproval={async () => {
            handleComfirmForFix()
          }}
          title={t('paySteps')}
          visible={detailModalShow}
        />
      )}
    </>
  )
}

export default BuyModal
