import create from 'zustand'
import { getLocalStorage } from '@/utils/localStorage'

type State = {
  curType: string
  curSortByValue: string
  curStatusFilter: string
  curCollectionsFilter: string
  category: { [key: string]: any }
}

const useExploreStore = create<State>((set) => ({
  curType: '',
  curSortByValue: 'recentList',
  curStatusFilter: 'buyNow',
  curCollectionsFilter: '',
  category: {
    curStatus: '',
    curColl: '',
    curChilColl: null,
    openCo: [null, null],
  },
}))

export default useExploreStore
