/**
     * @desc 查询上架手续费
用于上架时，查询上架的手续费数值和手续费单位
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  queryParams: IQueryParams
}

const ajax = new Ajax()

export function getFee(
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/trading/getFee',
    method: 'post',
    data: {},
    params: {}
  })
}
