import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

export interface IProps {
  leftTime: number
}

function Countdown({ leftTime }: IProps) {
  const [time, setTimeDetail]: any = useState({
    h: 0,
    m: 0,
    s: 0,
  })
  const { t } = useTranslation()

  const overTime = Math.abs(leftTime) + 1000 * 60 * 60 * 24
  useEffect(() => {
    if (leftTime) {
      let now = new Date().getTime()
      const timer = setInterval(() => {
        now = now + 1000
        bidCountdown(now)
      }, 1000)
      return () => {
        clearInterval(timer)
      }
    }
  }, [leftTime])

  // 卖家结束倒计时
  const bidCountdown = (leftTime: number) => {
    leftTime = Math.abs(leftTime)
    const finalTime = overTime - leftTime
    if (finalTime < 0) {
      setTimeDetail({
        h: '-',
        m: '-',
        s: '-',
      })
      return
    }
    const h = Math.floor(finalTime / 1000 / 60 / 60)
    const m = Math.floor((finalTime / 1000 / 60) % 60)
    const s = Math.floor((finalTime / 1000) % 60)
    setTimeDetail({
      h: h < 10 ? `0${h}` : `${h}`,
      m: m < 10 ? `0${m}` : `${m}`,
      s: s < 10 ? `0${s}` : `${s}`,
    })
  }

  return (
    <div className={styles.priceInfosItem}>
      <label className={styles.label}>{t('overEnd')}</label>
      <div className={styles.timeLine}>
        <p className={styles.num}>{time.h}</p>
        <p className={styles.num}>{time.m}</p>
        <p className={styles.num}>{time.s}</p>
      </div>
      <div className={styles.timeLine}>
        <p className={styles.txt}>{t('hours')}</p>
        <p className={styles.txt}>{t('minutes')}</p>
        <p className={styles.txt}>{t('seconds')}</p>
      </div>
    </div>
  )
}

export default React.memo(Countdown)
