/**
     * @desc 删除作品集
collection内有NFT时不允许删除
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.Base
}

const ajax = new Ajax()

export function deleteCollection(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/collection/deleteCollection',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
