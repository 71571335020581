/**
     * @desc 查询已上架NFT作品列表
用于查询用户collection中已上架的NFT列表，包含订单信息和最高报价信息
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.NFTItemQuery
}

const ajax = new Ajax()

export function getOnSaleItemList(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/item/getOnSaleItemList',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
