import React, { useMemo } from 'react'
import { Button, Modal, ModalProps } from 'antd'

import styles from './style.module.scss'
import { loading, vector } from '@/assets/images'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

type IType = 'success' | 'loading' | 'cancel'
export interface IProps extends ModalProps {
  type?: IType
  onConfirm?: () => void
  titleText?: string
  content?: string
  buttonText?: string
  nftId?: string
}

function Index({ nftId, type, onConfirm, titleText, content, buttonText, ...modalProps }: IProps) {
  const history = useHistory()
  const { t } = useTranslation()
  const title = useMemo(() => {
    switch (type) {
      case 'loading':
        return <p className={styles.title}>{t('infoLoading')}</p>
      case 'success':
        return <p className={styles.title}>{t('infoSucceed')}</p>
      case 'cancel':
        return <p className={styles.title}>{titleText ? titleText : t('infoCancel')}</p>
      default:
        return <></>
    }
  }, [type])

  const WaitIfo = (
    <div className={styles.container}>
      <p className={styles.info}>{t('infoLoadingDesc')}</p>
      <img className={styles.img} src={loading} alt="" />
    </div>
  )

  const successInfo = (
    <div className={styles.container}>
      <p className={styles.info}>{t('infoSucceedTrans')}</p>
      <a
        onClick={() => {
          history.push(`/detail?id=${nftId}`)
        }}
        className={styles.desc}
      >
        {t('infoSucceedDesc')}
      </a>
      <img className={styles.imgSuc} src={vector} alt="" />
    </div>
  )

  const cancelInfo = (
    <div className={styles.cancelContainer}>
      <p className={styles.info}> {content ? content : t('infoCancelDesc')}</p>
      <Button onClick={onConfirm as any} className={styles.cancel}>
        {buttonText ? buttonText : t('infoCancelButton')}
      </Button>
    </div>
  )

  const render = useMemo(() => {
    switch (type) {
      case 'loading':
        return WaitIfo
      case 'success':
        return successInfo
      case 'cancel':
        return cancelInfo
      default:
        return <></>
    }
  }, [type])

  return (
    <Modal
      className={styles.infoModal}
      wrapClassName={styles.wrapContainer}
      centered
      width={type === 'cancel' ? 632 : 326}
      footer={false}
      title={title}
      {...modalProps}
    >
      <div className={styles.box}>{render}</div>
    </Modal>
  )
}

export default Index
