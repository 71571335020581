import React from 'react'
import { about as aboutEn } from '@/assets/locales/en-US'
import { about as aboutCn } from '@/assets/locales/zh-CN'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'

const GameRuls = () => {
  const { i18n } = useTranslation()
  const about = i18n.language === 'en' ? aboutEn : aboutCn
  return (
    <div className={styles.gameRules}>
      <section className={styles.grContent}>
        {about.rules.map((item, index) => (
          <div className={styles.rulesItem} key={item.title}>
            <div className={styles.rulesTitle}>{item.title}</div>
            <div className={styles.rulesDec}>
              {item.descs.map((it, ind) => (
                <div
                  className={index === 2 && ind % 2 === 0 ? styles.ques : ''}
                  key={`desc${index}${ind}`}
                >
                  {it}
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>
    </div>
  )
}

export default GameRuls
