enum NetEnv {
  test,
  prod,
}
interface IConfig {
  authKey: string
  baseUrl: string
  cdnUrl: string
  imgUrl: string // 图片前缀
  attrUrl: string // 属性图片前缀
  vedioUrl: string // 视频前缀
  scanUrl: string
  scanAddr: string
  netEnv: NetEnv // 网络环境 test为测试网 prod为正式网
}
const netEnv = NetEnv.prod

const config: IConfig = {
  netEnv,
  baseUrl: netEnv ? 'https://tspace.io/tspace' : 'http://43.129.72.114:8092/tspace',
  authKey: 'Authorization',
  cdnUrl: 'http://cdn.tspace.online/',
  imgUrl: 'http://cdn.tspace.online/image/finish/',
  attrUrl: 'http://cdn.tspace.online/image/',
  vedioUrl: 'https://nft-bucket.oss-cn-hongkong.aliyuncs.com/',
  scanUrl: netEnv
    ? 'https://confluxscan.io/transaction/'
    : 'https://testnet.confluxscan.io/transaction/',
  scanAddr: netEnv
    ? 'https://www.confluxscan.io/address/'
    : 'https://testnet.confluxscan.io/address/',
}
const env = process.env.REACT_APP_CONFIG_ENV || 'prod'
const envConfig = require(`./config.${env}`).default || {}
export default Object.assign({}, config, envConfig) as IConfig
