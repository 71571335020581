/**
     * @desc 获得一个唯一id
调用合约之前，需要产生的id，用该接口产生，该接口产生的id可以实现后端快速排序，合约监听返回的数据中包含该id，用于区分监听返回的数据
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  queryParams: IQueryParams
}

const ajax = new Ajax()

export function getId(
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/system/getId',
    method: 'post',
    data: {},
    params: {}
  })
}
