import React from 'react'
import { Button, notification, message } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import BigNumber from 'bignumber.js'
import useGlobalStore from '@/stores/global'
import { Translation } from 'react-i18next'
import { setLocalStorage } from '@/utils/localStorage'
import { versionStringCompare, initConflux } from '@/utils/common'

/*
 * 合约公共方法
 */
import {
  abi,
  TSArtAddress,
  EnglishExchangeAbi,
  EnglishExchangeAddrTest,
  FixedExchangeAbi,
  FixedExchangeAddress,
  free_addr_test,
  currencyAbi,
  priceAddress,
  priceAbi,
  abi721,
} from '@/vendor/contracts'
import { format } from 'js-conflux-sdk'
import Ajax from '@/utils/request'
import { getToken, IParams } from '@/services/mods/systemToken/getToken'
import { getLocalStorage } from './localStorage'

// contract拦截器
const contractInterceptor = async () => {
  const address = Boolean(getLocalStorage('account'))
  if (!address) {
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={async () => {
          notification.close('contractNo')
          if (!versionStringCompare(window?.confluxJS?.version)) {
            message.warning(
              getLocalStorage('language') === 'en'
                ? `I'm sorry! Your wallet version is too old, please promptly upgrade!`
                : '您的钱包版本过低，请升级'
            )
            return
          }
          const accouts = await window?.conflux?.request({ method: 'cfx_requestAccounts' })
          const param: IParams = {
            bodyParams: {
              walletAddress: accouts[0],
            },
          }
          const res: any = await getToken(param)
          // 存到storage中
          setLocalStorage('account', accouts[0])
          res && setLocalStorage('token', res.token)
          useGlobalStore.setState({
            isLogin: true,
            userAddress: accouts[0],
            token: res?.token,
          })
        }}
      >
        <Translation>{(t) => <> {t('unLock')}</>}</Translation>
      </Button>
    )
    notification.open({
      key: 'contractNo',
      message: <Translation>{(t) => <h1>{t('disconnect')}</h1>}</Translation>,
      description: <Translation>{(t) => <span>{t('approveWallet')}</span>}</Translation>,
      duration: null,
      icon: <InfoCircleOutlined style={{ color: 'red' }} />,
      btn,
    })
    return false
  } else {
    return true
  }
}

// TsArt abi 生成合约
const getContract =
  window.confluxJS &&
  window.confluxJS.Contract({
    address: TSArtAddress,
    abi: abi,
  })

// FixedExchange abi 生成合约
const getContractForFiex =
  window.confluxJS &&
  window.confluxJS.Contract({
    address: FixedExchangeAddress,
    abi: FixedExchangeAbi,
  })

// EnglishExchange abi 生成合约
const getContractForEng =
  window.confluxJS &&
  window.confluxJS.Contract({
    // address: FixedExchangeAddress,
    address: EnglishExchangeAddrTest,
    abi: EnglishExchangeAbi,
  })

// 价格预言机合约
const PriceContract =
  window.confluxJS &&
  window.confluxJS.Contract({
    address: priceAddress,
    abi: priceAbi,
  })

/** 将传入的对象的value抽出来组成一个数组 */
const jsonToTuple = (jsonInput: { [key: string]: any }) => {
  const tupleOutput = []
  for (const key in jsonInput) {
    tupleOutput.push(jsonInput[key])
  }
  return tupleOutput
}

/**
 * owner授权
 * @param sender 发起者钱包地址
 * @param operator 要添加授权的地址
 * @param approved 是否确认授权
 * @param address 被授权地址
 */
const setApprovalForAll = (
  sender: string,
  operator: string,
  approved: boolean,
  address: string
) => {
  const contract =
    window.confluxJS &&
    window.confluxJS.Contract({
      address: address,
      abi,
    })
  return contract
    .setApprovalForAll(format.hexAddress(operator), approved)
    .sendTransaction({
      from: sender,
      gas: 10000000,
    })
    .executed()
}

/**
 * 买家授权代币额度
 * @param currencyAddress 代币地址
 * @param address 被授权地址
 * @param amount 授权额度
 * @param sender 授权地址
 * @param sender 授权地址
 */
const approvalPay = async (
  currencyAddress: string,
  address: string,
  amount: any,
  sender: string
) => {
  // 代币合约
  const currencyCon =
    window.confluxJS &&
    window.confluxJS.Contract({
      address: currencyAddress,
      abi: currencyAbi,
    })
  // 获取已授权额度
  const allowance = await currencyCon.allowance(
    format.hexAddress(sender),
    format.hexAddress(address)
  )
  if (allowance / 1e18 >= amount / 1e18) {
    return true
  } else {
    if (allowance / 1e18 === 0) {
      return currencyCon
        .approve(
          format.hexAddress(address),
          new BigNumber(100000000).times(new BigNumber(1e18)).toFixed()
        )
        .sendTransaction({ from: sender, gas: 10000000 })
        .executed()
    } else {
      await currencyCon
        .approve(format.hexAddress(address), new BigNumber(0).times(new BigNumber(1e18)).toFixed())
        .sendTransaction({ from: sender, gas: 10000000 })
        .executed()
      return false
    }
  }
}

/**
 * 签名typaData处理
 * @param orderData order格式入参
 * @param type 1：EnglishExchange, 2：FixedExchange
 */
const orderSign = (orderData: { [key: string]: any }, type: string) => {
  const domain = [
    { name: 'name', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'chainId', type: 'uint256' },
    { name: 'verifyingContract', type: 'address' },
  ]
  const order =
    type === 'EnglishExchange'
      ? [
          { name: 'owner', type: 'address' },
          { name: 'taker', type: 'address' },
          { name: 'operator', type: 'address' },
          { name: 'orderType', type: 'uint256' },
          { name: 'uid', type: 'uint256' },
          { name: 'startPrice', type: 'uint256' },
          { name: 'endTime', type: 'uint256' },
          { name: 'salt', type: 'uint256' },
          { name: 'assetAddress', type: 'address' },
          { name: 'id', type: 'uint256' },
          { name: 'currencyAddress', type: 'address' },
          { name: 'feeRecipient', type: 'address' },
          { name: 'feePercentage', type: 'uint256' },
        ]
      : [
          { name: 'owner', type: 'address' },
          { name: 'taker', type: 'address' },
          { name: 'operator', type: 'address' },
          { name: 'orderType', type: 'uint256' },
          { name: 'uid', type: 'uint256' },
          { name: 'startPrice', type: 'uint256' },
          { name: 'salt', type: 'uint256' },
          { name: 'assetAddress', type: 'address' },
          { name: 'id', type: 'uint256' },
          { name: 'currencyAddress', type: 'address' },
          { name: 'feeRecipient', type: 'address' },
          { name: 'feePercentage', type: 'uint256' },
        ]
  const orderTemplate = {
    types: {
      EIP712Domain: domain,
      Order: order,
    },
    primaryType: 'Order',
    domain: {
      name: 'ConfiExchange',
      version: '1.0',
      chainId: window.conflux.chainId,
      verifyingContract:
        type === 'EnglishExchange' ? EnglishExchangeAddrTest : FixedExchangeAddress,
    },
    message: orderData,
  }
  return orderTemplate
}

/**
 * 其他币种转usdt
 * @param {(number | string)} amount
 * @param {string} currencyAddress
 * @return {*}
 */
const tokenToUsdt = async (amount: number | string, currencyAddress: string) => {
  if (!amount) return '-'
  const res = await PriceContract.tokenToUsdtPrice(
    new BigNumber(amount).times(1e6).toString(),
    format.hexAddress(currencyAddress)
  )
  return res ? new BigNumber(new BigNumber(res).div(1e6).toFixed(2)).toFormat() : '-'
}

/**
 * 获取非主网币余额
 * @param {string} currencyAddress 代币合约地址
 */
const getBalance = async (currencyAddress: string) => {
  const { isLogin, userAddress } = useGlobalStore.getState()
  if (!isLogin) return '-'
  // 代币合约
  const currencyCon =
    window.conflux &&
    window.confluxJS.Contract({
      address: currencyAddress,
      abi: currencyAbi,
    })
  const res = await currencyCon.balanceOf(userAddress)
  return res ? new BigNumber(new BigNumber(res).div(1e18).toFixed(2)).toFormat() : '-'
}

/**
 * 获取非主网币余额(返回数字)
 * @param {string} currencyAddress 代币合约地址
 */
const getBalanceToNumber = async (currencyAddress: string) => {
  const { isLogin, userAddress } = useGlobalStore.getState()
  if (!isLogin) return '-'
  // 代币合约
  const currencyCon =
    window.conflux &&
    window.confluxJS.Contract({
      address: currencyAddress,
      abi: currencyAbi,
    })
  const res = await currencyCon.balanceOf(userAddress)
  return res ? new BigNumber(new BigNumber(res).div(1e18).toFixed(2)).toNumber() : 0
}

/**
 * 转让nft
 * @param assetAddress 合约地址
 * @param sender 转让者address
 * @param recipient 接收者address
 * @param confiId nftID
 * @param nftType 合约类型
 */
const transfer = (
  assetAddress: string,
  sender: string,
  recipient: string,
  confiId: string,
  nftType: string
) => {
  if ('3' != nftType) {
    const contract =
      window.conflux &&
      window.confluxJS.Contract({
        address: assetAddress,
        abi: abi,
      })
    return contract
      .safeTransferFrom(
        format.hexAddress(sender),
        format.hexAddress(recipient),
        confiId,
        1,
        Buffer.from('', 'hex')
      )
      .sendTransaction({ from: format.hexAddress(sender) })
      .confirmed()
  }

  if ('3' === nftType) {
    const contract =
      window.conflux &&
      window.confluxJS.Contract({
        address: assetAddress,
        abi: abi721,
      })

    return contract
      .safeTransferFrom(format.hexAddress(sender), format.hexAddress(recipient), confiId)
      .sendTransaction({ from: format.hexAddress(sender) })
      .confirmed()
  }
}

// 手续费
const feePercentage = new BigNumber(0.025).times(new BigNumber(1e18)).toFixed()

// 判断是否已经授权
async function isApprovedForThis(account: string, operator: string, nftAddress: string) {
  const contract = window.confluxJS && window.confluxJS.Contract({ address: nftAddress, abi: abi })
  var result = await contract.isApprovedForAll(account, operator)
  console.log('shou quan', result)
  return result
}

export {
  contractInterceptor,
  free_addr_test,
  TSArtAddress,
  getContract,
  orderSign,
  getContractForFiex,
  jsonToTuple,
  getContractForEng,
  EnglishExchangeAddrTest,
  FixedExchangeAddress,
  setApprovalForAll,
  approvalPay,
  PriceContract,
  tokenToUsdt,
  getBalance,
  getBalanceToNumber,
  transfer,
  feePercentage,
  isApprovedForThis,
}
