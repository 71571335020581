import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NftCard from '@/components/NftCard'
import useRequest from '@ahooksjs/use-request'

import Category from './components/Category'
import useGlobalStore from '@/stores/global'
import NoData from '@/components/NoData'
import { Spin, notification } from 'antd'
import styles from './style.module.scss'
import { refreshIcon } from '@/assets/images'
import { useTranslation } from 'react-i18next'

function Index() {
  const { userAddress, isLogin, token } = useGlobalStore()
  const history = useHistory()
  const { t } = useTranslation()

  const [tab, setTab] = useState<string | null>(null)
  const [data, setData] = useState<any>(null)
  // 查询是否需要刷新我的nft
  const { run: selectNewDataFlagRun, data: selectNewDataFlagData } = useRequest<any>(
    API.user.selectNewDataFlag.selectNewDataFlag,
    {
      manual: true,
    }
  )
  // 刷新我的nft
  const { run: refreshRun, loading: refreshLoading } = useRequest<any>(
    API.item.refreshMyNFT.refreshMyNFT,
    {
      manual: true,
    }
  )
  //  查询未上架NFT作品列表
  const { loading: getNotOnSaleItemListLoading, run: selectNotOnSaleAndMintedItemListRun } =
    useRequest<any>(API.item.selectNotOnSaleAndMintedItemList.selectNotOnSaleAndMintedItemList, {
      manual: true,
    })
  // 查询已上架NFT作品列表
  const { loading: getOnSaleItemListLoading, run: getOnSaleItemListRun } = useRequest<any>(
    API.item.getOnSaleItemList.getOnSaleItemList,
    {
      manual: true,
      formatResult: (res) => {
        const list = (res?.data?.itemList || []).map((item: any) => {
          return {
            ...item,
            imgSrc: item.image,
            text: `# ${item.name} #${item.tokenId}`,
            price: item?.listing?.method === 'instant' ? item?.listing?.price : null,
            highPrice:
              item?.listing?.method === 'bid'
                ? item?.maxBid
                  ? item?.maxBid?.price
                  : item?.listing?.minimumBid
                : null,
            type: item.image.substr(item.image.lastIndexOf('.') + 1) === 'mp4' ? 'video' : 'image',
          }
        })
        if (res?.data?.itemList) {
          res.data.itemList = list
        }
        return res
      },
    }
  )
  // 查询左侧collections列表
  const { data: getCollectionListData, run: getCollectionListRun } = useRequest<any>(
    API.collection.getCollectionListWithReference.getCollectionListWithReference,
    {
      manual: true,
      formatResult: (res) => {
        const collectionList = (res?.data?.collectionList || []).map((item: any) => {
          return {
            ...item,
            imgSrc: item.featuredImage,
            text: item.name,
          }
        })

        if (res?.data?.collectionList) {
          res.data.collectionList = [
            {
              text: 'ALL',
              name: 'ALL',
              imgSrc: '',
              id: '',
            },
            ...collectionList,
          ]
        }

        return res
      },
    }
  )
  useEffect(() => {
    if (!isLogin) return
    getCollectionListRun({
      bodyParams: {
        count: 0,
        owner: userAddress,
        pageSize: 1000,
      },
    }).then((res) => {
      setTab(res?.data?.collectionList[0]?.id)
    })
  }, [token, isLogin, userAddress])

  useEffect(() => {
    if (!isLogin) return
    if (tab === null) return
    getAllData(tab)
  }, [tab, isLogin])

  // 查询到需要更新nfts
  useEffect(() => {
    if (!selectNewDataFlagData) return
    if (selectNewDataFlagData?.data?.newDataFlag === 1) {
      notification['error']({
        message: t('mySpaceNftsMessage'),
        description: t('mySpaceNftsDesc'),
        duration: 10,
      })
    }
  }, [selectNewDataFlagData])

  // 查询是否需要更新我的nfts
  useEffect(() => {
    const timer = setInterval(() => {
      if (!isLogin) return
      selectNewDataFlagRun()
    }, 10000)
    return () => {
      clearInterval(timer)
    }
  })

  const getAllData = (tab: string) => {
    Promise.all([
      getOnSaleItemListRun({
        bodyParams: {
          collectionId: tab,
          owner: userAddress,
          count: 0,
          pageSize: 1000,
        },
      }),
      selectNotOnSaleAndMintedItemListRun({
        bodyParams: {
          collectionId: tab || '',
          owner: userAddress,
          count: 0,
          pageSize: 1000,
        },
      }),
    ]).then((resArr) => {
      const newData = (resArr || []).map((item) => {
        return item?.data?.itemList
      })
      // const flatData = newData.flat()
      setData(newData)
    })
  }

  // 上架的作品集
  const renderItem = () => {
    if (
      !getOnSaleItemListLoading &&
      Array.isArray(data) &&
      Array.isArray(data[0]) &&
      data[0].length === 0
    ) {
      return <></>
    } else {
      return ((data && data[0]) || []).map((item: any) => {
        return (
          <NftCard
            {...item}
            key={item?.id}
            imgSrc={item?.image}
            owner={item?.owner}
            text={item?.name}
            onClick={() => {
              history.push(`/detail?id=${item.id}&listingStatus=${item.listingStatus}`)
            }}
          />
        )
      })
    }
  }

  // 未上架的作品集
  const renderNotOnSellItem = () => {
    if (
      !getNotOnSaleItemListLoading &&
      Array.isArray(data) &&
      Array.isArray(data[1]) &&
      data[1].length === 0
    ) {
      return <></>
    } else {
      return ((data && data[1]) || []).map((item: any, index: number) => {
        console.log(item)
        return (
          <NftCard
            {...item}
            key={item?.id}
            imgSrc={item?.image}
            owner={item?.owner}
            text={item?.name}
            cardType="push"
            onClick={() => {
              history.push(`/detail?id=${item.id}&listingStatus=${item.listingStatus}`)
            }}
          />
        )
      })
    }
  }

  const categoryChange = (v: string) => {
    setTab(v)
    window.scrollTo({
      top: 70,
    })
  }

  // 处理刷新
  const handleRefresh = () => {
    refreshRun().then(() => {
      getAllData('')
      setTab('')
    })
  }
  // 是否渲染divider
  const showSell = data && Array.isArray(data[0]) && data[0].length > 0
  const showNotSell = data && Array.isArray(data[1]) && data[1].length > 0
  const isShowDivider = useMemo(() => {
    let isShow = false

    if (data) {
      if (showSell && showNotSell) isShow = true
      else {
        isShow = false
      }
    }
    return isShow
  }, [data])

  return (
    <div className={styles.container}>
      <div className={styles.refresh} onClick={handleRefresh}>
        <img src={refreshIcon} alt="" />
        <span className={styles.refreshName}>{t('mySpaceRefresh')}</span>
      </div>
      <Category
        tab={tab}
        data={getCollectionListData?.data?.collectionList || []}
        onChange={categoryChange}
      />
      <Spin spinning={getOnSaleItemListLoading || refreshLoading || getNotOnSaleItemListLoading}>
        <div className={styles.content}>
          {showSell && <div className={styles.cards}>{renderItem()}</div>}
          {isShowDivider && <p className={styles.divier} />}
          {showNotSell && (
            <div className={styles.cards}>
              {/* TODO 暂时不打开创建，只有认证过的用户才能使用 */}
              {/* <NftCard
                type="image"
                cardType="create"
                onClick={() => {
                  history.push(`/create-collectible`)
                }}
              /> */}
              {renderNotOnSellItem()}
            </div>
          )}
          {!getOnSaleItemListLoading &&
            !refreshLoading &&
            !getNotOnSaleItemListLoading &&
            !showNotSell &&
            !showSell && <NoData className={styles.noData} />}
        </div>
      </Spin>
    </div>
  )
}

export default Index
