/**
     * @desc 上架NFT
通过合约上架NFT后，将上架信息保存到后端
id：通过/system/getId获得，用于监听合约，数据唯一标识
tokenName：通过/paymentTokens/getPaymentTokenList接口获取其中一个name
method=instant时，price传一口价，minimumBid传空字符串，reservePrice传空字符串
method=bid时，price传空字符串，expirationTime传过期时间
seller：钱包地址
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.ListingCreate
}

const ajax = new Ajax()

export function postListing(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/trading/postListing',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
