import Title from '@/components/Title'
import React from 'react'
import NewsCard from '@/components/NewsCard'
import { lastNews1, lastNews2 } from '@/assets/images'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

function LastNews() {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t('lastNews')}</p>
      <div className={styles.content}>
        <NewsCard
          imgSrc={lastNews1}
          text={t('lastNewsOne')}
          link="https://www.odaily.com/post/5162097"
        />
        <NewsCard
          imgSrc={lastNews2}
          text={t('lastNewsTwo')}
          link="https://mp.weixin.qq.com/s/tOmq3RR6zchNWeCLRva4gg"
        />
      </div>
    </div>
  )
}

export default LastNews
