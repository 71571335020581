/* eslint-disable */
const configs = require('@/config')
const isTest = configs.default.netEnv === 1

const { Conflux } = require('js-conflux-sdk')
const contractFile = require('./contract_address.json')
const abi = isTest ? require('./TsArt.json').abi : require('./TspaceCreate_prod.json').abi
const TSArtAddress = isTest ? contractFile.TSArt_addr_test : contractFile.TSArt_addr
const EnglishExchangeAbi = require('./EnglishExchange_prod.json').abi
const EnglishExchangeAddrTest = isTest
  ? contractFile.EnglishExchange_addr_test
  : contractFile.EnglishExchange_addr
const FixedExchangeAbi = require('./FixedExchangeV2_prod.json').abi
const FixedExchangeAddress = isTest
  ? contractFile.FixedExchange_addr_test
  : contractFile.FixedExchange_addr
const free_addr_test = isTest ? contractFile.free_addr_test : contractFile.free_addr
const currencyAbi = require('./currency.json').abi
const priceAddress = isTest
  ? contractFile.price_forecasting_addr_test
  : contractFile.price_forecasting_addr // 价格预言机合约
const priceAbi = require('./PriceOracle.json').abi
const abi721 = require('./721.json')
export {
  abi,
  abi721,
  EnglishExchangeAbi,
  FixedExchangeAbi,
  TSArtAddress,
  EnglishExchangeAddrTest,
  FixedExchangeAddress,
  free_addr_test,
  currencyAbi,
  priceAddress,
  priceAbi,
}
export default Conflux
