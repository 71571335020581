import React, { useEffect } from 'react'
import { HashRouter as Router, Route, Switch, useLocation } from 'react-router-dom'
import { Conflux } from 'js-conflux-sdk'
import routes from '@/routes'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import styles from './style.module.scss'
import { useRequest } from 'ahooks'
import { useTranslation } from 'react-i18next'
import { Button, notification, message } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import useGlobalStore from '@/stores/global'
import { removeLocalStorage, setLocalStorage } from '@/utils/localStorage'
import { versionStringCompare, initConflux } from '@/utils/common'

function renderRoute(routes: any) {
  return routes.map((item: any) => {
    if (Array.isArray(item?.routes) && item.routes.length > 0) {
      const { routes } = item
      return renderRoute(routes)
    }
    return (
      <Route key={item.path} exact={item?.exact} path={item.path}>
        {item.component}
      </Route>
    )
  })
}

function Layout() {
  const { t } = useTranslation()
  const location = useLocation()
  const { isLogin } = useGlobalStore()
  const { run: runToken, data: dataToken } = useRequest(API.systemToken.getToken.getToken, {
    manual: true,
  })

  /**
   *  链接钱包
   *  open 是否默认打开钱包官网
   */
  const connectPortal = async (open: boolean = true) => {
    if (typeof window.conflux === 'undefined' && open) {
      // 用户未安装portal,跳转到下载引导页
      window.open('http://portal.conflux-chain.org/')
      return
    }
    if (!isLogin) {
      if (!versionStringCompare(window?.confluxJS?.version)) {
        message.warning(t('walletVersion'))
        return
      }
      // 用户已经安装portal
      const accouts = await window?.conflux?.request({ method: 'cfx_requestAccounts' })
      if (accouts.length > 0) {
        login(accouts[0])
      }
    }
  }

  const login = async (account: string) => {
    // 获取token
    const res: any = await runToken({
      bodyParams: {
        walletAddress: account,
      },
    })
    // 存到storage中
    setLocalStorage('account', account)
    res && setLocalStorage('token', res.token)
    useGlobalStore.setState({
      isLogin: true,
      userAddress: account,
      token: res?.token,
    })
  }

  useEffect(() => {
    // 用户未登陆主动发起登陆调用
    connectPortal(false)
    // 创建钱包监听
    window?.conflux?.on('accountsChanged', (addrList: any) => {
      if (addrList.length === 0) {
        // 链接断开清除缓存数据
        removeLocalStorage('account')
        removeLocalStorage('token')
        useGlobalStore.setState({
          isLogin: false,
          userAddress: '',
          token: '',
        })
        notification.open({
          key: 'layoutNo',
          message: t('disconnect'),
          description: t('approveWallet'),
          duration: null,
          icon: <InfoCircleOutlined style={{ color: 'red' }} />,
          btn: (
            <Button
              type="primary"
              size="small"
              onClick={async () => {
                if (!versionStringCompare(window?.confluxJS?.version)) {
                  message.warning(t('walletVersion'))
                  return
                }
                const accouts = await window?.conflux?.request({ method: 'cfx_requestAccounts' })
                notification.close('layoutNo')
                login(accouts[0])
              }}
            >
              {t('unLock')}
            </Button>
          ),
        })
      } else {
        // window.location.reload()
      }
    })
    // 网络切换监听
    window?.conflux?.on('chainChanged', (chainId: string) => {
      console.log('chainChanged', chainId)
      initConflux()
      // window.location.reload()
    })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <>
      <Header />
      <div className={styles.layout}>
        <div className={styles.pageCon}>
          <Router>
            <Switch>{renderRoute(routes)}</Switch>
          </Router>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Layout
