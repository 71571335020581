/**
     * @desc 上传图片
所有图片上传接口，返回图片在线地址
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  queryParams: IQueryParams
}

const ajax = new Ajax()

export function uploadFile(
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/files/uploadFile',
    method: 'post',
    data: {},
    params: {}
  })
}
