import React, { useState } from 'react'
import cs from 'classnames'
import { useTranslation } from 'react-i18next'
import SelectList from '@/components/SelectList'
import { getAvatar } from '@/utils/common'

import styles from './style.module.scss'

export interface InfoProps {
  detailInfo: any
}

function Infos({ detailInfo }: InfoProps) {
  const { t } = useTranslation()
  const options = [
    {
      label: t('info'),
      value: '1',
    },
    {
      label: t('chainInfo'),
      value: '2',
    },
    {
      label: t('properties'),
      value: '3',
    },
  ]
  const [curTab, setTab] = useState(options[0].value)

  const renderInfo = () => {
    const configs = require('@/config')
    switch (curTab) {
      case '1':
        return <span className={styles.infoTxt}>{detailInfo?.description}</span>
      case '2':
        return (
          <div className={styles.chainInfo}>
            <div className={styles.chainInfoItem}>
              <label className={styles.label}>{t('contractAddress')}</label>
              <span
                className={cs(styles.txt, styles.address)}
                onClick={() =>
                  window.open(`${configs.default.scanAddr}${detailInfo?.contractAddress}`)
                }
              >
                {detailInfo?.contractAddress}
              </span>
            </div>
            <div className={styles.chainInfoItem}>
              <label className={styles.label}>{t('tokenId')}</label>
              <span className={styles.txt}>{detailInfo?.tokenId}</span>
            </div>
            <div className={styles.chainInfoItem}>
              <label className={styles.label}>{t('blockchain')}</label>
              <span className={styles.txt}>{detailInfo?.blockchain}</span>
            </div>
          </div>
        )
      default:
        return (
          <div className={styles.attrs}>
            {detailInfo?.properties.map((item: any, index: number) => {
              return (
                <span key={index} className={styles.attrsItem}>
                  {item.name}
                </span>
              )
            })}
          </div>
        )
    }
  }
  return (
    <div className={styles.infos}>
      <div className={styles.infosItem}>
        <div className={styles.tabs}>
          <SelectList value="1" onSelect={(val: string) => setTab(val)} options={options} />
        </div>
        {renderInfo()}
      </div>
      <div className={styles.infosItem}>
        <div className={styles.owner}>
          <img src={getAvatar(detailInfo?.owner)} className={styles.avatar} alt="" />
          <div className={styles.txtBox}>
            <span>{detailInfo?.owner}</span>
            <span>{t('owner')}</span>
          </div>
        </div>
      </div>
      <div className={styles.infosItem}>
        <div className={styles.owner}>
          <img src={getAvatar(detailInfo?.creator)} className={styles.avatar} alt="" />
          <div className={styles.txtBox}>
            <span>{detailInfo?.creator}</span>
            <span>{t('creator')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Infos
