/**
     * @desc 创建作品集
categoryList：分类对象数组，参数只要id
paymentTokensList：代币对象数据，参数只要id
featuredImage地址=图片上传接口返回地址
logo地址=featuredImage地址+图片缩放参数
图片缩放参数参考：https://cloud.tencent.com/document/product/1246/45375
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.NFTCollectionCreate
}

const ajax = new Ajax()

export function createCollection(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/collection/createCollection',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
