import React, { useEffect, useState } from 'react'
import { useRequest } from 'ahooks'
import MySpaceHistoryItem from '@/components/MySpaceHistoryItem'

import styles from './style.module.scss'
import useGlobalStore from '@/stores/global'
import NoData from '@/components/NoData'
import useScroll from '@/hooks/useScroll'
import { Spin } from 'antd'

function HistoryArea() {
  const { userAddress, isLogin, token } = useGlobalStore()
  const { count } = useScroll({ number: 20, overHeight: 1200 })
  const { loading, data, run } = useRequest<any>(
    API.trading.getUserTradingHistory.getUserTradingHistory,
    {
      manual: true,
      formatResult: (res) => {
        const data = (res?.data?.userTradingHistory || []).map((item: any) => {
          return {
            type: item?.event,
            time: item?.operationTime,
            user: item?.from,
            name: item?.item?.name,
            imgSrc: item?.item?.image,
            number: item?.price,
            unit: item?.unit,
            ...item,
          }
        })
        return data
      },
    }
  )
  const [listData, setListData] = useState<any>([])
  //  初始化
  useEffect(() => {
    if (!isLogin) return
    run({
      bodyParams: {
        pageSize: 20,
        count: 0,
        owner: userAddress,
      },
    })
  }, [token, isLogin])

  // 滚动加载
  useEffect(() => {
    if (!data) return
    if (Array.isArray(data) && data.length === 0) return
    run({
      bodyParams: {
        pageSize: 20,
        count: count,
        owner: userAddress,
      },
    })
    return () => {}
  }, [count])

  // 所有的list
  useEffect(() => {
    if (!data) return
    setListData([...listData, ...data])
    return () => {}
  }, [data])

  const render = () => {
    if (Array.isArray(listData) && listData.length === 0) {
    }
    return (listData || []).map((item: any, index: number) => {
      return <MySpaceHistoryItem key={index} {...item} />
    })
  }

  return (
    <Spin spinning={loading}>
      <div className={styles.container}>
        <div>{render()}</div>
        {!loading && Array.isArray(listData) && listData.length === 0 && (
          <NoData className={styles.noData} />
        )}
      </div>
    </Spin>
  )
}

export default HistoryArea
