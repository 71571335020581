/**
     * @desc 查询代币列表
用于：browse页，查询出所有代币信息列表
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.ClassificationOfQueryObject
}

const ajax = new Ajax()

export function getPaymentTokensList(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/paymentTokens/getPaymentTokensList',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
