/**
     * @desc 查询作品集列表，只有用户自己创建的collection
查询作品集列表，只有用户自己创建的collection
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.NFTCollectionQuery
}

const ajax = new Ajax()

export function getCollectionList(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/collection/getCollectionList',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
