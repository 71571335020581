/**
     * @desc 接受报价
通过合约接受报价后，pending当前listing，pending当前bid
id：bid的id
     */
import Ajax, { ResponseData } from '../../../utils/request'
import { AxiosResponse } from 'axios'

export class IQueryParams {}

export interface IParams {
  bodyParams: defs.Base
}

const ajax = new Ajax()

export function acceptBid(
  { bodyParams }: IParams = {} as IParams,
  errorHandler?: (error: AxiosResponse<ResponseData>) => void
): Promise<defs.Result> {
  return ajax.request({
    url: '/trading/acceptBid',
    method: 'post',
    data: bodyParams,
    params: {}
  })
}
