import React, { useEffect, useState } from 'react'
import { clock } from '@/assets/images'
import styles from './style.module.scss'
import cs from 'classnames'
import { Player, LoadingSpinner, ControlBar, PlayToggle } from 'video-react'
import { useTranslation } from 'react-i18next'

type CardType = 'hot' | 'trend' | ''

export interface CardProps {
  id?: string
  imgSrc?: string
  text: string
  type: CardType
  price?: string
  unit?: string
  newStyles?: string
  tokenId?: string
  listing?: any
  childStyles?: string
  onClick?: () => void
}

function Index({
  imgSrc = '',
  text,
  price,
  type,
  tokenId = '',
  newStyles = '',
  childStyles = '',
  unit = '',
  listing,
  onClick,
}: CardProps) {
  const { t } = useTranslation()
  const [hasTime, setTime] = useState(true) // 是否有倒计时
  const [time, setTimeDetail]: any = useState({
    h: 0,
    m: 0,
    s: 0,
  })

  useEffect(() => {
    const timer = setInterval(() => {
      bidCountdown(listing?.expirationTime)
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [listing])

  // 报价倒计时
  const bidCountdown = (expirationTime: string) => {
    const endTime = new Date(expirationTime).getTime()
    const now = new Date().getTime()
    const leftTime = endTime - now
    if (leftTime <= 0) {
      setTime(false)
      return
    }
    const h = Math.floor(leftTime / 1000 / 60 / 60)
    const m = Math.floor((leftTime / 1000 / 60) % 60)
    const s = Math.floor((leftTime / 1000) % 60)
    setTimeDetail({
      h: h < 10 ? `0${h}` : `${h}`,
      m: m < 10 ? `0${m}` : `${m}`,
      s: s < 10 ? `0${s}` : `${s}`,
    })
  }

  const renderType = () => {
    switch (type) {
      case 'trend':
        return (
          <div className={cs(styles.coverTrend, childStyles)}>
            <p className={styles.name}>
              # {text} # {tokenId}
            </p>
          </div>
        )
      case 'hot':
        return (
          <div className={cs(styles.coverHot, childStyles)}>
            {listing?.expirationTime && listing.method === 'bid' && hasTime && (
              <p className={styles.clockText}>
                <img src={clock} alt="" className={styles.clock} />
                <span>{`${time.h}:${time.m}:${time.s}`}</span>
              </p>
            )}
            <p className={styles.name}>
              # {text} # {tokenId}
            </p>
            <p className={styles.price}>
              <span className={styles.priceTitle}>{t('price')}</span>
              <span className={styles.priceNumber}>
                {price} {unit}
              </span>
            </p>
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <div
      onClick={() => {
        onClick && onClick()
      }}
      className={cs(styles.container, newStyles)}
    >
      <div className={styles.imgContainer}>
        {imgSrc.substr(imgSrc.lastIndexOf('.') + 1) === 'mp4' ? (
          <Player muted autoPlay className="my-player" poster={imgSrc} src={imgSrc}>
            <LoadingSpinner />
            <ControlBar autoHide={false} disableDefaultControls={true}>
              <PlayToggle />
            </ControlBar>
          </Player>
        ) : (
          <img className={styles.img} src={imgSrc} alt="" />
        )}
      </div>

      {renderType()}
    </div>
  )
}

export default React.memo(Index)
