import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import cs from 'classnames'

export interface BaseInputProps {
  text?: string
  isRequired?: boolean
  value?: string
  placeHolder?: string
  error?: string
  readonly?: string
  onChange: (val: any) => void
  inputStyle?: string
  maxlength?: number
  inputType?: string
}

function Index({
  text = '',
  isRequired = false,
  value,
  placeHolder,
  error = '',
  readonly,
  onChange,
  inputStyle,
  maxlength,
  inputType = 'text',
}: BaseInputProps) {
  const [inputValue, setInputValue] = useState<any>(value || '')
  const numberInspect = (e: any) => {
    const rule = new RegExp('^(0|[1-9]+[0-9]*)(.[0-9]{1,6})?$') // 精度为6的正浮点数
    if ((e.target.value && rule.test(e.target.value)) || e.target.value === '0') {
      setInputValue(e.target.value)
    } else if (e.target.value === '') {
      setInputValue('')
    }
  }

  useEffect(() => {
    onChange(inputValue)
  }, [inputValue])

  useEffect(() => {
    setInputValue(value)
    return () => {}
  }, [value])

  return (
    <div className={styles.baseInput}>
      {text && (
        <div className={styles.textSection}>
          {isRequired && <div className={styles.required}>*</div>}
          <div className={styles.text}>{text}</div>
        </div>
      )}
      <div
        className={
          error ? cs(styles.input, inputStyle, styles.error) : cs(styles.input, inputStyle)
        }
      >
        <input
          value={inputValue}
          maxLength={maxlength}
          readOnly={readonly}
          placeholder={error ? error : placeHolder}
          type={inputType}
          onChange={(e) => {
            if (inputType !== 'number') {
              setInputValue(e.target.value)
            } else {
              numberInspect(e)
            }
          }}
        />
      </div>
    </div>
  )
}

export default React.memo(Index)
